import { useAccountContext } from '@ovotech/ui-tools';
import { useQuery, UseQueryResult } from 'react-query';
import { createCorgiProductsEndpoint } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';
import { CorgiProductsResponse } from '@/src/types/Response';

type QueryCacheKey = `orion-corgi-products-${string}`;

const FIVE_MINUTES = 5 * 60 * 1000;

export const useCorgiProductsQuery =
  (): UseQueryResult<CorgiProductsResponse> => {
    const { accountId } = useAccountContext();
    return useQuery(
      `orion-corgi-products-${accountId}` as QueryCacheKey,
      () =>
        apiService.get<CorgiProductsResponse>({
          url: createCorgiProductsEndpoint(accountId as string),
        }),
      {
        enabled: !!accountId,
        staleTime: FIVE_MINUTES,
        cacheTime: FIVE_MINUTES,
      },
    );
  };
