import {
  Card,
  Col,
  ErrorNotification,
  Heading1,
  Heading3,
  Heading4,
  Margin,
  P,
  Row,
  SecondaryCTAButton,
  Small,
  Stack,
  Strong,
  TextLink,
} from '@ovotech/nebula';
import { format } from 'date-fns';
import React from 'react';

import { Media } from '@/src/components/Media';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom-v5';
import { EVHeaderCard } from '../../components/EVHeaderCard/EVHeaderCard';
import {
  StyledGreenBox,
  CardWithRightIconTime,
  TextInsideCard,
  StyledHeading1,
  StyledHeading4,
  StyledStrongSmall,
  StyledStrongSmallGreen,
} from './EVAnytime.styled';
import { EVAnytimeFAQ } from './EVAnytimeFAQ';
import {
  useAnytimeCreditsQuery,
  useAnytimeBoltonPricingQuery,
} from '@/src/api';
import { PageWithNavigation, ScrollToTopOnMount } from '@/src/components';
import { EVS_INLIFE_ANYTIME } from '@/src/constants/analytics-subjects';
import { DRIVE_ANYTIME } from '@/src/constants/feature-flags';
import { CHARGE_ANYTIME_EMAIL_ADDRESS, ROUTE_EV } from '@/src/constants/routes';
import { State } from '@/src/types/State';
import { useFeature } from '@/src/utils/optimizely';
import LastMonthSavingsMobile from '@/src/resources/last-month-anytime-savings-mobile.png';
import LastMonthSavings from '@/src/resources/last-month-anytime-savings.png';
import TotalSavingsMobile from '@/src/resources/total-anytime-savings-mobile.png';
import TotalSavings from '@/src/resources/total-anytime-savings.png';
import { breakpoints } from '@/src/utils/mediaQuery';
import { useAccountContext } from '@ovotech/ui-tools';
import { useChargeAnytimeBoltonState } from '@/src/pages/AnytimeEligibility/hooks/useChargeAnytimeBoltonState';

export const EVsInlifePageAnytime = () => {
  const history = useHistory();

  const [anytimeFeatureFlag] = useFeature(DRIVE_ANYTIME);
  const { accountId } = useAccountContext();
  const { anytimeBoltonActive, isFetching, anytimeBolton } =
    useChargeAnytimeBoltonState();
  const currentDate = format(new Date(), 'YYYY/MM');
  const [year, month] = currentDate.split('/');
  const { connection } = useSelector(({ evInlife }: State) => evInlife);

  const queryRunCondition =
    anytimeBoltonActive &&
    anytimeFeatureFlag &&
    Boolean(connection) &&
    Boolean(anytimeBolton?.activeFrom);

  const anytimeBoltonPricing = useAnytimeBoltonPricingQuery();

  const anytimeResponse = useAnytimeCreditsQuery(
    accountId,
    anytimeBolton?.activeFrom,
    year,
    month,
    queryRunCondition,
  );

  if (anytimeResponse.isFetching) {
    return null;
  }

  const noDataErrorCondition =
    anytimeResponse.status === 'no-data' && !queryRunCondition && !isFetching;

  if (anytimeResponse.status === 'error' || noDataErrorCondition) {
    return (
      <PageWithNavigation
        analytics={{ name: EVS_INLIFE_ANYTIME }}
        title="Your EV - Charge Anytime"
      >
        <>
          <ScrollToTopOnMount />
          <EVHeaderCard
            title="Charge Anytime"
            description="The supercharged add-on for your OVO Energy plan."
            linkText="< Back to Your EV hub"
            linkUrl={ROUTE_EV}
          />
          <Card data-testid="anytime-credits-error">
            <Margin bottom={6}>
              <ErrorNotification
                id="AnytimeError"
                title="Sorry, there’s a bit of a problem"
              >
                <P>
                  Please try again. If this doesn't work, please get in touch
                  with us at {CHARGE_ANYTIME_EMAIL_ADDRESS}
                </P>
              </ErrorNotification>
            </Margin>
            <SecondaryCTAButton
              data-testid="retry-anytime-credits"
              as={Link}
              to={ROUTE_EV}
            >
              Try again
            </SecondaryCTAButton>
          </Card>
        </>
      </PageWithNavigation>
    );
  }

  //Redirect back to Inlife page if the user isnt visiting this page from the Inlife page's Anytime tile.
  //The Kaluza connection state is set on the Inlife page and is essential for the working of this page. Redirect user to inlife if connection not set yet.
  if (anytimeResponse.status === 'no-data') {
    history.push(ROUTE_EV);
  }

  // @ts-ignore: type declared in cp-ui-tools
  const anytimeSavings =
    anytimeResponse.status === 'data' ? anytimeResponse.data : null;

  const currentRate = anytimeSavings?.tariffRateGBPPerKWH;

  if (!currentRate) {
    // if there is not tariff rate to calculate anytime savings - redirect to EV inlife page
    history.push(ROUTE_EV);
  }

  return (
    <PageWithNavigation
      analytics={{ name: EVS_INLIFE_ANYTIME }}
      title="Your EV - Change Anytime"
    >
      <>
        <ScrollToTopOnMount />
        <EVHeaderCard
          title="Charge Anytime"
          description="The supercharged add-on for your OVO Energy plan."
          linkText="< Back to Your EV hub"
          linkUrl={ROUTE_EV}
        />
        <Margin top={8}>
          <Heading3>Your Charge Anytime charging credit¹</Heading3>
          <P>
            By smart charging your EV at{' '}
            {anytimeBoltonPricing?.data?.formattedPence ?? 'XXp/kWh'}, here’s
            how much you've saved:
          </P>
          <Margin vertical={4}>
            <Card>
              <Margin bottom={4}>
                <Row isNested>
                  <Col medium={6}>
                    <CardWithRightIconTime as={Card}>
                      <TextInsideCard>
                        <StyledHeading1 as={Heading1}>
                          £
                          <span data-testid="current-month-credit">
                            {anytimeSavings?.currentMonth.anytimeCreditGBP || 0}
                          </span>{' '}
                        </StyledHeading1>
                        <StyledHeading4 as={Heading4}>
                          so far this month
                        </StyledHeading4>
                      </TextInsideCard>
                      <Media query={breakpoints.mediumUp}>
                        <img src={LastMonthSavings} alt="" />
                      </Media>
                      <Media query={breakpoints.mediumDown}>
                        <img src={LastMonthSavingsMobile} alt="" />
                      </Media>
                    </CardWithRightIconTime>
                  </Col>
                  <Col medium={6}>
                    <CardWithRightIconTime as={Card}>
                      <TextInsideCard>
                        <StyledHeading1 as={Heading1}>
                          £
                          <span data-testid="current-month-credit">
                            {anytimeSavings?.total.anytimeCreditGBP}
                          </span>{' '}
                        </StyledHeading1>
                        <StyledHeading4 as={Heading4}>total</StyledHeading4>
                      </TextInsideCard>
                      <Media query={breakpoints.mediumUp}>
                        <img src={TotalSavings} alt="" />
                      </Media>
                      <Media query={breakpoints.mediumDown}>
                        <img src={TotalSavingsMobile} alt="" />
                      </Media>
                    </CardWithRightIconTime>
                  </Col>
                </Row>
              </Margin>
              <Margin bottom={8}>
                <P>
                  ¹Your <Strong>charging credit</Strong> will appear on your
                  bill in the first week of the following month. Remember, if
                  you override your smart charging schedule at any point to
                  boost charge your car, you'll be charged your standard energy
                  rate.
                </P>
              </Margin>
              <StyledGreenBox>
                <StyledStrongSmall as={Strong}>
                  Charging credit (£) = (Home rate - EV rate) x electricity used
                  to smart charge EV
                </StyledStrongSmall>
              </StyledGreenBox>
              <Margin top={4}>
                <Stack spaceBetween={2}>
                  <StyledStrongSmall as={Strong}>
                    Charging credit so far this month
                  </StyledStrongSmall>{' '}
                  <StyledStrongSmallGreen as={Strong}>
                    £{anytimeSavings?.currentMonth.anytimeCreditGBP || 0}
                  </StyledStrongSmallGreen>{' '}
                  <StyledStrongSmall>
                    = ({anytimeSavings?.tariffRateGBPPerKWH} -{' '}
                    {anytimeSavings?.anytimeRateGBPPerKWH}) x{' '}
                    {anytimeSavings?.currentMonth.evSmartChargingInKWH}
                  </StyledStrongSmall>
                </Stack>
              </Margin>
            </Card>
          </Margin>
          <Heading3>Frequently asked questions</Heading3>
          <Margin top={4} bottom={4}>
            <EVAnytimeFAQ />
          </Margin>
        </Margin>
        <Heading4>Terms and conditions</Heading4>
        <Small>
          Full terms and conditions can be found{' '}
          <TextLink
            data-testid="anytime-terms"
            href="https://www.ovoenergy.com/terms/OVO-Drive-Flex"
            opensInNewWindow={false}
          >
            here
          </TextLink>
        </Small>
      </>
    </PageWithNavigation>
  );
};
