import * as LEGACY_NAMES from '@/src/constants/analytics';
import * as ANALYTICS_SUBJECTS from '@/src/constants/analytics-subjects';

export const lookup: Record<string, Record<string, string>> = {
  page: {
    [ANALYTICS_SUBJECTS.WORLDPAY_TRANSACTION_PENDING]:
      LEGACY_NAMES.PAYMENTS.WORLD_PAY_PENDING,
    [ANALYTICS_SUBJECTS.WORLDPAY_TRANSACTION_SUCCESS]:
      LEGACY_NAMES.PAYMENTS.WORLD_PAY_SUCCESS,
    [ANALYTICS_SUBJECTS.WORLDPAY_TRANSACTION_ERROR]:
      LEGACY_NAMES.PAYMENTS.WORLD_PAY_ERROR,
    [ANALYTICS_SUBJECTS.WORLDPAY_TRANSACTION_CANCELLED]:
      LEGACY_NAMES.PAYMENTS.WORLD_PAY_CANCELLED,
    [ANALYTICS_SUBJECTS.ANYTIME_ERROR]: 'anytime-error',
    [ANALYTICS_SUBJECTS.EVS_INLIFE_MONTHLY]:
      LEGACY_NAMES.EV.EVS_INLIFE_PAGE_MONTHLY_VIEWED,
    [ANALYTICS_SUBJECTS.EVS_INLIFE_MONTHLY_ERROR]:
      LEGACY_NAMES.EV.EVS_INLIFE_PAGE_MONTHLY_ERROR_VIEWED,
    [ANALYTICS_SUBJECTS.EVS_INLIFE_MONTHLY_NO_CONNECTION]:
      LEGACY_NAMES.EV.EVS_INLIFE_PAGE_MONTHLY_VIEWED_NO_CONNECTION,
    [ANALYTICS_SUBJECTS.EVS_INLIFE_YEARLY]:
      LEGACY_NAMES.EV.EVS_INLIFE_PAGE_YEARLY_VIEWED,
    [ANALYTICS_SUBJECTS.EVS_INLIFE_ANYTIME]:
      LEGACY_NAMES.EV.EVS_INLIFE_PAGE_ANYTIME_VIEWED,
    [ANALYTICS_SUBJECTS.EVS_INLIFE_DISCONNECT]:
      LEGACY_NAMES.EV.EVS_INLIFE_PAGE_DISCONNECT_VIEWED,
    [ANALYTICS_SUBJECTS.SMART_THERMOSTATS_PRODUCTS]:
      LEGACY_NAMES.SMART_THERMOSTATS.PRODUCT_PAGE.VIEWED,
  },
};
