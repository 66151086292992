import {
  NbaActionCodes,
  NbaActionSubCategories,
} from '@/src/types/NextBestAction';
import { SHOW_GREENER_ENERGY } from '@/src/constants/feature-flags';
import { D2VDownloadCard } from '../../ActionCentre/Banners/ChargeAnytime/D2VDownloadCard';
import { CheckContactDetails } from '../../ActionCentre/Banners/CheckContactDetails/CheckContactDetails';
import { EnergyExpertVisits } from '../../ActionCentre/Banners/EnergyExpert/EnergyExpertVisits';
import {
  EnergyTrackerFirstTimeBanner,
  EnergyTrackerReturningBanner,
} from '../../ActionCentre/Banners/EnergyTracker/EnergyTrackerBanner';
import { GreenerEnergyBanner } from '../../ActionCentre/Banners/GreenerEnergy/GreenerEnergyBanner';
import { HandraiserCard } from '../../ActionCentre/Banners/Handraiser/Handraiser';
import { LowChurnCard } from '../../ActionCentre/Banners/Handraiser/LowChurnCard';
import { HomePlanChangeOfTariff } from '../../ActionCentre/Banners/HomePlan/HomePlanChangeOfTariff';
import { HomePlanEnergyCreditsPromoFixed } from '../../ActionCentre/Banners/HomePlan/HomePlanEnergyCreditsPromoFixed';
import { HomePlanMixedPanel } from '../../ActionCentre/Banners/HomePlan/HomePlanMixedPanel';
import { HomePlanSmartInstall } from '../../ActionCentre/Banners/HomePlan/HomePlanSmartInstall';
import { HomePlanWinback } from '../../ActionCentre/Banners/HomePlan/HomePlanWinback';
import { HomeRecoverBanner } from '../../ActionCentre/Banners/HomeRecover/HomeRecover';
import { DirectDebitReduction } from '../../ActionCentre/Banners/Payments/DirectDebitReduction';
import { DirectDebitSetup } from '../../ActionCentre/Banners/Payments/DirectDebitSetup';
import { HighRiskDDCancellation } from '../../ActionCentre/Banners/Payments/HighRiskDDCancellation';
import { SVTDirectDebitSetup } from '../../ActionCentre/Banners/Payments/SVTDirectDebitSetup';
import { PromoteOnTrustpilot } from '../../ActionCentre/Banners/PromoteOnTrustpilot/PromoteOnTrustpilot';
import { FixedPlanRenewalCard } from '../../ActionCentre/Banners/Renewal/FixedPlanRenewalCard';
import { SmartMeterBookingBookingNowAvailable } from '../../ActionCentre/Banners/SmartMeterBooking/SmartMeterBookingBookingNowAvailable';
import { SmartMeterBookingMandate } from '../../ActionCentre/Banners/SmartMeterBooking/SmartMeterBookingMandate';
import SmartMeterBookingOffer from '../../ActionCentre/Banners/SmartMeterBooking/SmartMeterBookingOffer';
import { SmartMeterBookingRecerts } from '../../ActionCentre/Banners/SmartMeterBooking/SmartMeterBookingRecerts';
import { SmartMeterBookingRenter } from '../../ActionCentre/Banners/SmartMeterBooking/SmartMeterBookingRenter';
import { SmartMeterNoReadingNecessary } from '../../ActionCentre/Banners/SmartMeterBooking/SmartMeterNoReadingNecessary';
import { SolarPanelsCard } from '../../ActionCentre/Banners/Solar/SolarPanels';
import { SVTFixedSmartEligible } from '../../ActionCentre/Banners/SwitchPlan/SVTFixedSmartEligible';
import { BannerId, banners } from '../../ActionCentre/Banners/banners';
import { useAccountContext } from '@ovotech/ui-tools';
import { useContractsQuery } from '@/src/api';
import { useMemo } from 'react';
import { HomePlanEnergyCreditsPromoSVT } from '../../ActionCentre/Banners/HomePlan/HomePlanEnergyCreditsPromoSVT';
import { lossBannerToUse } from './utils/lossBannerToUse';
import { homeplanBannerToUse } from './utils/homeplanBannerToUse';

export type NbaActionCodeId =
  | NbaActionCodes
  | `${NbaActionCodes}.${NbaActionSubCategories}`;

type ActionCard = {
  name: BannerId;
  component: React.ComponentType<any>;
  featureFlag?: string;
};

export type NbaActionCardsMap = Partial<
  Record<NbaActionCodeId, ActionCard | null>
> & { [key: string]: ActionCard | null };

export const nbaBannersMap: NbaActionCardsMap = {
  anytime_ovo_001: {
    name: 'd2VDownloadCard',
    component: D2VDownloadCard,
  },
  check_contact_details_ovo_001: {
    name: 'checkContactDetails',
    component: CheckContactDetails,
  },
  greenlight_new_ovo_001: {
    name: 'greenlightNew',
    component: EnergyTrackerFirstTimeBanner,
  },
  greenlight_view_again_ovo_001: {
    name: 'greenlightViewAgain',
    component: EnergyTrackerReturningBanner,
  },
  high_risk_dd_cancellation_ovo_001: {
    name: 'highRiskDDCancellation',
    component: HighRiskDDCancellation,
  },
  in_renewal_window_ovo_001: {
    name: 'renewalWindow',
    component: FixedPlanRenewalCard,
  },
  meter_read_day_ovo_001: {
    name: 'noReadingNecessary',
    component: SmartMeterNoReadingNecessary,
  },
  sell_ovo_beyond_ovo_001: {
    name: 'greenerEnergy',
    component: GreenerEnergyBanner,
    featureFlag: SHOW_GREENER_ENERGY,
  },
  sell_energy_experts_ovo_001: {
    name: 'energyExpertVisits',
    component: EnergyExpertVisits,
  },
  smart_booking_ovo_001: {
    name: 'smartMeterBookingOffer',
    component: SmartMeterBookingOffer,
  },
  smart_booking_ovo_001_renter: {
    name: 'smartMeterBookingRenter',
    component: SmartMeterBookingRenter,
  },
  smart_booking_ovo_001_mandate: {
    name: 'smartMeterBookingMandate',
    component: SmartMeterBookingMandate,
  },
  smart_booking_ovo_001_recerts: {
    name: 'smartMeterBookingRecerts',
    component: SmartMeterBookingRecerts,
  },
  smart_booking_ovo_001_booking_now_available: {
    name: 'smartMeterBookingBookingNowAvailable',
    component: SmartMeterBookingBookingNowAvailable,
  },
  smart_booking_renter_ovo_001: {
    name: 'smartMeterBookingRenter',
    component: SmartMeterBookingRenter,
  },
  smart_booking_mandate_ovo_001: {
    name: 'smartMeterBookingMandate',
    component: SmartMeterBookingMandate,
  },
  solar_install_ovo_001: {
    name: 'solarPanels',
    component: SolarPanelsCard,
  },
  svt_dd_set_up_ovo_001: {
    name: 'svtDirectDebitSetup',
    component: SVTDirectDebitSetup,
  },
  dd_set_up_ovo_001: {
    name: 'directDebitSetup',
    component: DirectDebitSetup,
  },
  sell_ovo_homeplan_ovo_001_cot: {
    name: 'homePlanCot',
    component: HomePlanChangeOfTariff,
  },
  sell_ovo_homeplan_ovo_001_smart_install: {
    name: 'homePlanSmartInstall',
    component: HomePlanSmartInstall,
  },
  sell_ovo_homeplan_ovo_001_mixed_panel: {
    name: 'homePlanMixedPanel',
    component: HomePlanMixedPanel,
  },
  sell_ovo_homeplan_ovo_001_winback: {
    name: 'homePlanWinback',
    component: HomePlanWinback,
  },
  handraisers_ovo_001_em_nba_test: {
    name: 'handraiserTest',
    component: HandraiserCard,
  },
  handraisers_ovo_001_em_nba_control: {
    name: 'handraiserControl',
    component: HandraiserCard,
  },
  svt_low_churn_ovo_001_on_demand: {
    name: 'handraisersOnDemand',
    component: LowChurnCard,
  },
  svt_low_churn_ovo_001_direct_debit: {
    name: 'handraisersDirectDebit',
    component: LowChurnCard,
  },
  dd_change_ovo_001_dd_decrease: {
    name: 'directDebitReduction',
    component: DirectDebitReduction,
  },
  sell_home_recover_ovo_001: {
    name: 'homeRecover',
    component: HomeRecoverBanner,
  },
  promote_trustpilot_ovo_001: {
    name: 'promoteOnTrustPilot',
    component: PromoteOnTrustpilot,
  },
  sell_ovo_homeplan_ovo_001_svt: {
    name: 'homePlanEnergyCreditsPromoSVT',
    component: HomePlanEnergyCreditsPromoSVT,
  },
  sell_ovo_homeplan_ovo_001_fixed: {
    name: 'homePlanEnergyCreditsPromoFixed',
    component: HomePlanEnergyCreditsPromoFixed,
  },
  svt_to_fixed_ovo_001_smart_eligible: {
    name: 'svtFixedSmartEligible',
    component: SVTFixedSmartEligible,
  },
};

const getActionCard = (
  bannerId: BannerId | null,
): React.ComponentType<any> | undefined => {
  if (!bannerId || !banners[bannerId]) return;
  return banners[bannerId];
};

export const useHardcodedNbaActionCards = (): NbaActionCardsMap => {
  const { accountId } = useAccountContext();
  const contractQuery = useContractsQuery(accountId);

  const lossBannerId = contractQuery?.data
    ? lossBannerToUse(contractQuery.data)
    : null;

  const homeplanBannerId = contractQuery?.data
    ? homeplanBannerToUse(contractQuery.data)
    : null;

  const lossComponent = getActionCard(lossBannerId);
  const homeplanComponent = getActionCard(homeplanBannerId);

  return useMemo(() => {
    return {
      ...(lossComponent && lossBannerId
        ? {
            lost_members_ovo_001: {
              name: lossBannerId,
              component: lossComponent,
            },
          }
        : {}),
      ...(homeplanComponent && homeplanBannerId
        ? {
            'sell_ovo_homeplan_ovo_001.incentive_xsell': {
              name: homeplanBannerId,
              component: homeplanComponent,
            },
          }
        : {}),
      ...nbaBannersMap,
    };
  }, [homeplanBannerId, lossBannerId, homeplanComponent, lossComponent]);
};
