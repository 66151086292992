import {
  CTAButton,
  Heading1,
  Heading2,
  Heading3,
  Li,
  P,
  Strong,
  TextLink,
  Ul,
} from '@ovotech/nebula';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom-v5';
import styled from 'styled-components';
import { useTadoEligible } from '../hooks/use-tado-eligible';
import {
  ErrorSection,
  PageWithNavigation,
  PageWithSimpleHeader,
} from '@/src/components';
import { SMART_THERMOSTATS_STATUS_ERROR } from '@/src/constants/error-section-ids';
import { SMART_THERMOSTATS_MAINTENANCE } from '@/src/constants/feature-flags';
import { ROUTE_SMART_THERMOSTATS_OFFER } from '@/src/constants/routes';
import { LoadingPanel } from '@/src/pages/Payments/DirectDebitCalculator/LoadingPanel';
import AppHeatingMobile from '@/src/resources/tado/app-heating-mobile.png';
import AppHeating from '@/src/resources/tado/app-heating.png';
import { ReactComponent as BoilerSvg } from '@/src/resources/tado/boiler.svg';
import useWindowDimensions from '@/src/utils/hooks/useWindowDimensions';
import { mqMediumUp, mqSmallUp } from '@/src/utils/mediaQuery';
import { useFeature } from '@/src/utils/optimizely';
import { getQueryParamValue } from '@/src/utils/getQueryParamValue';
import TadoThermostatImage from '@/src/resources/tado-thermostat.png';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';
import { AnalyticsClick } from '@ovotech/ui-tools';
import { buildPromoCodeLink } from '../utils/utils';

const StyledContainerWithLongHeaderBg = styled.div`
  ${mqMediumUp(`
    .bg-ovo {
      height: 300px;
    }
  `)}
  .bg-ovo {
    height: 450px;
  }
`;

export const Compatibility = () => {
  const eligibility = useTadoEligible();
  const history = useHistory();
  const { width } = useWindowDimensions();
  const isMobile = width < 768;
  const [isUnderMaintenance] = useFeature(SMART_THERMOSTATS_MAINTENANCE);
  const promoCode = getQueryParamValue('promoCode');

  if (eligibility.isError) {
    return (
      <PageWithNavigation title="Smart Thermostats">
        <ErrorSection
          headingComponent={Heading2}
          id={SMART_THERMOSTATS_STATUS_ERROR}
        />
      </PageWithNavigation>
    );
  }

  if (eligibility.isLoading) {
    return (
      <PageWithNavigation title="Smart Thermostats">
        <LoadingPanel />
      </PageWithNavigation>
    );
  }

  if (!eligibility.isEligible || isUnderMaintenance) {
    return <Redirect to={ROUTE_SMART_THERMOSTATS_OFFER} />;
  }

  const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `;

  const StyledHeading1 = styled(Heading1)`
    color: white;
    align-self: center;
  `;

  const StyledImage = styled.img`
    display: none;

    ${mqSmallUp(`
      display: block;
      height: 188px;
    `)}
  `;

  const StyledLi = styled(Li)`
    position: relative;
    line-height: 2rem;
    margin: 1rem 0 2rem;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0.5rem;
      bottom: 0.5rem;
      left: -2rem;
      /* Set the dimensions for the pseudo-element */
      width: 8px;
      height: 8px;
      /* Set the background color or any other styles for the square */
      background-color: #000; /* Replace #000 with your desired color */
    }
  `;

  const StyledButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 32px;

    ${mqSmallUp(`
      justify-content: space-between;
      flex-direction: row;
    `)};
  `;

  const BoilerWrapper = styled.div`
    display: none;

    ${mqMediumUp(`
        display: block;
        width: 100px;
        padding: 20px;
    `)}
  `;

  const Container = styled.div`
    display: flex;
    flex-direction: column-reverse;
    background-color: white;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    ${mqMediumUp(`
       flex-direction: row;

    `)}
  `;

  const Content = styled.div`
    padding: 20px;
    margin-bottom: 40px;

    ${mqMediumUp(`
        flex: 1;
    `)}
  `;

  const ImageWrapper = styled.div``;

  const handleBackClick = () => {
    history.push(ROUTE_SMART_THERMOSTATS_OFFER);
  };

  const handleNextStep = () => {
    const route = buildPromoCodeLink(
      ROUTE_SMART_THERMOSTATS_OFFER + '/checkout',
      promoCode,
    );
    history.push(route);
  };

  return (
    <StyledContainerWithLongHeaderBg>
      <PageWithSimpleHeader
        title="Smart Thermostats"
        analytics={{
          name: SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.GET_SET_FOR_TADO.VIEWED,
        }}
      >
        <StyledHeader>
          <StyledHeading1>Get set for tado° smart heating</StyledHeading1>
          <StyledImage alt="Tado" src={TadoThermostatImage} />
        </StyledHeader>
        <Container>
          <BoilerWrapper>
            <BoilerSvg />
          </BoilerWrapper>
          <Content>
            <Heading3>Check your compatibility</Heading3>
            <P style={{ marginTop: 30, marginBottom: 20 }}>
              tado° has designed their smart thermostats to be compatible with
              most heating systems.
            </P>
            <P style={{ marginBottom: 20 }}>This includes:</P>
            <Ul style={{ listStyle: 'none' }}>
              <StyledLi>Combi, system, and heat-only boilers</StyledLi>
              <StyledLi>Conventional and condensing boilers</StyledLi>
              <StyledLi>Hydronic underfloor systems</StyledLi>
              <StyledLi>
                Multi-room systems (with one tado° Smart Thermostat per zone)
              </StyledLi>
              <StyledLi>District heating systems</StyledLi>
              <StyledLi>Gravity-fed heating systems</StyledLi>
            </Ul>
            <P style={{ marginTop: 30 }}>
              Unfortunately, <Strong>tado°</Strong> doesn’t get on well with
              electric systems such as electric radiators, pilot wire (fil
              pilote), infrared panels and immersion heaters (hot-water
              cylinder) and electric underfloor heating.
            </P>
            <P>
              <AnalyticsClick
                name={
                  SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.GET_SET_FOR_TADO.CLICKED
                    .TADO_TECH_SUPPORT
                }
              >
                <TextLink
                  href="https://support-request.tado.com/#/contact?locale=en&selection=sales&subselection=compatibility&message="
                  opensInNewWindow
                >
                  Contact tadoº Technical Support{' '}
                </TextLink>
              </AnalyticsClick>{' '}
              to check your compatibility.
            </P>
          </Content>
          <ImageWrapper>
            {isMobile ? (
              <img src={AppHeatingMobile} alt="App Heating Mobile" />
            ) : (
              <img src={AppHeating} alt="App Heating" />
            )}
          </ImageWrapper>
        </Container>
        <StyledButtonWrapper>
          <AnalyticsClick
            name={
              SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.GET_SET_FOR_TADO.CLICKED
                .BACK
            }
          >
            <CTAButton variant="missionSecondary" onClick={handleBackClick}>
              Back
            </CTAButton>
          </AnalyticsClick>
          <AnalyticsClick
            name={
              SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.GET_SET_FOR_TADO.CLICKED
                .READY_TO_ORDER
            }
          >
            <CTAButton variant="missionPrimary" onClick={handleNextStep}>
              I'm ready to order
            </CTAButton>
          </AnalyticsClick>
        </StyledButtonWrapper>
      </PageWithSimpleHeader>
    </StyledContainerWithLongHeaderBg>
  );
};
