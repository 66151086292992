import { useAccountContext } from '@ovotech/ui-tools';
import { compareAsc, isAfter } from 'date-fns';
import { MappedPowerMoveChallenge } from '../types';
import {
  findHighestLessThanOrEqualTotalPercentage,
  findHighestMonthlyReward,
  getActiveUntilDate,
  getChallengeTiers,
  getChallengeType,
  getJoinedChallengesData,
} from '../utils/utils';
import { useAvailableProductsQuery, useProductsQueryV2 } from '@/src/api';
import { useProductCatalogueQueryV2 } from '@/src/api/use-product-catalogue-query-v2';
import { PRODUCT_IDS } from '@/src/constants/products';
import { CreditingConfigType } from '@/src/types/Products';

type UsePowerMoveChallengesReturn = {
  isError: boolean;
  isLoading: boolean;
  data: MappedPowerMoveChallenge[];
  joinedChallenges: Array<{
    name: string;
    id: string;
    activeFrom: string;
    activeUntil?: string;
  }>;
};

export const usePowerMoveChallenges = (): UsePowerMoveChallengesReturn => {
  const { accountId } = useAccountContext();
  const activeProductsQuery = useProductsQueryV2(accountId);
  const catalogueBoltonsQuery = useProductCatalogueQueryV2();
  const availableChallengesQuery = useAvailableProductsQuery(
    PRODUCT_IDS.powerMove,
  );

  /* End date is only available in bolt-on */
  const joinedChallengesData = getJoinedChallengesData(
    activeProductsQuery.data?.boltons ?? [],
  );

  const mappedChallenges = (availableChallengesQuery.data ?? []).reduce(
    (acc: MappedPowerMoveChallenge[], product) => {
      if (
        !(
          product.creditingConfig?.type ===
            CreditingConfigType.PeakUsageReduction ||
          product.creditingConfig?.type ===
            CreditingConfigType.TieredPeakUsageReduction ||
          product.creditingConfig?.type ===
            CreditingConfigType.TieredPeakUsageReductionV2
        )
      ) {
        return acc;
      }

      const { id, versionId, activeFrom, displayName, creditingConfig } =
        product;

      acc.push({
        id,
        versionId,
        displayName,
        activeFrom: new Date(activeFrom),
        challengeType: getChallengeType(creditingConfig),
        tiers: getChallengeTiers(creditingConfig),
        peakHours: creditingConfig.peakHours,
        highestLessThanOrEqualTotalPercentage:
          findHighestLessThanOrEqualTotalPercentage(creditingConfig),
        highestMonthlyReward: findHighestMonthlyReward(creditingConfig),
        isUpcoming: isAfter(new Date(activeFrom), new Date()),
        isJoined: joinedChallengesData.some(
          bolton => bolton.versionId === versionId,
        ),
        activeUntil: getActiveUntilDate(versionId, catalogueBoltonsQuery),
        areWeekendsIncluded:
          creditingConfig.type ===
          CreditingConfigType.TieredPeakUsageReductionV2,
      });

      return acc;
    },
    [],
  );

  const sortedChallenges: MappedPowerMoveChallenge[] = mappedChallenges.sort(
    (prev, next) => compareAsc(prev.activeFrom, next.activeFrom),
  );

  const joinedChallenges = (activeProductsQuery.data?.boltons ?? []).reduce(
    (acc: any, challenge) => {
      if (
        joinedChallengesData.some(
          bolton => bolton.versionId === challenge.versionId,
        )
      ) {
        acc.push({
          name: challenge.displayName,
          id: challenge.versionId,
          activeFrom: challenge.activeFrom,
          activeUntil: getActiveUntilDate(
            challenge.versionId,
            catalogueBoltonsQuery,
          ),
        });
      }
      return acc;
    },
    [],
  );

  return {
    data: sortedChallenges,
    joinedChallenges,
    isError: activeProductsQuery.isError || availableChallengesQuery.isError,
    isLoading:
      activeProductsQuery.isLoading ||
      availableChallengesQuery.isLoading ||
      catalogueBoltonsQuery.isLoading,
  };
};
