import { CardPaymentResponse } from '@ovotech/energy-cx';
import { useMutation } from 'react-query';
import { createWorldpayCardPaymentEndpoint } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';

export const useWorldpayCardPaymentMutation = (
  accountId: string,
  onSuccess?: (response: CardPaymentResponse) => void,
) =>
  useMutation(
    ({ amount }: { amount: number }) =>
      apiService.post<CardPaymentResponse>({
        responseType: 'json',
        url: createWorldpayCardPaymentEndpoint(accountId),
        body: { amountInPence: amount },
        headers: {
          'orion-endpoint-version': '3',
        },
      }),
    {
      mutationKey: `worldpay-card-payment-${accountId}`,
      onSuccess: response => {
        if (onSuccess) {
          onSuccess(response);
        }
      },
    },
  );
