import { Card, Heading4, Margin, P, Small, Strong } from '@ovotech/nebula';
import { PLAN_DETAILS_MAP, PlanFamily } from '@monovo/energy-renewals';
import React from 'react';

import {
  AsteriskContainer,
  CardCol,
  CardHeader,
  CardRow,
  CheapestTariffColumnsSpacer,
  CheapestTariffContainer,
  DisplayOnlyMobile,
  DisplayOnlyWeb,
  Divider,
  StyledCard,
} from './Card.styled';
import { FuelType } from '@/src/types/Account';
import {
  CheaperTariff,
  FetchTariffCostsResponse,
} from '@/src/types/TariffCosts';
import { getCurrencyValue } from '@/src/utils/money';

type Props = {
  tariffCosts: FetchTariffCostsResponse;
};

const CheapestTariff = ({
  tariff,
  fuel,
  type,
}: {
  fuel: FuelType;
  tariff: CheaperTariff;
  type: 'similar' | 'overall';
}) => {
  // tariff.name is in following format "Provider PlanName Date" -> e.g. "OVO Better Energy 18 Feb 2020"
  const name = Object.values(PlanFamily).find(name =>
    tariff.planName.includes(name),
  ) as PlanFamily | undefined;
  const title = name && PLAN_DETAILS_MAP[name].title;
  const isSavingsLessThanOnePound = Number(tariff.costSavingIncVat.amount) < 1;

  return (
    <>
      {type === 'overall' ? (
        <DisplayOnlyWeb>
          <Heading4 style={{ textTransform: 'capitalize' }}>{fuel}</Heading4>
          <Divider bold />
        </DisplayOnlyWeb>
      ) : null}
      <Margin top={2}>
        <P>
          <Strong>
            Cheapest {type} {fuel} tariff
          </Strong>
        </P>
      </Margin>
      <Margin top={1} bottom={6}>
        {tariff.current || Number(tariff.costSavingIncVat.amount) <= 0 ? (
          <P>
            <Small>
              You’re currently on our cheapest {type} {fuel} plan
            </Small>
          </P>
        ) : (
          <>
            <div>
              <P>
                <Small>Supplier: OVO Energy</Small>
              </P>
            </div>
            {name ? (
              <div>
                <P>
                  <Small>Plan: {name}</Small>
                </P>
              </div>
            ) : null}
            {title ? (
              <div>
                <P>
                  <Small>Plan type: {title}</Small>
                </P>
              </div>
            ) : null}
            <div>
              <P>
                <Small>
                  You could save*: £
                  {getCurrencyValue(
                    { unit: 'GBP', dp: isSavingsLessThanOnePound ? 2 : 0 },
                    tariff.costSavingIncVat,
                  )}
                </Small>
              </P>
            </div>
          </>
        )}
      </Margin>
    </>
  );
};

const CheapestTariffCard = ({ tariffCosts }: Props) => {
  const elecTariff = tariffCosts.find(({ fuel }) => fuel === 'electricity');
  const gasTariff = tariffCosts.find(({ fuel }) => fuel === 'gas');

  return (
    <StyledCard as={Card}>
      <CardHeader>
        <Margin all={2} />
      </CardHeader>
      <CheapestTariffContainer>
        <DisplayOnlyWeb>
          <CardRow>
            {elecTariff ? (
              <CardCol>
                <CheapestTariff
                  fuel={elecTariff.fuel}
                  type="overall"
                  tariff={elecTariff.cheapestOverallTariff}
                />
              </CardCol>
            ) : null}
            {gasTariff ? (
              <>
                <CheapestTariffColumnsSpacer />
                <CardCol>
                  <CheapestTariff
                    fuel={gasTariff.fuel}
                    type="overall"
                    tariff={gasTariff.cheapestOverallTariff}
                  />
                </CardCol>
              </>
            ) : null}
          </CardRow>
          <CardRow>
            {elecTariff ? (
              <>
                <CardCol>
                  <Divider />
                  <CheapestTariff
                    fuel={elecTariff.fuel}
                    type="similar"
                    tariff={elecTariff.cheapestSimilarTariff}
                  />
                </CardCol>
              </>
            ) : null}
            {gasTariff ? (
              <>
                <CheapestTariffColumnsSpacer />
                <CardCol>
                  <Divider />
                  <CheapestTariff
                    fuel={gasTariff.fuel}
                    type="similar"
                    tariff={gasTariff.cheapestSimilarTariff}
                  />
                </CardCol>
              </>
            ) : null}
          </CardRow>
        </DisplayOnlyWeb>
        <DisplayOnlyMobile>
          <CardRow>
            <CardCol>
              {elecTariff ? (
                <CheapestTariff
                  fuel={elecTariff.fuel}
                  type="overall"
                  tariff={elecTariff.cheapestOverallTariff}
                />
              ) : null}
              {gasTariff ? (
                <>
                  <Divider />
                  <CheapestTariff
                    fuel={gasTariff.fuel}
                    type="overall"
                    tariff={gasTariff.cheapestOverallTariff}
                  />
                </>
              ) : null}
            </CardCol>
          </CardRow>
          <Divider bold />

          <CardRow>
            <CardCol>
              {elecTariff ? (
                <CheapestTariff
                  fuel={elecTariff.fuel}
                  type="similar"
                  tariff={elecTariff.cheapestSimilarTariff}
                />
              ) : null}
              {gasTariff ? (
                <>
                  <Divider />
                  <CheapestTariff
                    fuel={gasTariff.fuel}
                    type="similar"
                    tariff={gasTariff.cheapestSimilarTariff}
                  />
                </>
              ) : null}
            </CardCol>
          </CardRow>
        </DisplayOnlyMobile>
        <Margin bottom={4} top={6}>
          <div>
            <P>
              <Strong>
                <Small>Cheapest overall tariff</Small>
              </Strong>
            </P>
          </div>
          <div>
            <P>
              <Small>
                The cheapest plan we have available within our OVO family.
              </Small>
            </P>
          </div>
        </Margin>
        <Margin bottom={4}>
          <div>
            <P>
              <Small>
                <Strong>Cheapest similar tariff</Strong>
              </Small>
            </P>
          </div>
          <div>
            <P>
              <Small>
                The cheapest from the same type of plans that you currently
                have.
              </Small>
            </P>
          </div>
        </Margin>
        <Margin vertical={8}>
          <CardRow>
            <AsteriskContainer>
              <P>
                <Small>*</Small>
              </P>
            </AsteriskContainer>
            <div>
              <P>
                <Small>
                  We worked this out based on how much energy you normally use
                  over a year. We used today’s prices, but they can change day
                  by day, so savings can be different at another date.
                </Small>
              </P>
            </div>
          </CardRow>
        </Margin>
      </CheapestTariffContainer>
    </StyledCard>
  );
};

export default CheapestTariffCard;
