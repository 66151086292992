import { Card } from '@ovotech/nebula';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FuelTabs } from './FuelTabs/FuelTabs';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import { BreakdownScreenResponse, Fuel } from '@/src/types/Response';
import AnalyticsService from '@/src/services/analyticsService';

const NoPaddingCard = styled(Card)`
  padding: 0;
  border: 0;

  ${({ theme: { space } }) => `
    margin: ${space[8]} 0;
  `}
`;

export const Breakdown = (props: BreakdownScreenResponse) => {
  const [fuel, setFuel] = useState(
    props.visibleFuelTabs?.[0] || Fuel.ELECTRICITY,
  );

  return (
    <NoPaddingCard>
      <FuelTabs
        {...props}
        fuel={fuel}
        hasSolarPanels={!!props.hasSolarPanels}
        isSingleFuel={props.visibleFuelTabs?.length === 1}
        handleFuelChange={(fuel: Fuel) => {
          setFuel(fuel);
          AnalyticsService.dispatch(
            ENERGY_INSIGHTS.spotlightsFuelTabChange(fuel),
          );
        }}
      />
    </NoPaddingCard>
  );
};
