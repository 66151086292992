import {
  P,
  Ul,
  Li,
  Heading4,
  Accordion,
  TextLink,
  AccordionGroup,
} from '@ovotech/nebula';
import { useAnalytics } from '@ovotech/ui-tools';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { PATH_TO_ZERO } from '@/src/constants/analytics';
import { POWER_MOVE_FAQ_LINK } from '@/src/constants/links';

const StyledTextLink = styled(TextLink)`
  cursor: pointer;
  margin-top: 12px;
`;

export const PowerMoveDescriptionAccordion = () => {
  const { dispatch } = useAnalytics();
  const [expanded, setExpanded] = useState(false);

  const onToggleHandler = useCallback(
    (newExpanded: boolean) => {
      setExpanded(prevExpanded => {
        // Accordion triggers `onToggleHandler` sometimes more than once for the same toggle
        // this will handle that to ensure we don't dispatch an event twice to analytics
        if (prevExpanded !== newExpanded) {
          dispatch({
            name: PATH_TO_ZERO.POWER_MOVE_HOW_IT_WORKS_BUTTON_CLICKED,
            type: 'click',
            properties: {
              opened: `${newExpanded}`,
            },
          });
        }
        return newExpanded;
      });
    },
    [dispatch],
  );

  return (
    <AccordionGroup>
      <Accordion expanded headingComponent={Heading4} title="Why take part?">
        <Ul>
          <Li>Earn credit on your monthly bills</Li>
          <Li>Lower our collective carbon footprint</Li>
          <Li>Help us all rely less on fossil fuels</Li>
        </Ul>
      </Accordion>
      <Accordion
        onToggle={onToggleHandler}
        expanded={expanded}
        headingComponent={Heading4}
        title="How it works"
      >
        <P>Each challenge is for 3 months and has its own:</P>
        <Ul>
          <Li>monthly target</Li>
          <Li>time slot</Li>
          <Li>reward</Li>
        </Ul>
        <P>You’ll earn a reward every month you hit the target.</P>
        <P>
          If you’re successful, you’ll see the credit on your energy bill the
          following calendar month.
        </P>
        <StyledTextLink
          href={POWER_MOVE_FAQ_LINK}
          opensInNewWindow
          onClick={() => {
            dispatch({
              name: PATH_TO_ZERO.POWER_MOVE_FAQS_LINK_CLICKED,
              type: 'click',
            });
          }}
        >
          View frequently asked questions
        </StyledTextLink>
      </Accordion>
    </AccordionGroup>
  );
};
