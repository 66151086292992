import { Heading1, P } from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';
import {
  ActionCentreBanner,
  Banner,
} from '@/src/components/ActionCentre/Banners/Banner';
import { breakpoints } from '@/src/utils/mediaQuery';

export const SmartMeterNoReadingNecessary: ActionCentreBanner = ({
  dismiss,
}) => {
  const SmallDeviceP = styled(P)`
    @media ${breakpoints.mediumDown} {
      max-width: 80%;
    }
  `;
  return (
    <Banner data-testid="no-smart-meter-reading" icon="pound" dismiss={dismiss}>
      <Heading1>We're getting regular readings from you</Heading1>
      <SmallDeviceP>
        There's no need to get in touch with us to submit a meter reading, we
        receive these automatically from your smart meter.
      </SmallDeviceP>
    </Banner>
  );
};
