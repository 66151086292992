import { Card } from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';

import {
  StyledNoMarginH2,
  StyledNoMarginP,
} from '../CarbonComparison/CarbonComparison.styled';

import { BreakdownSection } from './Components/BreakdownSection';

import { mqMediumDown, mqMediumUp, mqSmallDown } from '@/src/utils/mediaQuery';
import { GridContainer } from '@/src/pages/EnergyInsights/components';
import { BreakdownWidgetData } from '@/src/pages/EnergyInsights/types';

type Props = {
  widgetData: BreakdownWidgetData | null;
  hasSolarPanels: boolean | null;
};

const StyledCard = styled(Card)`
  min-height: 380px;
  display: flex;
  ${mqMediumUp(`
    flex-direction: row;
  `)}
  ${mqMediumDown(`
    flex-direction: column;
  `)}

  ${mqSmallDown(`
    min-height: 0;
  `)}
`;

const BreakdownWidget = ({ widgetData, hasSolarPanels }: Props) => {
  if (!widgetData?.totalUsage) {
    return null;
  }

  return (
    <GridContainer
      spanSmall={12}
      spanMedium={6}
      spanLarge={6}
      includeRow={false}
    >
      <StyledNoMarginH2>Your weekly energy use</StyledNoMarginH2>
      <StyledNoMarginP>
        Learn where your energy goes and get some handy tips on cutting carbon
      </StyledNoMarginP>
      <StyledCard>
        <BreakdownSection {...widgetData} hasSolarPanels={hasSolarPanels} />
      </StyledCard>
    </GridContainer>
  );
};

export default BreakdownWidget;
