import { CardPaymentStatus as OepCardPaymentStatus } from '@ovotech/energy-cx';
import React, { ComponentProps } from 'react';
import { Route } from 'react-router-dom-v5';
import { PaymentAmount } from '../PaymentAmount/PaymentAmount';
import {
  SwitchWithNotFound,
  PageWithNavigationAndSectionPadding,
} from '@/src/components';
import {
  ROUTE_MAKE_CARD_PAYMENT,
  ROUTE_MAKE_CARD_PAYMENT_CANCELLED_FROM_APP,
  ROUTE_MAKE_CARD_PAYMENT_CONFIRM_FROM_APP,
  ROUTE_MAKE_CARD_PAYMENT_ERROR_FROM_APP,
  ROUTE_MAKE_CARD_PAYMENT_PENDING_FROM_APP,
  ROUTE_MAKE_CARD_PAYMENT_STATUS,
} from '@/src/constants/routes';

const CardPayment = () => (
  <PageWithNavigationAndSectionPadding title="Card payment">
    <PaymentAmount />
  </PageWithNavigationAndSectionPadding>
);

const CardPaymentStatus = (
  props: ComponentProps<typeof OepCardPaymentStatus>,
) => (
  <PageWithNavigationAndSectionPadding title="Card payment status">
    <OepCardPaymentStatus {...props} />
  </PageWithNavigationAndSectionPadding>
);

const CardPaymentSuccess = () => <CardPaymentStatus status="success" />;
const CardPaymentCancelled = () => <CardPaymentStatus status="cancelled" />;
const CardPaymentPending = () => <CardPaymentStatus status="pending" />;
const CardPaymentError = () => <CardPaymentStatus status="error" />;

const CardPaymentRoutes = () => (
  <SwitchWithNotFound>
    <Route
      exact
      path={ROUTE_MAKE_CARD_PAYMENT_CONFIRM_FROM_APP}
      component={CardPaymentSuccess}
    />
    <Route
      exact
      path={ROUTE_MAKE_CARD_PAYMENT_CANCELLED_FROM_APP}
      component={CardPaymentCancelled}
    />
    <Route
      exact
      path={ROUTE_MAKE_CARD_PAYMENT_PENDING_FROM_APP}
      component={CardPaymentPending}
    />
    <Route
      exact
      path={ROUTE_MAKE_CARD_PAYMENT_ERROR_FROM_APP}
      component={CardPaymentError}
    />
    <Route exact path={ROUTE_MAKE_CARD_PAYMENT} component={CardPayment} />
    <Route
      exact
      path={ROUTE_MAKE_CARD_PAYMENT_STATUS}
      component={CardPaymentStatus}
    />
  </SwitchWithNotFound>
);

export default CardPaymentRoutes;
