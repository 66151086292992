import styled from 'styled-components';

export const StyledSecureWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ theme: { space } }) => `
  padding-top:  ${space[4]};
  padding-bottom:  ${space[3]};
`}
`;

export const StyledIconWrapper = styled.div`
  ${({ theme: { space } }) => `
  padding-right:  ${space[2]};
  @media only screen and (min-width: 992px) {  
    display: flex;
 } 
  @media only screen and (max-width: 600px) {  
    position: relative;
    top: -${space[2]};
  }
`}
`;
