import { P, Strong } from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';
import { ActionCentreBanner } from '../Banner';
import { ENERGY_EXPERT_VISITS_BANNER } from '@/src/constants/analytics-subjects';
import { mqMediumDown } from '@/src/utils/mediaQuery';
import { ENERGY_EXPERT_VISITS_LINK } from '@/src/constants/links';
import { ClippedImageBanner } from '@/src/components/ActionCentre/Banners/ClippedImageBanner/ClippedImageBanner';
import EnergyExpertVisitsImage from '@/src/resources/energy-expert-visits.png';

const ClippedImg = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-image: url(${EnergyExpertVisitsImage});
  background-size: 370px auto;
  clip-path: polygon(0% 0%, 23% 93%, 100% 66%, 100% 0%);

  ${mqMediumDown(`
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    background-size: cover;
    background-position: top;
  `)}
`;

export const EnergyExpertVisits: ActionCentreBanner = ({
  onClick,
  dismiss,
}) => {
  return (
    <ClippedImageBanner
      analyticsName={ENERGY_EXPERT_VISITS_BANNER}
      heading="Get an Energy Expert visit for less"
      body={
        <P data-testid="energy-expert-body">
          Book by <Strong>31 August</Strong> to get a full home assessment,
          personalised report and more – <Strong>for just £25</Strong>!
        </P>
      }
      linkTo={ENERGY_EXPERT_VISITS_LINK}
      onClick={onClick}
      onDismiss={dismiss}
      ctaTitle="Tell me more"
      cancelTitle="Close"
      CroppedImage={ClippedImg}
    />
  );
};
