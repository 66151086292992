import { TabList, Tabs } from '@ovotech/nebula';
import React from 'react';
import { FuelTabContent } from './Components/FuelTabContent/FuelTabContent';
import { StyledFlexCard, StyledTab, StyledTabPanel } from './FuelTabs.styled';
import { BreakdownScreenResponse, Fuel } from '@/src/types/Response';
import { SelectableIconLabel } from '@/src/pages/EnergyInsights/components';

interface Props extends BreakdownScreenResponse {
  isSingleFuel: boolean;
  fuel: Fuel;
  handleFuelChange: (f: Fuel) => void;
}

export const FuelTabs = ({
  handleFuelChange,
  isSingleFuel,
  ...rest
}: Props) => {
  const selectElectricity = () => handleFuelChange(Fuel.ELECTRICITY);
  const selectGas = () => handleFuelChange(Fuel.GAS);
  const isElectricityTabActive = rest.fuel === Fuel.ELECTRICITY;
  const isGasTabActive = rest.fuel === Fuel.GAS;

  if (isSingleFuel) {
    return (
      <StyledFlexCard>
        <FuelTabContent {...rest} />
      </StyledFlexCard>
    );
  }

  return (
    <Tabs fullWidth="always">
      <TabList>
        <StyledTab active={isElectricityTabActive} onClick={selectElectricity}>
          <SelectableIconLabel
            data-testid="tab-electricity"
            title={Fuel.ELECTRICITY}
            alignCentre
          />
        </StyledTab>
        <StyledTab active={isGasTabActive} onClick={selectGas}>
          <SelectableIconLabel
            data-testid="tab-gas"
            title={Fuel.GAS}
            alignCentre
          />
        </StyledTab>
      </TabList>
      <StyledTabPanel data-testid="tab-panel-electricity">
        {isElectricityTabActive && <FuelTabContent {...rest} />}
      </StyledTabPanel>
      <StyledTabPanel data-testid="tab-panel-gas">
        {isGasTabActive && <FuelTabContent {...rest} />}
      </StyledTabPanel>
    </Tabs>
  );
};
