import { CTALink, Callout, Heading2, Modal, P, Strong } from '@ovotech/nebula';
import { useAccountContext, useAnalytics } from '@ovotech/ui-tools';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useAccountMeterReadFrequencyMutation } from '@/src/api';
import { PATH_TO_ZERO } from '@/src/constants/analytics';
import { ReadingFrequency } from '@/src/types/Response';
import { mqDesktopNav } from '@/src/utils/mediaQuery';
import { ReactComponent as MeterIcon } from '@/src/resources/meter-icon.svg';

const StyledModal = styled(Modal)`
  display: flex;
  & > div:first-of-type {
    display: flex;
    width: 100%;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  width: 100%;

  .setup-link {
    margin-top: auto;
  }

  .meter-icon {
    margin-top: 32px;
  }

  ${mqDesktopNav(`
    .setup-link {
      width: max-content;
    }
    .meter-icon {
      margin-top: 12px;
    }
  `)}
`;

export const NoHalfHourlyReadingsModal = ({
  isOpen,
  onClose,
  onCloseModal,
}: {
  isOpen: boolean;
  onClose: () => void;
  onCloseModal: () => void;
}) => {
  const { accountId } = useAccountContext();
  const metersMutation = useAccountMeterReadFrequencyMutation(accountId ?? '');
  const { dispatch } = useAnalytics();

  const handleClick = () => {
    dispatch({
      name: PATH_TO_ZERO.POWER_MOVE_HALF_HOURLY_SWITCH_CLICKED,
      type: 'click',
    });
    if (!metersMutation.isLoading) {
      metersMutation.mutate({ readingFrequency: ReadingFrequency.HALFHOURLY });
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleClickBackToChallenge = () => {
    dispatch({
      name: PATH_TO_ZERO.POWER_MOVE_HALF_HOURLY_BACK_TO_CHALLENGE,
      type: 'click',
    });
    onCloseModal();
  };

  useEffect(() => {
    if (isOpen) {
      dispatch({
        name: PATH_TO_ZERO.POWER_MOVE_HALF_HOURLY_MODAL_VIEWED,
        type: 'view',
      });
    }
    // dispatch should be stable across renders, but sadly it is not which leads to multiple events being dispatched
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (metersMutation.isSuccess) {
      dispatch({
        name: PATH_TO_ZERO.POWER_MOVE_HALF_HOURLY_SWITCH_SUCCESS,
        type: 'view',
      });
    }
    // dispatch should be stable across renders, but sadly it is not which leads to multiple events being dispatched
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metersMutation.isSuccess]);

  return (
    <StyledModal
      title=""
      isOpen={isOpen}
      onClose={handleClose}
      data-testid="no-half-hourly-modal"
    >
      <StyledContainer>
        <MeterIcon className="meter-icon" />
        {metersMutation.isSuccess ? (
          <>
            <Heading2>We’re switching you over</Heading2>
            <P>
              We’ll start receiving half-hour readings within 24 hours, but it
              could be sooner.
            </P>
            <P>
              Once we do, you’ll be able to join the challenge. So check back
              soon!
            </P>
            <CTALink
              className="setup-link"
              iconRight="off"
              onClick={handleClickBackToChallenge}
            >
              Back to challenge
            </CTALink>
          </>
        ) : (
          <>
            <Heading2>
              {metersMutation.isLoading
                ? 'We’re switching you over'
                : 'Update to half-hourly readings'}
            </Heading2>
            <Strong>Why do I need to do this?</Strong>
            <P>
              We use regular readings to measure how much you save during a
              challenge – and show your home’s average use.
            </P>
            <P>
              It’s quick and easy and won’t change your energy plan or the price
              you pay.
            </P>

            <CTALink
              iconRight="off"
              className="setup-link"
              onClick={handleClick}
            >
              {metersMutation.isLoading
                ? 'Switching over'
                : 'Update to half-hourly'}
            </CTALink>

            {metersMutation.isError && (
              <Callout style={{ paddingTop: '12' }} variant="error">
                <P>Something went wrong. Please try again</P>
              </Callout>
            )}
          </>
        )}
      </StyledContainer>
    </StyledModal>
  );
};
