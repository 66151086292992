import { addDays, isAfter, isValid } from 'date-fns';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BannerId } from '../../ActionCentre/Banners/banners';
import {
  ruleset,
  ServiceActionCodes,
  ServiceBannerConfig,
} from './utils/rulesetForServiceBanners';
import {
  useContractsQuery,
  useFinalStatementQuery,
  useMoveOutDateQuery,
  useSmartMeterBookabilityQuery,
} from '@/src/api';
import {
  OSE_SMART_BOOKING_OFFER_FLAG,
  PACE_PLANNED_MAINTENANCE,
} from '@/src/constants/feature-flags';
import { findContractInLoss, isDualFuelContract } from '@/src/utils/contracts';
import {
  getShowSmartMeterBookingBannerStart,
  getSmartMeterBookingStart,
} from '@/src/redux/ducks/smartMeterBooking/smartMeterBooking';
import { State } from '@/src/types/State';
import { WarrantInstall } from '@/src/types/Response';
import { useFeature } from '@/src/utils/optimizely';

type PartialContracts = Array<{
  status: {
    active: boolean;
    hasFutureContracts: boolean;
  };
}>;

const hasFutureContract = (contracts: PartialContracts): boolean =>
  contracts.findIndex(
    ({ status: { active, hasFutureContracts } }) =>
      active && hasFutureContracts,
  ) !== -1;

function setDismissed(id: BannerId, daysToExpiration: number) {
  const expiryDate = addDays(new Date(), daysToExpiration).toISOString();
  localStorage.setItem(id, expiryDate);
}

function isDismissed(id: BannerId): boolean {
  const storedValue = localStorage.getItem(id);
  if (storedValue) {
    const expiryDate = new Date(storedValue);
    if (isValid(expiryDate) && isAfter(expiryDate, new Date())) {
      return true;
    }
    localStorage.removeItem(id);
  }

  return false;
}

const userHasWarrantInstalls = (meters: WarrantInstall[] | unknown) => {
  if (!meters) return false;

  return (
    Array.isArray(meters) &&
    meters.some((meter: WarrantInstall) => meter.hasWarrantInstall)
  );
};

export const useServiceBanners = (
  accountId?: string | null,
): {
  bannerId: BannerId;
  actionCode: ServiceActionCodes;
  onDismiss: () => void;
}[] => {
  const dispatch = useDispatch();

  const smartMeterBooking = useSelector(
    (state: State) => state.smartMeterBooking,
  );

  const contracts = useContractsQuery(accountId);
  const finalStatement = useFinalStatementQuery();
  const moveOut = useMoveOutDateQuery(accountId);
  const bookability = useSmartMeterBookabilityQuery(accountId);

  const [showPaymentMaintenance] = useFeature(PACE_PLANNED_MAINTENANCE);
  const [oseSmartBookingOffer] = useFeature(OSE_SMART_BOOKING_OFFER_FLAG);

  useEffect(() => {
    dispatch(getSmartMeterBookingStart());
    dispatch(getShowSmartMeterBookingBannerStart());
  }, [dispatch]);

  if (contracts.status !== 'success' || moveOut.status !== 'success') {
    return [];
  }

  const electricityContractInLoss = findContractInLoss(
    contracts.data.electricity,
  );
  const gasContractInLoss = findContractInLoss(contracts.data.gas);

  const { electricity, gas } = contracts.data;
  const isDualFuel = isDualFuelContract(contracts.data);
  const inLoss = Boolean(electricityContractInLoss || gasContractInLoss);
  const inLossForDualFuel = Boolean(
    electricityContractInLoss && gasContractInLoss,
  );
  const lossCompleteForBothFuels = Boolean(
    electricityContractInLoss?.status?.lossComplete &&
      gasContractInLoss?.status?.lossComplete,
  );
  const hasAFinalStatement =
    inLoss &&
    finalStatement.isSuccess &&
    Boolean(finalStatement.data?.electricity || finalStatement.data?.gas);
  const hasBothFinalStatements =
    inLoss &&
    finalStatement.isSuccess &&
    Boolean(finalStatement.data?.electricity && finalStatement.data?.gas);
  const hasMoveOutDate = moveOut.data
    ? Boolean(moveOut.data.moveOutDate)
    : false;
  const hasNextPlan = hasFutureContract(electricity) || hasFutureContract(gas);
  const smartBookingPresent = smartMeterBooking.data
    ? smartMeterBooking.data.booking.outstandingBooking &&
      !userHasWarrantInstalls(bookability?.data)
    : false;

  const rules = ruleset({
    inLoss,
    inLossForDualFuel,
    isDualFuel,
    hasMoveOutDate,
    hasAFinalStatement,
    hasBothFinalStatements,
    hasNextPlan,
    lossCompleteForBothFuels,
    smartBookingPresent,
    oseSmartBookingOffer,
    showPaymentMaintenance,
  });

  return rules
    .filter(({ id }) => !isDismissed(id))
    .reduce<ServiceBannerConfig[]>((acc, banner) => {
      banner.shown && acc.push(banner);
      return acc;
    }, [])
    .map(banner => ({
      bannerId: banner.id,
      actionCode: banner.actionCode,
      onDismiss: () => setDismissed(banner.id, banner.dismissDurationDays || 1),
    }));
};
