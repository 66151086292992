import { useAccountContext } from '@ovotech/ui-tools';
import { isAfter, subMonths } from 'date-fns';
import { useHomeProfileWidgetQuery } from '@/src/api';
import { useLocalStorage } from '@/src/utils/useLocalStorage';
import { HomeProfileWidgetPosition } from './HomeProfileWidget.types';

export const useHomeProfileWidget = () => {
  const { accountId } = useAccountContext();
  const [dismissedDate, setDismissedDate] = useLocalStorage(
    `home_profile_widget_banner_${accountId}`,
    '',
  );

  const isDismissed =
    !!dismissedDate &&
    isAfter(new Date(dismissedDate), subMonths(new Date(), 6));

  const { data } = useHomeProfileWidgetQuery({
    accountId,
    enabled: !isDismissed,
  });

  const widgetPosition: HomeProfileWidgetPosition = !data
    ? 'hidden'
    : data.recentlyUpdated
    ? 'bottom'
    : 'top';

  return {
    widgetPosition,
    data: isDismissed ? null : data,
    onClose: () => setDismissedDate(new Date().toISOString()),
  };
};
