import styled from 'styled-components';
import { FlexCard } from '@/src/pages/EnergyInsights/components';

export const StyledFlexcard = styled(FlexCard)`
  padding: 24px;
  ${({ theme: { colors } }) => `
    border-bottom: 3px solid ${colors.borderMuted};
    border-top: 3px solid ${colors.borderMuted};
  `};

  box-shadow: none;
`;
export const StyledContainer = styled.div`
  height: 85px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
