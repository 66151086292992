import {
  CTALink,
  Card,
  Heading4,
  P,
  Skeleton,
  SkeletonCTA,
  Strong,
  SummaryItem,
  SummaryList,
  TextLink,
} from '@ovotech/nebula';
import { useAnalytics } from '@ovotech/ui-tools';
import React, { forwardRef, useEffect } from 'react';
import styled from 'styled-components';
import { usePowerMoveEligibility } from '../hooks/use-power-move-eligibility';
import { logout } from '@/src/api/utils/logout';
import { PATH_TO_ZERO } from '@/src/constants/analytics';
import { SMART_BOOKING_URL } from '@/src/constants/endpoints';
import { EXTERNAL_ROUTE_SWITCH_WITH_UTM } from '@/src/constants/routes';
import { SMART_METER_HELP_GUIDE_LINK } from '@/src/constants/links';

const StyledSummaryList = styled(SummaryList)`
  ${({ theme }) => `
    padding: ${theme.space[3]} 10px ${theme.space[4]};

    &.success {
        background-color: ${theme.colors.primaryMuted};

    }

    &.error {
        background-color: ${theme.colors.errorMuted};
        border: 4px solid ${theme.colors.error};
    }
`}
`;

const Item = styled.div`
  ${({ theme }) => `
    padding-top: ${theme.space[4]};
    &:not(:last-child) {
        padding-bottom: ${theme.space[4]};
        border-bottom: 1px solid ${theme.colors.primary};
    }
`}
`;

const StyledStrong = styled(Strong)`
  ${({ theme }) => `
    color: ${theme.colors.error};
  `}
`;

const StyledTextLink = styled(TextLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0 0;
  cursor: pointer;
`;

const StyledSkeleton = styled(Skeleton)`
  height: 152px;
  margin-bottom: 8px;
  background-color: #d9d9d9;
`;
const StyledSkeletonCTA = styled(SkeletonCTA)`
  height: 29px;
  background-color: #d9d9d9;
`;

interface PowerMoveEligibilityCardProps {
  hasError?: boolean;
  onUpdateToHHMeterReadsCTAClick: () => void;
}

export const PowerMoveEligibilityCard = forwardRef<
  HTMLDivElement,
  PowerMoveEligibilityCardProps
>((props, ref) => {
  const { hasError = false, onUpdateToHHMeterReadsCTAClick } = props;
  const powerMoveEligibility = usePowerMoveEligibility();
  const analytics = useAnalytics();

  const handleLogoutClick = () => {
    logout();
  };

  useEffect(() => {
    if (
      !powerMoveEligibility.hasElecHalfHourlySmartMeterReadsEnabled &&
      powerMoveEligibility.updatingElecSmartMeterFrequencyToHalfHourly
    ) {
      analytics.dispatch({
        name: PATH_TO_ZERO.POWER_MOVE_HALF_HOURLY_SWITCH_PENDING,
        type: 'view',
      });
    }
    // dispatch should be stable across renders, but sadly it is not which leads to multiple events being dispatched
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    powerMoveEligibility.hasElecHalfHourlySmartMeterReadsEnabled,
    powerMoveEligibility.updatingElecSmartMeterFrequencyToHalfHourly,
  ]);

  const handleClick = () => {
    analytics.dispatch({
      name: PATH_TO_ZERO.POWER_MOVE_SMART_METER_BOOKING_CLICKED,
      type: 'click',
    });
  };

  const getErrorReason = (): string | null => {
    const {
      hasElecSmartMeter,
      hasElecHalfHourlySmartMeterReadsEnabled,
      updatingElecSmartMeterFrequencyToHalfHourly,
      hasGasMeterOnly,
    } = powerMoveEligibility;

    if (hasGasMeterOnly) return null;
    if (!hasElecSmartMeter) {
      return 'You need a smart meter installed to continue';
    }
    if (updatingElecSmartMeterFrequencyToHalfHourly) {
      return "We're switching you over to half-hourly readings";
    }

    if (
      !hasElecHalfHourlySmartMeterReadsEnabled &&
      hasElecSmartMeter &&
      powerMoveEligibility.hasValidFrequency &&
      powerMoveEligibility.canUpdateMeterReadFrequency
    ) {
      return 'Update to half-hourly meter readings to continue';
    }

    if (
      !hasElecHalfHourlySmartMeterReadsEnabled &&
      hasElecSmartMeter &&
      !powerMoveEligibility.hasValidFrequency &&
      powerMoveEligibility.canUpdateMeterReadFrequency
    ) {
      return 'Confirm half-hourly meter readings to continue';
    }

    return null;
  };

  const loadBodyAndHeader = () => {
    if (powerMoveEligibility.hasGasMeterOnly) {
      return {
        bodyContent: (
          <Item>
            <P>Switch to OVO electricity so we can measure your Power Move.</P>
            <P>Already an electricity customer? Log in to another account.</P>
            <CTALink
              href={EXTERNAL_ROUTE_SWITCH_WITH_UTM}
              opensInNewWindow
              fullWidth="always"
              iconRight="off"
              variant="secondary"
            >
              Get an energy quote
            </CTALink>
            <StyledTextLink onClick={handleLogoutClick}>
              Log in to another account
            </StyledTextLink>
          </Item>
        ),
        headerContent: <Heading4>Ready to make a Power Move?</Heading4>,
      };
    }
    if (!powerMoveEligibility.hasElecSmartMeter) {
      return {
        bodyContent: (
          <Item>
            <P>You’ll need a free smart meter installed to join a challenge.</P>
            <CTALink
              href={SMART_BOOKING_URL}
              opensInNewWindow
              fullWidth="always"
              iconRight="off"
              variant="secondary"
              onClick={handleClick}
            >
              Book my appointment
            </CTALink>
          </Item>
        ),
        headerContent: <Heading4>Ready to make a Power Move?</Heading4>,
      };
    }

    if (
      !powerMoveEligibility.updatingElecSmartMeterFrequencyToHalfHourly &&
      powerMoveEligibility.hasElecSmartMeter &&
      !powerMoveEligibility.hasElecHalfHourlySmartMeterReadsEnabled &&
      powerMoveEligibility.hasValidFrequency &&
      powerMoveEligibility.canUpdateMeterReadFrequency
    ) {
      return {
        bodyContent: (
          <Item>
            <P>
              To join a challenge, update your electricity readings to send
              every half hour.
            </P>
            <CTALink
              onClick={onUpdateToHHMeterReadsCTAClick}
              fullWidth="always"
              iconRight="off"
              variant="secondary"
            >
              Update to half-hourly
            </CTALink>
          </Item>
        ),
        headerContent: <Heading4>Ready to make a Power Move?</Heading4>,
      };
    }

    if (
      !powerMoveEligibility.updatingElecSmartMeterFrequencyToHalfHourly &&
      powerMoveEligibility.hasElecSmartMeter &&
      !powerMoveEligibility.hasElecHalfHourlySmartMeterReadsEnabled &&
      !powerMoveEligibility.hasValidFrequency &&
      powerMoveEligibility.canUpdateMeterReadFrequency
    ) {
      return {
        bodyContent: (
          <Item>
            <P>
              We can't check the frequency of your readings at the moment –
              please confirm to continue.
            </P>
            <CTALink
              onClick={onUpdateToHHMeterReadsCTAClick}
              fullWidth="always"
              iconRight="off"
              variant="secondary"
            >
              Confirm half-hourly
            </CTALink>
          </Item>
        ),
        headerContent: <Heading4>How to join</Heading4>,
      };
    }

    if (
      !powerMoveEligibility.updatingElecSmartMeterFrequencyToHalfHourly &&
      powerMoveEligibility.hasElecSmartMeter &&
      !powerMoveEligibility.hasElecHalfHourlySmartMeterReadsEnabled &&
      !powerMoveEligibility.canUpdateMeterReadFrequency
    ) {
      return {
        bodyContent: (
          <Item>
            <P>To join a challenge, you need a connected smart meter.</P>
            <P>
              If your smart meter isn't connecting, our{' '}
              <TextLink target="_blank" href={SMART_METER_HELP_GUIDE_LINK}>
                guide to solving common problems
              </TextLink>{' '}
              could help.
            </P>
            <P>No smart meter? Come back once you have one installed.</P>
          </Item>
        ),
        headerContent: <Heading4>How to join</Heading4>,
      };
    }

    if (
      !powerMoveEligibility.hasElecHalfHourlySmartMeterReadsEnabled &&
      powerMoveEligibility.updatingElecSmartMeterFrequencyToHalfHourly
    ) {
      return {
        bodyContent: (
          <Item>
            <P>
              You can join once we receive your half-hour readings. This usually
              takes up to 24 hours.
            </P>
          </Item>
        ),
        headerContent: <Heading4>Ready to make a Power Move?</Heading4>,
      };
    }

    return {
      bodyContent: null,
      headerContent: null,
    };
  };

  if (powerMoveEligibility.isEligible || powerMoveEligibility.isMetersApiError)
    return null;

  if (powerMoveEligibility.isLoading) {
    return (
      <Card data-testid="power-move-eligibility-loading-card">
        <StyledSkeletonCTA />
        <StyledSkeleton />
      </Card>
    );
  }

  const { bodyContent, headerContent } = loadBodyAndHeader();

  return (
    <Card ref={ref} data-testid="power-move-eligibility-card">
      {headerContent}
      {hasError && <StyledStrong>{getErrorReason()}</StyledStrong>}
      <StyledSummaryList className={hasError ? 'error' : 'success'}>
        <SummaryItem
          label={
            !powerMoveEligibility.hasElecHalfHourlySmartMeterReadsEnabled &&
            powerMoveEligibility.updatingElecSmartMeterFrequencyToHalfHourly
              ? "We're switching you over"
              : 'Before you get started'
          }
        >
          {bodyContent}
        </SummaryItem>
      </StyledSummaryList>
    </Card>
  );
});
