import { Heading2, Modal, P, Strong } from '@ovotech/nebula';
import React from 'react';
import { MappedPowerMoveChallenge, PowerMoveChallengeType } from '../types';
import { formatTime } from '../utils/utils';

export const HowRewardsAreCalculatedModal = ({
  isOpen,
  onClose,
  challenge,
}: {
  isOpen: boolean;
  onClose: () => void;
  challenge: MappedPowerMoveChallenge | null;
}) => {
  return (
    <Modal
      title=""
      isOpen={isOpen}
      data-testid="power-move-how-rewards-are-calculated-modal"
      onClose={onClose}
    >
      <Heading2>
        How to earn {challenge?.displayName.toLocaleLowerCase() ?? ''} rewards
      </Heading2>

      <P>
        To earn a reward, you’ll need to use
        {challenge?.challengeType === PowerMoveChallengeType.MULTI_TIER &&
          ', on average, '}{' '}
        <Strong>
          {challenge?.highestLessThanOrEqualTotalPercentage}% or less
        </Strong>{' '}
        of your total electricity use between{' '}
        {formatTime(challenge?.peakHours?.from)} and{' '}
        {formatTime(challenge?.peakHours?.to)}, Monday to Friday.
      </P>
      <P>
        So, the more electricity you shift outside of these times, the more you
        could earn.{' '}
      </P>
      <Strong>How we calculate rewards</Strong>
      <P>
        We look at your half-hourly electricity readings during peak times over
        the month.
      </P>
      <P>
        To get the percentage, we compare how much electricity you use during
        peak times to your monthly use.
      </P>
    </Modal>
  );
};
