import { Heading3, P } from '@ovotech/nebula';
import React from 'react';
import {
  ActionCentreBanner,
  Banner,
} from '@/src/components/ActionCentre/Banners/Banner';

export const PlannedMaintenance: ActionCentreBanner = () => {
  return (
    <Banner data-testid="payments-maintenance" icon="warning">
      <Heading3>We're undergoing some maintenance</Heading3>
      <P>
        This means you might not be able to request refunds, view your direct
        debit information or use the direct debit calculator. We're sorry for
        any inconvenience - we'll have everything back to normal as soon as
        possible. Please check back later!
      </P>
    </Banner>
  );
};
