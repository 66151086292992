import {
  CarbonIntensityForecast,
  CarbonIntensityLevel,
  NextGreenTimes,
} from './carbon-intensity.types';
import { HomeProfileData } from './home-profile.types';

type ForecastAndGreentime = {
  forecast: CarbonIntensityForecast[];
  greentime: NextGreenTimes | null;
};

export type BreakdownWidgetData = {
  totalUsage: number;
  gasTotalUsage: number;
  elecTotalUsage: number;
  barWidths: { yourBarWidth: string; averageBarWidth: string };
  comparisonTotal: number;
  comparisonBarText: string;
  mostUsedCategories: {
    fuel: string;
    topCategory: CategoryDisplayName;
    secondCategory: CategoryDisplayName;
  };
};

export type CarbonFigures =
  | CarbonFiguresGeneric<CarbonAmounts, null>
  | CarbonFiguresGeneric<null, CarbonAmounts>;

type CarbonFiguresGeneric<UsedCarbonType, SavedCarbonType> = {
  comparisonCarbonTonnes: CarbonAmounts;
  usedCarbonTonnes: UsedCarbonType;
  savedCarbonTonnes: SavedCarbonType;
};

type CarbonAmounts = {
  gas: number | null;
  electricity: number | null;
  total: number;
};

export type HubScreenResponse = {
  hasSolarPanels: boolean | null;
  homeProfile: HomeProfileData | null;
  hasSmartMeter: boolean | null;
  halfHourlyReads: boolean | null;
  carbonFigures: CarbonFigures | null;
  breakdownWidget: BreakdownWidgetData | null;
  hasBreakdownData: boolean | null;
  currentIntensity: {
    index: CarbonIntensityLevel;
  } | null;
  carbonGreentime: ForecastAndGreentime | null;
};

export enum CategoryDisplayName {
  alwaysOn = 'Always on & Standby',
  lighting = 'Lighting',
  other = 'Other Appliances',
  cooking = 'Cooking',
  entertainment = 'Entertainment & Computing',
  refrigeration = 'Fridges & Freezers',
  washing = 'White Goods',
  waterHeating = 'Hot Water',
  heating = 'Heating',
  ev = 'Electric Vehicle Charging',
}
