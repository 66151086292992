import { PrimaryCTAButton } from '@ovotech/nebula';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom-v5';

import { ConnectedWaitlistButton } from '../WaitlistButton';
import { ButtonRightArrow } from './ProductContent.styled';

import { ROUTE_SMART_THERMOSTATS_OFFER } from '@/src/constants/routes';
import { State } from '@/src/types/State';
import { getQueryParamValue } from '@/src/utils/getQueryParamValue';
import { buildPromoCodeLink } from '../../utils/utils';

export const BuyNowButton = () => {
  const history = useHistory();

  const promoCode = getQueryParamValue('promoCode');
  const eligible = useSelector(
    (state: State) => state.smartThermostats.eligibility.general?.eligible,
  );

  const goToQuestions = () => {
    const route = buildPromoCodeLink(
      ROUTE_SMART_THERMOSTATS_OFFER + '/questions',
      promoCode,
    );
    history.push(route);
  };

  const error = useSelector((state: State) => state.smartThermostats.error);

  if (error) {
    return null;
  }

  if (eligible === true) {
    return (
      <PrimaryCTAButton variant="missionPrimary" onClick={goToQuestions}>
        Buy now
        <ButtonRightArrow />
      </PrimaryCTAButton>
    );
  } else if (eligible === false) {
    return <ConnectedWaitlistButton />;
  } else {
    return <></>;
  }
};
