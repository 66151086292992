import { Row, Col } from '@ovotech/nebula';
import React from 'react';
import { useHomeProfilePromptPosition } from '../../hooks/useHomeProfilePromptPosition';
import { HomeProfileProps, HomeProfilePrompt } from './HomeProfilePrompt';
import { PlainCard } from './HomeProfilePrompt.styled';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import AnalyticsService from '@/src/services/analyticsService';

export const HomeProfilePromptBreakdownContainer = ({
  homeProfile,
}: Omit<HomeProfileProps, 'isDismissed' | 'position'>) => {
  const { homeProfilePromptPosition, sendHomeProfilePromptToBottom } =
    useHomeProfilePromptPosition(homeProfile);

  const updateHomeProfileOnClick = () => {
    AnalyticsService.dispatch(
      ENERGY_INSIGHTS.BUTTON_CLICK_UPDATE_HOME_PROFILE_BREAKDOWN,
    );
  };

  const dismissHomeProfileOnClick = () => {
    try {
      AnalyticsService.dispatch(
        ENERGY_INSIGHTS.HOME_PROFILE_PROMPT_DISMISSED_BREAKDOWN,
      );
      sendHomeProfilePromptToBottom();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Row>
      <Col span={12}>
        <PlainCard>
          <HomeProfilePrompt
            position={homeProfilePromptPosition}
            homeProfile={homeProfile}
            updateHomeProfileOnClick={updateHomeProfileOnClick}
            dismissHomeProfileOnClick={dismissHomeProfileOnClick}
          />
        </PlainCard>
      </Col>
    </Row>
  );
};
