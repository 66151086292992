import { Card, P } from '@ovotech/nebula';
import React from 'react';
import { IconWithContent } from './IconWithContent';
import { ReactComponent as PowerMoveLightning } from '@/src/resources/power-move-lightning.svg';

export const PowerMoveHeroBanner = ({ isJoined }: { isJoined: boolean }) => {
  if (isJoined) {
    return (
      <Card>
        <IconWithContent
          icon={<PowerMoveLightning />}
          title="Power Move"
          subTitle="Ready to earn rewards for greener habits?"
        />
      </Card>
    );
  }

  return (
    <Card>
      <IconWithContent
        icon={<PowerMoveLightning />}
        title="Power Move"
        subTitle="Earn rewards for greener habits"
      />
      <P>
        Join our Power Move challenges to use electricity at greener times of
        the day.
      </P>
    </Card>
  );
};
