import { Margin } from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';
import {
  StyledCard,
  PrescriptionSection,
  TargetRewardSection,
  StyledChallengeInfoContainer,
} from './PowerMoveChallenge.styled';
import {
  StyledSkeleton,
  StyledSkeletonCircleWrapper,
  StyledSkeletonCircle as SkeletonCircle,
} from '@/src/components/SkeletonLoading/SkeletonLoading.styled';

const SectionA = styled(PrescriptionSection)`
  padding: 25px;
  ${({ theme: { mediaQueries } }) => `
    background-color: #D9D9D9;
    border-color: #D9D9D9;

    ${mediaQueries.mediumAndUp} {
      padding: 35px 25px;
    }
    
    &::before {
      border-color: #D9D9D9 transparent transparent transparent;
      
      ${mediaQueries.mediumAndUp} {
        border-color: transparent transparent transparent #D9D9D9;
      }
    }
  `};
`;

const SectionB = styled(TargetRewardSection)`
  padding: 34px;
  background-color: white;
`;

const StyledCircle = styled(SkeletonCircle)`
  background-color: white;
`;

const StyledHeadingTextA = styled(StyledSkeleton)`
  width: 100%;
  height: 1.3em;
  max-width: 153px;
  margin-bottom: 0.6rem;
  background-color: white;
`;

const StyledTextA = styled(StyledHeadingTextA)<{
  maxWidth?: string;
}>(
  ({ maxWidth = '350' }) => `
  height: 0.95em;
  max-width: ${maxWidth}px;
`,
);

const StyledMargin = styled(Margin)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledTextB = styled(StyledTextA)`
  height: 0.722em;
  background-color: #d9d9d9;
`;

export const PowerMoveMonthChallengeSkeleton = () => (
  <StyledCard data-testid="power-move-month-challenge-skeleton">
    <StyledChallengeInfoContainer>
      <SectionA>
        <Margin horizontal={6} vertical={3}>
          <StyledSkeletonCircleWrapper size="6rem">
            <StyledCircle />
          </StyledSkeletonCircleWrapper>
        </Margin>
        <StyledHeadingTextA />
        <StyledHeadingTextA />
        <StyledMargin vertical={6}>
          <StyledTextA maxWidth="199" />
          <StyledTextA maxWidth="303" />
          <StyledTextA maxWidth="199" />
        </StyledMargin>
      </SectionA>
      <SectionB>
        <StyledTextB maxWidth="51" />
        <StyledTextB />
        <StyledTextB />
        <Margin top={6} />
        <StyledTextB maxWidth="51" />
        <StyledTextB />
        <StyledTextB />
      </SectionB>
    </StyledChallengeInfoContainer>
  </StyledCard>
);
