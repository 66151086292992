import { TextLink } from '@ovotech/nebula';
import React from 'react';
import { FootnoteReferenced } from '@/src/components';
import {
  SMART_THERM_TERMS_AND_CONDITIONS_LINK,
  TADO_GUIDE_LINK,
  TADO_STUDY,
} from '@/src/constants/routes';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';
import { AnalyticsClick } from '@ovotech/ui-tools';

function FullTermsAndConditionsLink({ children }: any) {
  return (
    <TextLink href={SMART_THERM_TERMS_AND_CONDITIONS_LINK}>{children}</TextLink>
  );
}

function StudyLink({ children }: any) {
  return <TextLink href={TADO_STUDY}>{children}</TextLink>;
}

function InstallingYourselfLink({ children }: any) {
  return <TextLink href={TADO_GUIDE_LINK}>{children}</TextLink>;
}

function CutWasteTitleFootnote() {
  return (
    <FootnoteReferenced
      content={{
        id: 'CutWasteTitle',
        body: [
          <>
            Savings on your energy as a result of receiving personalised energy
            saving tips are not guaranteed and will only occur as a result of
            behavioral changes leading to a reduction in your energy
            consumption. Actual savings will also depend on other factors such
            as the type of heating system in your home and the way home is
            insulated.
          </>,
        ],
      }}
    />
  );
}

function CutWasteDescriptionFootnote() {
  return (
    <FootnoteReferenced
      style={{ fontSize: 9 }}
      content={{
        id: 'CutWasteDescription',
        body: [
          <>
            Eligibility requirements to get a gas smart meter apply, please see
            our{' '}
            <AnalyticsClick
              name={
                SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.PRODUCT_PAGE.CLICKED
                  .TERMS_AND_CONDITIONS
              }
            >
              <FullTermsAndConditionsLink>
                terms and conditions
              </FullTermsAndConditionsLink>
            </AnalyticsClick>{' '}
            for more information.
          </>,
        ],
      }}
    />
  );
}

function CutWasteSecondDescriptionFootnote() {
  return (
    <FootnoteReferenced
      style={{ fontSize: 9 }}
      content={{
        id: 'CutWasteSecondDescription',
        body: [
          <>
            We are still developing this service, so we may make some changes
            and improvements from time to time. As this is a developing product
            things may not always work perfectly but we will do our best to fix
            any problems as soon as we can.
          </>,
        ],
      }}
    />
  );
}

function PriceExplanationFootnote({
  monthlyPaymentAmount,
}: {
  monthlyPaymentAmount: number;
}) {
  return (
    <FootnoteReferenced
      content={{
        id: 'PriceExplanation',
        body: [
          <>
            You can pay upfront or spread the cost over 12 interest free monthly
            installments of £{monthlyPaymentAmount}. Monthly payment option is
            subject to eligibility and credit status. Please see our tadoº{' '}
            <AnalyticsClick
              name={
                SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.PRODUCT_PAGE.CLICKED
                  .TERMS_AND_CONDITIONS
              }
            >
              <FullTermsAndConditionsLink>
                terms and conditions
              </FullTermsAndConditionsLink>
            </AnalyticsClick>{' '}
            for more information.
          </>,
        ],
      }}
    />
  );
}

function IndependentStudyFootnote() {
  return (
    <FootnoteReferenced
      content={{
        id: 'IndependentStudy',
        body: [
          <>
            An <StudyLink>independent study</StudyLink> has shown that
            installing and correctly using a tado° smart thermostat can reduce
            your energy use from heating by up to 28%. The study found that the
            tado° device could save you up to 23% by turning the temperature
            down when nobody is at home. You could also save another 6% by
            turning down the heating based on the weather forecast, depending on
            the window sizes. If the window surfaces are relatively large, the
            energy saving will increase.
          </>,
        ],
      }}
    />
  );
}

function PriceFootnote({ id }: { id: string } = { id: 'Price' }) {
  return (
    <FootnoteReferenced
      content={{
        id,
        body: [
          <>
            Saving based on the manufacturer's recommended retail price of
            £199.99.
          </>,
        ],
      }}
    />
  );
}

function TermsAndConditionsFootnote() {
  return (
    <FootnoteReferenced
      content={{
        id: 'Terms',
        body: [
          <>
            See the full{' '}
            <AnalyticsClick
              name={
                SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.PRODUCT_PAGE.CLICKED
                  .TERMS_AND_CONDITIONS
              }
            >
              <FullTermsAndConditionsLink>
                terms and conditions
              </FullTermsAndConditionsLink>
            </AnalyticsClick>{' '}
            for full compatibility details.
          </>,
        ],
      }}
    />
  );
}

function InstallingYourselfFootnote() {
  return (
    <FootnoteReferenced
      content={{
        id: 'InstallingYourself',
        body: [
          <>
            <AnalyticsClick
              name={
                SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.PRODUCT_PAGE.CLICKED
                  .HOW_TO_INSTALL
              }
            >
              <InstallingYourselfLink>
                How to install the Wireless Smart Thermostat yourself.
              </InstallingYourselfLink>
            </AnalyticsClick>
          </>,
        ],
      }}
    />
  );
}

export {
  CutWasteTitleFootnote,
  CutWasteDescriptionFootnote,
  CutWasteSecondDescriptionFootnote,
  PriceExplanationFootnote,
  IndependentStudyFootnote,
  PriceFootnote,
  InstallingYourselfFootnote,
  TermsAndConditionsFootnote,
};
