import { Heading3, Li, P, Ul } from '@ovotech/nebula';
import { AnalyticsRender, useAnalytics } from '@ovotech/ui-tools';

import React from 'react';
import { footnoteOHSQ1PromoOfferNoClaims } from '../FooterTermsAndConditions';
import Offer from './Offer/Offer';
import { FootnoteReferenced } from '@/src/components';

import { HOMEPLAN_FIXED_CONTRACT_Q1_PROMO_OFFER } from '@/src/constants/analytics-subjects';
import { createHomePlanUrl } from '@/src/constants/endpoints';
import image from '@/src/resources/offers/boiler-cover.jpg';

type Props = {
  accountId: string;
};

export const HomePlanQ1Offer = ({ accountId }: Props) => {
  const analytics = useAnalytics();

  return (
    <AnalyticsRender name={HOMEPLAN_FIXED_CONTRACT_Q1_PROMO_OFFER}>
      <Offer
        image={image}
        offerId="q1PromoOffer"
        linkUrl={createHomePlanUrl(
          'offers-and-upgrades',
          accountId,
          '&utm_medium=banner&utm_source=offers-and-upgrades&utm_campaign=fixed_addon_q1_offer',
        )}
        linkText="Apply now"
        tracking={() => {
          analytics.dispatch({
            name: HOMEPLAN_FIXED_CONTRACT_Q1_PROMO_OFFER,
            type: 'click',
          });
        }}
      >
        <Heading3 as="h2">
          Get 50% off boiler cover for 6 months.
          <FootnoteReferenced content={footnoteOHSQ1PromoOfferNoClaims} />
        </Heading3>

        <P>
          Protect your home with our boiler cover – on offer until 21 March.
        </P>

        <Ul>
          <Li>Make claims online, or call our 24/7 helpline</Li>
          <Li>Over 195,000 boilers serviced last year</Li>
          <Li>
            Choose from 3 plans – get your electrics, drains, taps and toilets{' '}
          </Li>
        </Ul>
      </Offer>
    </AnalyticsRender>
  );
};
