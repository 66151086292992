import React from 'react';
import { NoSolarBreakdown } from '../../../NoUsageBreakdown/NoSolarBreakdown';
import { NoUsageBreakdown } from '../../../NoUsageBreakdown/NoUsageBreakdown';
import FuelBreakdownContent from './FuelBreakdownContent';
import { Fuel } from '@/src/types/Response';
import { HomeProfilePromptBreakdownContainer } from '@/src/pages/EnergyInsights/components';
import { useHomeProfilePromptPosition } from '@/src/pages/EnergyInsights/hooks/useHomeProfilePromptPosition';
import { HomeProfileData } from '@/src/pages/EnergyInsights/types';
import { BreakdownWeek } from '@/src/pages/EnergyInsights/types/bill-breakdown.types';

type FuelBreakdownProps = {
  breakdownWeek: BreakdownWeek;
  lastWeekBreakdown?: BreakdownWeek;
  fuel: Fuel;
  hasSolarPanels: boolean | null;
  homeProfile: HomeProfileData | null;
};

export const FuelBreakdown = (props: FuelBreakdownProps) => {
  const {
    breakdownWeek,
    fuel,
    hasSolarPanels,
    homeProfile,
    lastWeekBreakdown,
  } = props;

  const { homeProfilePromptPosition } =
    useHomeProfilePromptPosition(homeProfile);
  const { unavailableReason } = breakdownWeek[fuel] ?? {};

  if (unavailableReason) {
    return <NoUsageBreakdown fuel={fuel} reason={unavailableReason} />;
  }

  return (
    <>
      {homeProfilePromptPosition === 'top' && (
        <HomeProfilePromptBreakdownContainer homeProfile={homeProfile} />
      )}

      {fuel === Fuel.ELECTRICITY && hasSolarPanels ? (
        <NoSolarBreakdown />
      ) : (
        <FuelBreakdownContent
          fuel={fuel}
          breakdownWeek={breakdownWeek}
          lastWeekBreakdown={lastWeekBreakdown}
        />
      )}
      {homeProfilePromptPosition === 'bottom' && (
        <HomeProfilePromptBreakdownContainer homeProfile={homeProfile} />
      )}
    </>
  );
};
