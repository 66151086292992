import React from 'react';
import SiteLoading from '@/src/components/Wrapper/SiteLoading';
import { redirectToMobileStore } from '@/src/utils/mobileStoreRedirect';

export const URL_BEYOND = 'https://www.ovoenergy.com/beyond';

export const Shop = () => {
  // If not on mobile: Shop doesn't exist - redirect to Beyond page
  if (!redirectToMobileStore()) {
    window?.location.replace(URL_BEYOND);
  }

  return <SiteLoading />;
};
