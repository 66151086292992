import {
  Card,
  Heading3,
  Margin,
  Small,
  Strong,
  PrimaryCTAButton,
  SecondaryCTALink,
} from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom-v5';
import { ButtonGroup } from '@/src/components/ButtonGroup/ButtonGroup';
import {
  EVS_INLIFE_DISCONNECT_CANCEL_LINK,
  EVS_INLIFE_DISCONNECT_CONFIRMED_LINK,
} from '@/src/constants/analytics-subjects';
import { ROUTE_EV } from '@/src/constants/routes';
import { disconnectKaluzaSmartChargerDataStart } from '@/src/redux/ducks/evs/evInlife';

export const EVInlifeSmartChargerDisconnectRequest = () => {
  const dispatch = useDispatch();
  const submitAction = () => dispatch(disconnectKaluzaSmartChargerDataStart());

  return (
    <Card>
      <Heading3>
        Are you sure you want to stop sharing your data with OVO?
      </Heading3>
      <Margin top={3} bottom={3}>
        <Small>
          If you say <Strong>Yes</Strong>, we won’t be able to show you tailored
          insights about your car’s charging costs and carbon footprint.
        </Small>
      </Margin>
      <ButtonGroup>
        <AnalyticsClick name={EVS_INLIFE_DISCONNECT_CONFIRMED_LINK}>
          <PrimaryCTAButton
            data-testid="ev-inlife-yes"
            iconRight="off"
            as={Link}
            onClick={submitAction}
          >
            Yes I'm sure
          </PrimaryCTAButton>
        </AnalyticsClick>
        <AnalyticsClick
          name={EVS_INLIFE_DISCONNECT_CANCEL_LINK}
          data-testid="ev-inlife-no"
        >
          <SecondaryCTALink href={ROUTE_EV}>
            No, I've changed my mind
          </SecondaryCTALink>
        </AnalyticsClick>
      </ButtonGroup>
    </Card>
  );
};
