import { P } from '@ovotech/nebula';
import React, { useEffect } from 'react';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import AnalyticsService from '@/src/services/analyticsService';

export const SmbNotAvailable = () => {
  useEffect(() => {
    AnalyticsService.dispatch(
      ENERGY_INSIGHTS.NO_USAGE_BREAKDOWN_SMB_NOT_AVAILABLE,
    );
  }, []);

  return (
    <div data-testid="no-smart-meter-unavailable">
      <P>
        You need a smart meter to access Spotlights, but all of our engineers
        are busy at the moment.
      </P>
      <P>Please check back soon.</P>
    </div>
  );
};
