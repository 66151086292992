import { P, PrimaryCTALink, TextLink } from '@ovotech/nebula';
import React from 'react';
import { DISCOVER_TADO, TADO_TERMS_AND_CONDITIONS } from '../../constants';
import { Price } from '../Price';
import { PtzOffer } from '../PtzOffer';
import { UnorderedListSection } from '../UnorderedListSection';
import { CLICKED_OFFER_EVENT } from '@/src/constants/analytics';
import analyticsService from '@/src/services/analyticsService';
import SmartThermostatOfferImage from '@/src/resources/offers/smartThermostat.png';
import SmartThermostatOfferImageV2 from '@/src/resources/offers/smartThermostatV2.png';
import { useFeature } from '@/src/utils/optimizely';
import { tadoRRP } from '@/src/pages/SmartThermostats/constants';

interface TadoOfferCardProps {
  price: number;
  linkUrl: string;
  tracking?: () => void;
}

const TADO_LIST = [
  'Set a temperature by the hour – so your heating is always just right',
  'Get reminders when to turn your heating down to save energy',
  'Works with the weather – so on a sunny day, tado° turns down the heating',
  "Notifications remind you to adjust your heating when you're not at home",
];

const tadoContent = {
  v1: {
    imagePath: SmartThermostatOfferImage,
    title: (price: number) => `Get a Tado Smart Thermostat for just £${price}`,
    description:
      'Unlock further savings by controlling your heating and hot water, knocking up to 28% off your home heating bill!',
  },
  v2: {
    imagePath: SmartThermostatOfferImageV2,
    title: (price: number) => `Get a tado° Smart Thermostat for just £${price}`,
    description:
      'Unlock further savings by controlling your heating and hot water, knocking up to 28% off your home heating bill.',
  },
};

export const TadoOffer = ({ price, tracking, linkUrl }: TadoOfferCardProps) => {
  const [displayTadoOfferV2] = useFeature('tado_offer_v2');
  const { imagePath, title, description } =
    tadoContent[displayTadoOfferV2 ? 'v2' : 'v1'];

  return (
    <PtzOffer
      imagePath={imagePath}
      imageAlt="Smart thermostat offer"
      title={title(price)}
      description={description}
    >
      <UnorderedListSection title="What’s included:" list={TADO_LIST} />
      <Price price={`£${price}`} />
      <span style={{ textDecoration: 'line-through' }}>
        RRP {`£${tadoRRP}`}
      </span>
      <P>
        View the{' '}
        <TextLink href={TADO_TERMS_AND_CONDITIONS} opensInNewWindow>
          tado° terms
        </TextLink>{' '}
      </P>

      <PrimaryCTALink
        href={linkUrl}
        onClick={() => {
          analyticsService.dispatch(CLICKED_OFFER_EVENT, {
            offerType: 'thermostats',
          });
          tracking?.();
        }}
        iconRight="off"
        data-testid="offer-cta-thermostats"
      >
        Buy now
      </PrimaryCTALink>

      <TextLink
        style={{ textAlign: 'center' }}
        href={DISCOVER_TADO}
        opensInNewWindow
      >
        Discover tado°
      </TextLink>
    </PtzOffer>
  );
};
