import { useProductCatalogueQueryV2 } from '@/src/api';
import { PRODUCT_IDS } from '@/src/constants/products';

export type EligibilityForPayAsYouDrive = {
  isFetching: boolean;
  isError: boolean;
  ineligibleNotSmartMeter: boolean;
  ineligibleInvalidMeterReadFrequency: boolean;
  ineligibleOther: boolean;
  ineligibleOnlyGas: boolean;
};

export function useCheckEligibilityForPayAsYouDrive(): EligibilityForPayAsYouDrive {
  const productsCatalogue = useProductCatalogueQueryV2();
  const catalogueBoltOns = productsCatalogue?.data?.boltons ?? [];
  const payAsYouDrive = catalogueBoltOns.find(
    (bolton: { productId: string }) =>
      bolton.productId === PRODUCT_IDS.payasyoudrive,
  );

  const payAsYouDriveIneligibilityReasons = payAsYouDrive?.eligibility.reason;
  const ineligibleNotSmartMeter =
    payAsYouDriveIneligibilityReasons?.toString() === 'NotSmartMeter';
  const ineligibleInvalidMeterReadFrequency =
    payAsYouDriveIneligibilityReasons?.toString() ===
    'InvalidMeterReadFrequency';
  const ineligibleOnlyGas =
    payAsYouDriveIneligibilityReasons?.toString() === 'Loss';
  const ineligibleOther =
    payAsYouDrive?.eligibility.eligible === false &&
    !ineligibleNotSmartMeter &&
    !ineligibleInvalidMeterReadFrequency &&
    !ineligibleOnlyGas;

  return {
    isFetching: productsCatalogue.isFetching,
    isError: productsCatalogue.isError,
    ineligibleNotSmartMeter,
    ineligibleInvalidMeterReadFrequency,
    ineligibleOther,
    ineligibleOnlyGas,
  };
}
