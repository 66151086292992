import React, { useEffect } from 'react';

import { SmbNotAvailable } from './SmbNotAvailable';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import AnalyticsService from '@/src/services/analyticsService';

export const UnknownSmbNotAvailableReason = () => {
  useEffect(() => {
    AnalyticsService.dispatch(
      ENERGY_INSIGHTS.NO_USAGE_BREAKDOWN_SMB_UNKNOWN_REASON,
    );
  }, []);

  return <SmbNotAvailable />;
};
