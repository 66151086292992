import React, { useEffect } from 'react';

import { SHOW_CARD_PAYMENT_POL } from '@/src/constants/feature-flags';
import { CardPaymentSession } from '@/src/pages';
import CardPayment from '@/src/pages/Payments/CardPayment';
import { useFeature } from '@/src/utils/optimizely';
import { useOptimizely } from '@/src/utils/useOptimizely';

export const CardPaymentToggle = () => {
  const optimizely = useOptimizely();
  const [showCardPaymentPol] = useFeature(SHOW_CARD_PAYMENT_POL, {
    enabled: true,
  });

  useEffect(() => {
    optimizely?.track('visited_make_a_payment_screen');
  }, [optimizely]);

  return showCardPaymentPol ? <CardPaymentSession /> : <CardPayment />;
};
