import { SmartChargers } from './anytime-chargers';

export type Make = string;
export type Model = string;

export type Type = 'PHEV' | 'MHEV' | 'BEV';

export type Status = 'BETA ELIGIBILITY' | 'FULLY ELIGIBLE' | 'INELIGIBLE';

const VWGMakes = ['Audi', 'Volkswagen', 'ŠKODA', 'Cupra'];

export type Vehicle = {
  make: Make;
  model: Model;
  yearFrom?: number;
  years?: Array<number>;
  type: Type;
  status: Status;
};

const vehicles: Vehicle[] = [
  {
    make: 'Audi',
    model: 'A3',
    yearFrom: 2015,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'A3 Sportback e-tron',
    yearFrom: 2015,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'A3 Sportback TFSI e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'A6 Avant TFSI e',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'A6 Limousine TFSI e',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'A7 Sportback',
    yearFrom: 2012,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'A7 Sportback TFSI e',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'A8 L TFSI e',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'A8 TFSI e',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'e-tron',
    yearFrom: 2019,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'e-tron GT',
    yearFrom: 2022,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'e-tron S',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'e-tron Sportback',
    yearFrom: 2020,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'e-tron S Sportback',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'Q3 Sportback TFSI e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'Q3 TFSI e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'Q4 e-tron',
    yearFrom: 2022,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'Q4 Sportback e-tron',
    yearFrom: 2022,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'Q5 Sportback TFSI e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'Q5 TFSI e',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'Q6 e-tron',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'Q7',
    yearFrom: 2016,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'Q7 e-tron',
    yearFrom: 2016,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'Q7 TFSI e',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'Q8 e-tron',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'Q8 Sportback e-tron',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'Q8 TFSI e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'RS e-tron GT',
    yearFrom: 2022,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'SQ8 e-tron',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'SQ8 Sportback e-tron',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'A4 Avant',
    yearFrom: 2012,
    type: 'MHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'A4 Sedan',
    yearFrom: 2013,
    type: 'MHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'A5 Cabriolet',
    yearFrom: 2017,
    type: 'MHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'A5 Coupe',
    yearFrom: 2018,
    type: 'MHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'A5 Sportback',
    yearFrom: 2013,
    type: 'MHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'A6 Avant',
    yearFrom: 2011,
    type: 'MHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'A6 Sedan',
    yearFrom: 2010,
    type: 'MHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'A8',
    yearFrom: 2018,
    type: 'MHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'Q3 Sportback',
    yearFrom: 2020,
    type: 'MHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'Q5',
    yearFrom: 2012,
    type: 'MHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'Q5 Sportback',
    yearFrom: 2021,
    type: 'MHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'RS6 Avant',
    yearFrom: 2013,
    type: 'MHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Audi',
    model: 'S6 Avant',
    yearFrom: 2020,
    type: 'MHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'BMW',
    model: '225e',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: '225xe',
    yearFrom: 2015,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: '230e',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: '320e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: '330e',
    yearFrom: 2015,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: '530e',
    yearFrom: 2017,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: '545e',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: '740e',
    yearFrom: 2016,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: '740Le',
    yearFrom: 2016,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: '745e',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: '745Le',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'i3',
    yearFrom: 2013,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'i3 120',
    yearFrom: 2018,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'i3 94',
    yearFrom: 2016,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'i3s 120',
    yearFrom: 2018,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'i3s 94',
    yearFrom: 2017,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'i4 eDrive35',
    yearFrom: 2023,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'i4 eDrive40',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'i4 M50',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'i5 eDrive40',
    yearFrom: 2023,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'i5 M60',
    yearFrom: 2023,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'i7 60',
    yearFrom: 2022,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'i7 eDrive50',
    yearFrom: 2023,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'i8',
    yearFrom: 2014,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'iX1 30',
    yearFrom: 2022,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'iX1 eDrive20',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'iX3',
    yearFrom: 2020,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'iX3 M Sport',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'iX 40',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'iX 50',
    yearFrom: 2020,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'iX M60',
    yearFrom: 2022,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'iX xDrive40',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'iX xDrive50',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'X1 25e',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'X1 30e',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'X1 xDrive25e',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'X2 25e',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'X2 xDrive25e',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'X3 30e',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'X3 xDrive30e',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'X5 40e',
    yearFrom: 2015,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'X5 45e',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'X5 50e',
    yearFrom: 2023,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'BMW',
    model: 'X5 xDrive45e',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Cadillac',
    model: 'Lyriq',
    yearFrom: 2023,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Chevrolet',
    model: 'Blazer EV',
    yearFrom: 2024,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Chevrolet',
    model: 'Bolt EUV',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Chevrolet',
    model: 'Bolt EV',
    yearFrom: 2017,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Chevrolet',
    model: 'Volt',
    yearFrom: 2019,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Citroën',
    model: 'BERLINGO (VAN)',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Citroën',
    model: 'C5 AIRCROSS',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Citroën',
    model: 'C5 X',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Citroën',
    model: 'Ë-BERLINGO',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Citroën',
    model: 'Ë-C4',
    yearFrom: 2020,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Citroën',
    model: 'Ë-C4X',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Citroën',
    model: 'Ë-Jumpy',
    yearFrom: 2020,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Citroën',
    model: 'Ë-SPACETOURER',
    yearFrom: 2020,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Cupra',
    model: 'Born',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Cupra',
    model: 'Formentor',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Cupra',
    model: 'Leon',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Cupra',
    model: 'Mii',
    yearFrom: 2022,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Cupra',
    model: 'Tarraco',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'DS',
    model: 'DS 3',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'DS',
    model: 'DS 3 CROSSBACK E-TENSE',
    yearFrom: 2019,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'DS',
    model: 'DS 4',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'DS',
    model: 'DS 4 E-TENSE',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'DS',
    model: 'DS 7',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'DS',
    model: 'DS 7 CROSSBACK E-TENSE 4x4',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'DS',
    model: 'DS 9',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Fiat',
    model: '500BEV',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Fiat',
    model: '600e',
    yearFrom: 2023,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Ford',
    model: 'Escape',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Ford',
    model: 'Explorer',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Ford',
    model: 'F-150',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Ford',
    model: 'Focus Electric',
    yearFrom: 2013,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Ford',
    model: 'Fusion Hybrid',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Ford',
    model: 'Kuga',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Ford',
    model: 'Kuga Vignale',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Ford',
    model: 'Mustang Mach-E',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Ford',
    model: 'Tourneo Custom',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Ford',
    model: 'Transit',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Ford',
    model: 'Transit Custom',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'GMC',
    model: 'HUMMER EV',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Hyundai',
    model: 'IONIQ',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Hyundai',
    model: 'IONIQ',
    yearFrom: 2019,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Hyundai',
    model: 'IONIQ 5',
    yearFrom: 2022,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Hyundai',
    model: 'IONIQ 6',
    yearFrom: 2023,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Hyundai',
    model: 'KONA',
    yearFrom: 2019,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Hyundai',
    model: 'SANTA FE',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Hyundai',
    model: 'TUCSON',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Hyundai',
    model: 'TUCSON',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Jaguar',
    model: 'E-PACE',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Jaguar',
    model: 'F-PACE',
    yearFrom: 2017,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Jaguar',
    model: 'I-PACE',
    yearFrom: 2019,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Jaguar',
    model: 'XE',
    yearFrom: 2016,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Jaguar',
    model: 'XF',
    yearFrom: 2017,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Kia',
    model: 'CEED',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Kia',
    model: 'E-NIRO',
    yearFrom: 2020,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Kia',
    model: 'E-SOUL',
    yearFrom: 2020,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Kia',
    model: 'EV6',
    yearFrom: 2022,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Kia',
    model: 'EV9',
    yearFrom: 2024,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Kia',
    model: 'NIRO',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Kia',
    model: 'NIRO',
    yearFrom: 2019,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Kia',
    model: 'SORENTO',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Kia',
    model: 'Soul',
    yearFrom: 2020,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Kia',
    model: 'SPORTAGE',
    yearFrom: 2022,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Kia',
    model: 'SPORTAGE',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Kia',
    model: 'XCeed',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Land Rover',
    model: 'Defender',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Land Rover',
    model: 'Discovery',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Land Rover',
    model: 'Range Rover',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Lexus',
    model: 'RX',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Lexus',
    model: 'RX',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Lexus',
    model: 'RZ',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Lexus',
    model: 'UX',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Mercedes',
    model: '111 KA/ L4X2 3200',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: '112 KA/ E4X2 3430',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: '129 VTP/E4X2 3430',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: '129 VTP/L4X2 3200',
    yearFrom: 2001,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: '300 EQV/E4X2 3430',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: '300  EQV/L4X2   3200',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: '312 KA 4X2 3924',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'A 250 e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'AMG EQE 43',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'AMG EQE 53',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'AMG EQS 53',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'C 300 de',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'C 300 e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'C 350 e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'CLA 250 e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'E 300 de',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'E 300 e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'E 350 e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'EQA 250',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'EQA 250+',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'EQA 300',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'EQA 350',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'EQB 250',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'EQB 300',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'EQB 350',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'EQC 400',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'EQE 300',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'EQE 350',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'EQE 350+',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'EQE 400',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'EQS 350',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'EQS 450',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'EQS 450+',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'EQS 580',
    yearFrom: 2021,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'EQV 300',
    yearFrom: 2001,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'GLA 250 e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'GLC 300 de',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'GLC 300 e',
    yearFrom: 2006,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'GLC 350 e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'GLE 350 e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'GLE 500 e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'S 580 e',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Mercedes',
    model: 'Vito Panel Van',
    yearFrom: 2002,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'MINI',
    model: 'Cooper SE',
    yearFrom: 2019,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'MINI',
    model: 'Cooper SE ALL4',
    yearFrom: 2017,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'MINI',
    model: 'Countryman Hybrid',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'MINI',
    model: 'Countryman SE ALL4',
    yearFrom: 2023,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Nio',
    model: 'EL6',
    yearFrom: 2023,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Nio',
    model: 'EL7',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Nio',
    model: 'ES8',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Nio',
    model: 'ET5',
    yearFrom: 2023,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Nio',
    model: 'ET7',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Nissan',
    model: 'Ariya',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Nissan',
    model: 'E-NV200',
    yearFrom: 2018,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Nissan',
    model: 'Leaf',
    yearFrom: 2019,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Nissan',
    model: 'Leaf ZE1',
    yearFrom: 2018,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Nissan',
    model: 'Leaf ZE1-P',
    yearFrom: 2019,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Nissan',
    model: 'TOWNSTAR',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Opel',
    model: 'Astra',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Opel',
    model: 'Combo-e LIFE',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Opel',
    model: 'COMBUSES VAN L2',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Opel',
    model: 'COMBUSES VAN L3',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Opel',
    model: 'Corsa-e',
    yearFrom: 2020,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Opel',
    model: 'Grandland X',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Opel',
    model: 'Mokka-e',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Opel',
    model: 'PANEL VAN L1',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Opel',
    model: 'Vivaro',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Opel',
    model: 'Zafira Life 20',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Peugeot',
    model: '3008',
    yearFrom: 2018,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Peugeot',
    model: '308 SW',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Peugeot',
    model: '508',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Peugeot',
    model: 'e-2008',
    yearFrom: 2020,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Peugeot',
    model: 'e-208',
    yearFrom: 2019,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Peugeot',
    model: 'e-208 GT',
    yearFrom: 2020,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Peugeot',
    model: 'e-RIFTER',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Peugeot',
    model: 'EXPERT',
    yearFrom: 2019,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Peugeot',
    model: 'PARTNER',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Peugeot',
    model: 'TRAVELLER',
    yearFrom: 2020,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Porsche',
    model: 'Cayenne E-Hybrid',
    yearFrom: 2019,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Porsche',
    model: 'Cayenne S E-Hybrid',
    yearFrom: 2015,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Porsche',
    model: 'Cayenne Turbo S E-Hybrid',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Porsche',
    model: 'Panamera 4 E-Hybrid',
    yearFrom: 2017,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Porsche',
    model: 'Panamera 4S E-Hybrid',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Porsche',
    model: 'Panamera Turbo S E-Hybrid',
    yearFrom: 2018,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Porsche',
    model: 'Taycan',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Porsche',
    model: 'Taycan 4',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Porsche',
    model: 'Taycan 4S',
    yearFrom: 2020,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Porsche',
    model: 'Taycan GTS',
    yearFrom: 2022,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Porsche',
    model: 'Taycan Turbo',
    yearFrom: 2020,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Porsche',
    model: 'Taycan Turbo S',
    yearFrom: 2020,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Porsche',
    model: 'Panamera S E-Hybrid',
    yearFrom: 2014,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Renault',
    model: 'CAPTUR II',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Renault',
    model: 'KANGOO 3 VAN',
    yearFrom: 2022,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Renault',
    model: 'KANGOO II',
    yearFrom: 2014,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Renault',
    model: 'MEGANE E-TECH',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Renault',
    model: 'MEGANE IV',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Renault',
    model: 'MEGANE IV BREAK',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Renault',
    model: 'SCENIC E-TECH',
    yearFrom: 2023,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Renault',
    model: 'TWINGO III',
    yearFrom: 2020,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Renault',
    model: 'ZOE',
    yearFrom: 2019,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Renault',
    model: 'MASTER III',
    yearFrom: 2020,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Renault',
    model: 'TWIZY',
    yearFrom: 2012,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Rivian',
    model: 'R1S',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Rivian',
    model: 'R1T',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'SEAT',
    model: 'Formentor',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'SEAT',
    model: 'Leon',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'SEAT',
    model: 'Mii',
    yearFrom: 2020,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'SEAT',
    model: 'Tarraco',
    yearFrom: 2021,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'ŠKODA',
    model: 'CITIGOe iV',
    yearFrom: 2019,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'ŠKODA',
    model: 'Enyaq',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'ŠKODA',
    model: 'OCTAVIA iV',
    yearFrom: 2013,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'ŠKODA',
    model: 'SUPERB iV',
    yearFrom: 2016,
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Tesla',
    model: 'Cybertruck',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Tesla',
    model: 'Model 3',
    yearFrom: 2017,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Tesla',
    model: 'Model S',
    yearFrom: 2012,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Tesla',
    model: 'Model X',
    yearFrom: 2016,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Tesla',
    model: 'Model Y',
    yearFrom: 2020,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Toyota',
    model: 'Auris',
    yearFrom: 2016,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Toyota',
    model: 'bZ4X',
    yearFrom: 2022,
    type: 'BEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Toyota',
    model: 'Prius Plug-in',
    yearFrom: 2018,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Toyota',
    model: 'RAV4 PHEV',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'BETA ELIGIBILITY',
  },
  {
    make: 'Toyota',
    model: 'Corolla HB/TS',
    yearFrom: 2019,
    type: 'MHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Toyota',
    model: 'Proace City EV',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Toyota',
    model: 'Proace City Verso EV',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Toyota',
    model: 'Proace EV',
    yearFrom: 2020,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Toyota',
    model: 'Proace Verso EV',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Toyota',
    model: 'RAV4',
    yearFrom: 2018,
    type: 'MHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Toyota',
    model: 'Yaris',
    yearFrom: 2017,
    type: 'MHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Toyota',
    model: 'Yaris Cross',
    yearFrom: 2021,
    type: 'MHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Vauxhall',
    model: 'Combo-e LIFE',
    yearFrom: 2022,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Vauxhall',
    model: 'Corsa-e',
    yearFrom: 2020,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Vauxhall',
    model: 'Grandland X',
    yearFrom: 2020,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Vauxhall',
    model: 'Mokka-e',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Vauxhall',
    model: 'Vivaro Life',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Volkswagen',
    model: 'ID.3',
    yearFrom: 2020,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Volkswagen',
    model: 'ID.4',
    yearFrom: 2021,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Volkswagen',
    model: 'ID.5',
    yearFrom: 2022,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Volkswagen',
    model: 'ID.7',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Volkswagen',
    model: 'ID. Buzz',
    yearFrom: 2023,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Volkswagen',
    model: 'Arteon',
    yearFrom: 2018,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Volkswagen',
    model: 'e-crafter',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Volkswagen',
    model: 'e-golf',
    yearFrom: 2014,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Volkswagen',
    model: 'e-up',
    yearFrom: 2014,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Volkswagen',
    model: 'Golf',
    yearFrom: 2015,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Volkswagen',
    model: 'Multivan',
    yearFrom: 2017,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Volkswagen',
    model: 'Passat',
    yearFrom: 2015,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Volkswagen',
    model: 'Tiguan',
    yearFrom: 2016,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Volkswagen',
    model: 'Touareg',
    yearFrom: 2018,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Volkswagen',
    model: 'Transporter 7',
    yearFrom: 2022,
    type: 'PHEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Volvo',
    model: 'S60 Plug-In Hybrid',
    years: [2019, 2020, 2021, 2022],
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Volvo',
    model: 'S90 Plug-In Hybrid',
    years: [2018, 2019, 2020, 2021],
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Volvo',
    model: 'V60 Plug-In Hybrid',
    years: [2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022],
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Volvo',
    model: 'V90 Plug-In Hybrid',
    years: [2018, 2019, 2020, 2021],
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Volvo',
    model: 'XC40 Plug-In Hybrid',
    years: [2019, 2020, 2021, 2022],
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Volvo',
    model: 'XC60 Plug-In Hybrid',
    years: [2018, 2019, 2020, 2021],
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Volvo',
    model: 'XC90 Plug-In Hybrid',
    years: [2016, 2017, 2018, 2019, 2020, 2021, 2022],
    type: 'PHEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Volvo',
    model: 'C40 Fully Electric',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Volvo',
    model: 'EC40 Fully Electric',
    yearFrom: 2025,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Volvo',
    model: 'EX40 Fully Electric',
    yearFrom: 2025,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Volvo',
    model: 'XC40 Fully Electric',
    yearFrom: 2021,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Volvo',
    model: 'EX30 Fully Electric',
    yearFrom: 2024,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'Volvo',
    model: 'EX90 Fully Electric',
    yearFrom: 2024,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'XPENG',
    model: 'G3',
    yearFrom: 2024,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'XPENG',
    model: 'G3i',
    yearFrom: 2023,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'XPENG',
    model: 'G9',
    yearFrom: 2023,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'XPENG',
    model: 'P5',
    yearFrom: 2023,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'XPENG',
    model: 'P7',
    yearFrom: 2023,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
  {
    make: 'XPENG',
    model: 'P7i',
    yearFrom: 2024,
    type: 'BEV',
    status: 'INELIGIBLE',
  },
];

// Vehicles that the Volvo partnership (where they integrate via the volvo app) apply to
const volvoPartnershipVehicles: Vehicle[] = [
  {
    make: 'Volvo',
    model: 'C40 Fully Electric',
    years: [2023, 2024],
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
];

const volvoPartnershipVehiclesOct: Vehicle[] = [
  {
    make: 'Volvo',
    model: 'C40 Fully Electric',
    years: [2021, 2022, 2023, 2024],
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Volvo',
    model: 'XC40 Fully Electric',
    years: [2023, 2024],
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
];

const volvoPartnershipVehiclesNov: Vehicle[] = [
  {
    make: 'Volvo',
    model: 'C40 Fully Electric',
    years: [2021, 2022, 2023, 2024],
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Volvo',
    model: 'EC40 Fully Electric',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Volvo',
    model: 'EX40 Fully Electric',
    yearFrom: 2024,
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
  {
    make: 'Volvo',
    model: 'XC40 Fully Electric',
    years: [2020, 2021, 2022, 2023, 2024],
    type: 'BEV',
    status: 'FULLY ELIGIBLE',
  },
];

export type VolvoPartnershipFlags = {
  isPayAsYouDriveVolvoEnabled: boolean;
  isPayAsYouDriveVolvoOctEnabled: boolean;
  isPayAsYouDriveVolvoNovEnabled: boolean;
};
export const getVolvoPartnershipVehicles = (
  flags: VolvoPartnershipFlags
): Vehicle[] => {
  if (flags.isPayAsYouDriveVolvoNovEnabled) {
    return volvoPartnershipVehiclesNov;
  } else if (flags.isPayAsYouDriveVolvoOctEnabled) {
    return volvoPartnershipVehiclesOct;
  } else if (flags.isPayAsYouDriveVolvoEnabled) {
    return volvoPartnershipVehicles;
  } else {
    return [];
  }
};

const NOT_LISTED = 'not_listed';

export const makes: Make[] = [
  ...new Set(vehicles.map(({ make }) => make)),
].sort((a, z) => a.localeCompare(z));

export const makeOptions = [
  { label: 'My EV make isn’t listed', value: NOT_LISTED },
  ...makes.map((make) => ({ label: make, value: make })),
];

export const getModels = (make: Make): Vehicle[] =>
  vehicles
    .filter((vehicle) => vehicle.make === make)
    .sort((a, z) => a.model.localeCompare(z.model));

export const modelOptions = (make: Make) => [
  { label: 'My EV model isn’t listed', value: NOT_LISTED },
  ...getModels(make).map(({ model }) => ({
    value: model,
    label: model,
  })),
];

const maxSupportedYear = new Date().getFullYear() + 1;
const availableYears = Array.from(
  { length: maxSupportedYear - 2010 + 1 }, // Always start from 2010
  (_, i) => maxSupportedYear - i
);
export const yearOptions = availableYears.map((year, i, arr) => ({
  value: `${year}`,
  label: `${year}${i === arr.length - 1 ? ' or earlier' : ''}`,
}));

const eligibleStatuses = ['BETA ELIGIBILITY', 'FULLY ELIGIBLE'];

export const isVehicleInList =
  (list: Vehicle[]) =>
  (make: Make | null, model: Model | null, year: number | null) =>
    list.some(
      (item) =>
        item.make === make &&
        item.model === model &&
        year !== null &&
        (item.yearFrom
          ? item.yearFrom <= year
          : item.years?.includes(year) ?? false)
    );

export const isVehicleEligible = (
  make: Make | null,
  model: Model | null,
  year: number | null,
  flags: VolvoPartnershipFlags
): boolean => {
  if (!make || !model || !year) {
    return false;
  }
  return vehicles
    .concat(getVolvoPartnershipVehicles(flags))
    .some((vehicles) => {
      const isEligibleYear = vehicles.years?.length
        ? vehicles.years.includes(year)
        : vehicles.yearFrom
        ? vehicles.yearFrom <= year
        : false;

      return (
        vehicles.make === make &&
        vehicles.model === model &&
        isEligibleYear &&
        eligibleStatuses.includes(vehicles.status)
      );
    });
};

export const isNotListedSelected = (make: Make | Model | null) =>
  make === NOT_LISTED;

export const VEHICLE_ENQUIRY_LINK = 'https://vehicleenquiry.service.gov.uk/';

export enum PromoType {
  CAN_ADD_VOLVO_PROMO = 'canAddVolvoPromo',
  CAN_LINK_TO_VOLVO_PROMO = 'canLinkToVolvoPromo',
  CAN_LINK_TO_VW_PROMO = 'canLinkToVWPromo',
}

export const eligibleForPromo = (
  make: Make | null,
  model: Model | null,
  year: number | null,
  smartChargerSelection: SmartChargers | null,
  hasSolarPanels: boolean,
  hasInternetAccess: boolean,
  flags: VolvoPartnershipFlags
): PromoType | boolean => {
  const partnershipVehicles = getVolvoPartnershipVehicles(flags);
  if (!make || !model || !year || !smartChargerSelection) {
    return false;
  }
  if (make === 'Volvo') {
    if (
      partnershipVehicles.some((volvo) => {
        const isEligibleYear = volvo.years?.length
          ? volvo.years.includes(year)
          : volvo.yearFrom
          ? volvo.yearFrom <= year
          : false;

        return (
          volvo.make === make &&
          volvo.model === model &&
          isEligibleYear &&
          eligibleStatuses.includes(volvo.status)
        );
      })
    ) {
      if (!hasSolarPanels && hasInternetAccess) {
        return PromoType.CAN_ADD_VOLVO_PROMO;
      } else if (
        [
          SmartChargers.Indra,
          SmartChargers.Hypervolt,
          SmartChargers.Andersen,
        ].includes(smartChargerSelection) ||
        (!hasSolarPanels &&
          [SmartChargers.Ohme].includes(smartChargerSelection))
      ) {
        return PromoType.CAN_LINK_TO_VOLVO_PROMO;
      }
    }
  }
  return false;
};

export const eligibleForVWGPromo = (
  make: Make | null,
  model: Model | null,
  year: number | null
): PromoType | boolean => {
  if (!make || !model || !year) {
    return false;
  }
  if (VWGMakes.includes(make) && year >= 2024) {
    if (
      vehicles.some(
        (vw) => vw.make === make && vw.model === model && vw.type === 'BEV'
      )
    ) {
      return PromoType.CAN_LINK_TO_VW_PROMO;
    }
  }
  return false;
};
