import React from 'react';
import { OvoFoundationDonate as OvoFoundationDonateUpdated } from './VariantDonateUpdated';
import { OvoFoundationDonate as OvoFoundationDonateOriginal } from './VariantOriginal';
import { OVO_FOUNDATION_CARD_UPDATED_DESIGN } from '@/src/constants/feature-flags';
import { CatalogueBolton } from '@/src/types/Products';
import { useDecision } from '@/src/utils/optimizely';

type Props = {
  ovoFoundation: CatalogueBolton;
};

export const OvoFoundationDonate = ({ ovoFoundation }: Props) => {
  const [variant] = useDecision(OVO_FOUNDATION_CARD_UPDATED_DESIGN);

  switch (variant.variationKey) {
    case 'variant_updated':
      return <OvoFoundationDonateUpdated ovoFoundation={ovoFoundation} />;

    case 'variant_original':
    default:
      return <OvoFoundationDonateOriginal ovoFoundation={ovoFoundation} />;
  }
};
