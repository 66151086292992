import { useQuery } from '@ovotech/ui-tools';
import { apiService } from '@/src/services/api-service';
import { UsageBreakdownDataResponse } from '@/src/types/Response';
import { weeklyUsageBreakdownUrl } from '@/src/constants/endpoints';

const ONE_DAY = 1000 * 60 * 60 * 24;

interface Params {
  accountId: string;
  enabled: boolean;
  onSuccess?: (data: UsageBreakdownDataResponse) => void;
  onError?: (error: Error) => void;
}

export const useUsageBreakdownQuery = ({
  accountId,
  enabled,
  onSuccess,
  onError,
}: Params) =>
  useQuery(
    ['weeklyUsageBreakdown', accountId],
    () =>
      apiService.get<UsageBreakdownDataResponse>({
        url: weeklyUsageBreakdownUrl(accountId),
      }),
    {
      enabled: enabled && !!accountId,
      staleTime: ONE_DAY,
      onSuccess,
      onError,
    },
  );
