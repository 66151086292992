import { INTERNAL_EVENT_FLAG } from '@ovotech/energy-cx';
import {
  Card,
  CurrencyField,
  ErrorSummaryNotification,
  getErrorMessage,
  Heading1,
  Icon,
  P,
  PrimaryCTAButton,
  Stack,
} from '@ovotech/nebula';
import {
  AnalyticsClick,
  useAccountContext,
  useAnalytics,
} from '@ovotech/ui-tools';
import React, { FormEvent, useState } from 'react';
import { useTheme } from 'styled-components';
import AccountPaymentSummary from '../components/AccountPaymentSummary';
import {
  createWorldpayCardPaymentUrl,
  formatCurrencyAmountAsPence,
} from '../utils';
import { StyledIconWrapper, StyledSecureWrapper } from './PaymentAmount.styled';
import { useWorldpayCardPaymentMutation } from '@/src/api';
import { ErrorSection, StyledBreadCrumb } from '@/src/components';
import { PAYMENT_ERROR } from '@/src/constants/error-section-ids';
import {
  ROUTE_PAYMENTS,
  ROUTE_MAKE_CARD_PAYMENT_STATUS,
} from '@/src/constants/routes';

export function PaymentAmount() {
  const [amount, setAmount] = useState('');
  const [errors, setErrors] = useState<{ id: string; message: string }[]>([]);
  const { accountId } = useAccountContext();
  const { dispatch } = useAnalytics();
  const { colors } = useTheme();

  const cardPaymentMutation = useWorldpayCardPaymentMutation(
    accountId as string,
    response => {
      window.location.href = createWorldpayCardPaymentUrl(
        amount,
        response,
        ROUTE_MAKE_CARD_PAYMENT_STATUS,
      );
    },
  );

  const handleValueChange = ({
    currentTarget: { value },
  }: FormEvent<HTMLInputElement>) => {
    setAmount(value);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setErrors([]);
    const numericAmount = Number(amount);

    if (cardPaymentMutation.status === 'loading') {
      return;
    }

    if (!/^\d+(\.\d\d?)?$/.test(amount)) {
      setErrors([
        {
          id: 'amount',
          message: 'Please enter a valid amount',
        },
      ]);
      return;
    }

    if (numericAmount < 0.01 || numericAmount > 5000) {
      setErrors([
        {
          id: 'amount',
          message: 'Enter an amount between £0.01 and £5000',
        },
      ]);
      return;
    }

    cardPaymentMutation.mutate({ amount: formatCurrencyAmountAsPence(amount) });

    dispatch({
      name: 'TopUpPaymentSubmitted',
      type: 'click',
      properties: {
        paymentAmount: amount,
      },
    });
  };

  return (
    <div data-testid="payment-amount-worldpay">
      {cardPaymentMutation.status === 'error' ? (
        <ErrorSection data-testid="payment-error-section" id={PAYMENT_ERROR} />
      ) : null}
      <ErrorSummaryNotification
        data-testid="payment-error-summary"
        id="error-summary"
        errors={errors}
      />
      <Card>
        <Stack spaceBetween={[2, 4]}>
          <AnalyticsClick
            name="CardPaymentBackBreadcrumb"
            properties={{ [INTERNAL_EVENT_FLAG]: true }}
          >
            <StyledBreadCrumb
              data-testid="back-to-payments"
              href={ROUTE_PAYMENTS}
            >
              Back to payments
            </StyledBreadCrumb>
          </AnalyticsClick>
          <Heading1>Make a payment</Heading1>
          <AccountPaymentSummary displayLastDD={false} />
          <form onSubmit={handleSubmit} method="POST" noValidate>
            <CurrencyField
              data-testid="card-payment-amount"
              id="amount"
              label="Amount"
              value={amount}
              onChange={handleValueChange}
              disabled={cardPaymentMutation.status === 'loading'}
              min={0.01}
              max={5000}
              error={getErrorMessage('amount', errors)}
              characters={8}
            />
            <StyledSecureWrapper>
              <StyledIconWrapper>
                <Icon name="secure" size={20} color={colors.primary} />
              </StyledIconWrapper>
              <div>
                <P>Next step will happen on a secure checkout page</P>
              </div>
            </StyledSecureWrapper>
            <PrimaryCTAButton
              id="checkout-button"
              data-testid="make-card-payment-button"
              type="submit"
              icon
              disabled={cardPaymentMutation.status === 'loading'}
            >
              Continue to checkout
            </PrimaryCTAButton>
          </form>
        </Stack>
      </Card>
    </div>
  );
}
