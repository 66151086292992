import { P } from '@ovotech/nebula';
import React, { useEffect } from 'react';

import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import AnalyticsService from '@/src/services/analyticsService';

export const IneligibleForSmartMeter = () => {
  useEffect(() => {
    AnalyticsService.dispatch(
      ENERGY_INSIGHTS.NO_USAGE_BREAKDOWN_INELIGIBLE_FOR_SMART_METER,
    );
  }, []);

  return (
    <P data-testid="no-smart-meter-ineligible">
      Sorry it looks like you are not eligible to get a smart meter.
    </P>
  );
};
