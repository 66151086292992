import { useQuery } from 'react-query';
import { createGetSmartMeterBookabilityEndpoint } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';
import { WarrantInstall } from '../types/Response';

type QueryCacheKey = `orion-smart-meter-bookability-${string}`;

export const useSmartMeterBookabilityQuery = (
  accountId: string | null | undefined,
) =>
  useQuery<WarrantInstall>(
    `orion-smart-meter-bookability-${accountId}` as QueryCacheKey,
    () =>
      apiService.get({
        url: createGetSmartMeterBookabilityEndpoint(accountId ?? ''),
      }),
    { enabled: !!accountId },
  );
