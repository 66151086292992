import { useAccountContext } from '@ovotech/ui-tools';
import { useMutation, useQueryClient } from 'react-query';
import { createProductCancelEndpoint } from '@/src/constants/endpoints';
import { PRODUCT_IDS } from '@/src/constants/products';
import { apiService } from '@/src/services/api-service';

type QueryCacheKey = `orion-power-move-challenge-opt-out-${string}`;

export const usePowerMoveChallengeOptOutMutation = (
  onSuccess?: (response: Response) => void,
  onError?: (error: any) => void,
) => {
  const queryClient = useQueryClient();
  const { accountId } = useAccountContext();

  return useMutation(
    (versionId: string) =>
      apiService.delete<Response>({
        url: createProductCancelEndpoint(
          accountId ?? '',
          PRODUCT_IDS.powerMove,
          versionId,
        ),
      }),
    {
      mutationKey:
        `orion-power-move-challenge-opt-out-${accountId}` as QueryCacheKey,
      onSuccess: response => {
        queryClient.invalidateQueries({
          queryKey: [`orion-products-${accountId}-v2`],
        });
        queryClient.invalidateQueries({
          queryKey: ['orion-available-products'],
        });

        if (onSuccess) {
          onSuccess(response);
        }
      },
      onError: error => {
        if (onError) {
          onError(error);
        }
      },
    },
  );
};
