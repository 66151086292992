import { useAccountContext } from '@ovotech/ui-tools';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient, UseMutationResult } from 'react-query';
import { createProductActivateEndpointV2 } from '@/src/constants/endpoints';
import { apiService } from '@/src/services/api-service';
import { ProductsResponseV2 } from '@/src/types/Response';

type QueryCacheKey = `orion-power-move-challenge-signup-${string}`;

export type Body = {
  versionId: string;
};

export const usePowerMoveChallengeSignupMutation = (
  onSuccess?: (response: ProductsResponseV2) => void,
  onError?: (error: any) => void,
): UseMutationResult<ProductsResponseV2, unknown, Body> => {
  const queryClient = useQueryClient();
  const { accountId } = useAccountContext();

  return useMutation(
    (body: Body) =>
      apiService.post<ProductsResponseV2>({
        url: createProductActivateEndpointV2(accountId ?? ''),
        body: {
          source: 'MyOVO',
          products: [body],
        },
      }),
    {
      mutationKey:
        `orion-power-move-challenge-signup-${accountId}` as QueryCacheKey,
      onSuccess: response => {
        queryClient.invalidateQueries({
          queryKey: [`orion-products-${accountId}-v2`],
        });
        queryClient.invalidateQueries({
          queryKey: ['orion-available-products'],
        });

        if (onSuccess) {
          onSuccess(response);
        }
      },
      onError: (error: AxiosError) => {
        if (error?.response?.status === 409) {
          console.error(
            `Conflict received while joining power move challenge`,
            {
              accountId,
              requestBody: error.config?.data || {},
              response: error.response.data,
            },
          );
        }
        if (onError) {
          onError(error);
        }
      },
    },
  );
};
