import { AnalyticsClick, useAnalytics } from '@ovotech/ui-tools';
import isBefore from 'date-fns/is_before';
import subDays from 'date-fns/sub_days';

import React, { useMemo } from 'react';
import testIDs from './testIDs';
import NavDropdown from '@/src/components/Navigation/Links/NavDropdown';
import NavLink from '@/src/components/Navigation/Links/NavLink';
import { OVO_GREENLIGHT_NAV_ERROR } from '@/src/constants/analytics';

import { ROUTE_ENERGY_INSIGHTS } from '@/src/constants/routes';
import { useUsageBreakdownQuery } from '@/src/api';
import { useLocalStorage } from '@/src/utils/useLocalStorage';

const useDropdownLinks = ({
  accountId,
  path,
}: {
  accountId: string;
  path: string;
}) => {
  const shouldDisplayBreakdownLink = useShouldShowBreakdownLink(accountId);

  return useMemo(() => {
    const isActive = (route: string) => path.includes(route);

    const links = [
      <li key={ROUTE_ENERGY_INSIGHTS.HUB}>
        <AnalyticsClick name="Orion - Energy Insights link pressed">
          <NavLink
            path={path}
            route={ROUTE_ENERGY_INSIGHTS.HUB}
            isActiveChild={isActive(ROUTE_ENERGY_INSIGHTS.HUB)}
            label="Energy insights"
            data-testid={testIDs.hub}
          />
        </AnalyticsClick>
      </li>,

      <li key={ROUTE_ENERGY_INSIGHTS.CARBON_INTENSITY}>
        <NavLink
          path={path}
          route={ROUTE_ENERGY_INSIGHTS.CARBON_INTENSITY}
          isActiveChild={isActive(ROUTE_ENERGY_INSIGHTS.CARBON_INTENSITY)}
          label="Carbon forecast"
          data-testid={testIDs.carbonIntensity}
        />
      </li>,
    ];

    if (shouldDisplayBreakdownLink) {
      links.push(
        <li key={ROUTE_ENERGY_INSIGHTS.BILL_BREAKDOWN}>
          <NavLink
            path={path}
            route={ROUTE_ENERGY_INSIGHTS.BILL_BREAKDOWN}
            isActiveChild={isActive(ROUTE_ENERGY_INSIGHTS.BILL_BREAKDOWN)}
            label="Energy use"
            data-testid={testIDs.billBreakdown}
          />
        </li>,
      );
    }

    return links;
  }, [path, shouldDisplayBreakdownLink]);
};

type Props = {
  type: 'accordion' | 'dropdown';
  accountId: string;
  path: string;
};

const OvoGreenlightLink = ({ accountId, type, path }: Props) => (
  <AnalyticsClick name="Orion - Energy Insights dropdown pressed">
    <NavDropdown
      label="Energy insights"
      listItems={useDropdownLinks({ accountId, path })}
      accordion={type === 'accordion'}
    />
  </AnalyticsClick>
);

function useShouldShowBreakdownLink(accountId: string) {
  const analytics = useAnalytics();

  const [cachedStatus, updateCachedStatus] = useLocalStorage(
    `greenlight_breakdown_check_${accountId}`,
    {
      dateChecked: 0,
      hasBreakdown: false,
    },
  );

  let cacheIsStale = false;

  try {
    cacheIsStale =
      !cachedStatus.hasBreakdown && // once a user has a breakdown, we don't ever need to check again
      isBefore(cachedStatus.dateChecked, subDays(new Date(), 7));
  } catch (error) {
    analytics.dispatch({
      name: OVO_GREENLIGHT_NAV_ERROR,
      type: 'render',
      properties: { error },
    });
    // since we can't add types to the local storage value, this is just a failsafe in case
    // something unexpected happens
    cacheIsStale = true;
  }

  useUsageBreakdownQuery({
    accountId,
    enabled: cacheIsStale,
    onSuccess(data) {
      updateCachedStatus({
        hasBreakdown: !!data.breakdownWeeks.length,
        dateChecked: Date.now(),
      });
    },
    onError() {
      if (cacheIsStale) {
        updateCachedStatus({
          hasBreakdown: false,
          dateChecked: Date.now(),
        });
      }
    },
  });

  // another slightly paranoid failsafe since we can't use types
  try {
    return cachedStatus.hasBreakdown;
  } catch (error) {
    analytics.dispatch({
      name: OVO_GREENLIGHT_NAV_ERROR,
      type: 'render',
      properties: { error },
    });
    return false;
  }
}

export default OvoGreenlightLink;
