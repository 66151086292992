import { P, TextLink } from '@ovotech/nebula';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom-v5';
import { IneligibleForSmartMeter } from './IneligibleForSmartMeter';
import {
  StyledNoUsageBreakdown,
  NoUsageBreakdownBG,
  NoUsageBreakdownContainerModal,
  NoUsageModalBody,
} from './NoUsageBreakdown.styled';
import { SmbNotAvailable } from './SmbNotAvailable';
import { UnknownSmbNotAvailableReason } from './UnknownSmbNotAvailableReason';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import { ROUTE_ENERGY_INSIGHTS } from '@/src/constants/routes';
import { CardContent } from '@/src/pages/EOC1Compliance/components/Card.styled';
import { Fuel } from '@/src/types/Response';
import { BreakdownUnavailableReason } from '@/src/pages/EnergyInsights/types/bill-breakdown.types';
import { ReactComponent as usageBreakdownBackground } from '@/src/resources/usageBreakdown.svg';
import AnalyticsService from '@/src/services/analyticsService';

type Props = {
  fuel: Fuel;
  reason: BreakdownUnavailableReason;
};

export const NoUsageBreakdown = (props: Props) => (
  <StyledNoUsageBreakdown>
    <NoUsageBreakdownBG
      // @ts-expect-error svg background
      src={usageBreakdownBackground}
      alt=""
      data-testid="no-usage-breakdown-background"
    />
    <NoUsageBreakdownModal {...props} />
  </StyledNoUsageBreakdown>
);

function NoUsageBreakdownModal({ fuel, reason }: Props) {
  useEffect(() => {
    AnalyticsService.dispatch(ENERGY_INSIGHTS.NO_USAGE_BREAKDOWN_VIEW, {
      reason,
    });
  }, [reason]);

  const copyFor: Record<BreakdownUnavailableReason, JSX.Element | string> = {
    noSmartMeter: <SmbNotAvailable />,
    ineligibleForSmartMeter: <IneligibleForSmartMeter />,
    smbNotAvailable: <UnknownSmbNotAvailableReason />,
    noThirtyDays:
      'We need to collect one weeks’s worth of energy use data from your smart meter before we can create your Breakdown.\n\nPlease check back soon.',
    infrequentReads: `It looks like your smart meter isn’t sending us frequent enough readings. So we can’t bring you awesome insights into your ${fuel.toLowerCase()} use.\n\nJust email us at hello@ovoenergy.com to update your readings to half-hourly.`,
    noBreakdown: (
      <P data-testid="no-usage-breakdown">
        We can’t show your energy breakdown right now. Please check back later.
        {'\n\n'}
        If you have a smart meter, it needs to be sending half-hourly readings
        so you can see where your home is using the most energy.{' '}
        <TextLink to={ROUTE_ENERGY_INSIGHTS.HUB} as={Link}>
          Switch to half-hourly now
        </TextLink>
        , if you’ve not already done so.
      </P>
    ),
  };

  return (
    <NoUsageBreakdownContainerModal data-testid="no-usage-breakdown-modal">
      <CardContent data-testid={`no-usage-breakdown-modal-${reason}`}>
        <NoUsageModalBody>{copyFor[reason]}</NoUsageModalBody>
      </CardContent>
    </NoUsageBreakdownContainerModal>
  );
}
