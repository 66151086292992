import { Card, Heading4, P, Margin, PrimaryCTALink } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React from 'react';
import { Link } from 'react-router-dom-v5';
import {
  StyledContainer,
  StyledHeading4,
} from './EVInlifeSmartChargerDisconnectConfirmation.styled';
import { EVS_INLIFE_DISCONNECT_CONFIRMED } from '@/src/constants/analytics-subjects';
import { ROUTE_EV } from '@/src/constants/routes';
import { ReactComponent as LossComplete } from '@/src/resources/loss-complete-icon.svg';

export const EVInlifeSmartChargerDisconnectConfirmation = () => {
  return (
    <Card data-testid="disconnect-confirmation">
      <StyledContainer>
        <LossComplete />
        <StyledHeading4 as={Heading4}>All sorted!</StyledHeading4>
        <P>
          Smart charging insights will no longer appear in your OVO account.
        </P>
        <Margin top={3}>
          <AnalyticsClick name={EVS_INLIFE_DISCONNECT_CONFIRMED}>
            <PrimaryCTALink
              data-testid="ev-inlife-close"
              iconRight="off"
              as={Link}
              to={ROUTE_EV}
            >
              Close
            </PrimaryCTALink>
          </AnalyticsClick>
        </Margin>
      </StyledContainer>
    </Card>
  );
};
