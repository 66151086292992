import { P } from '@ovotech/nebula';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import CompareBreakdownToggle from '../../../CompareBreakdownToggle/CompareBreakdownToggle';
import TogglePrice from '../../../TogglePrice/TogglePrice';
import { UsageBreakdownSectionContent } from '../../../UsageBreakdownBar/UsageBreakdownBar.styled';
import UsageBreakdownContent from '../../../UsageBreakdownContent/UsageBreakdownContent';
import UsageCollapsibleHeader from '../../../UsageCollapsibleHeader/UsageCollapsibleHeader';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import { Fuel } from '@/src/types/Response';
import { mqSmallDown } from '@/src/utils/mediaQuery';

import {
  EnergySavingTips,
  CollapsibleGroup,
  CollapsibleSection,
  CollapsibleHeader,
  CollapsiblePanel,
} from '@/src/pages/EnergyInsights/components';
import {
  electricUsageBreakdownInfoMap,
  gasUsageBreakdownInfoMap,
} from '@/src/pages/EnergyInsights/constants/usage';
import { useGetTipInteractionsFromLocalStorage } from '@/src/pages/EnergyInsights/hooks/useGetTipInteractionsFromLocalStorage';
import { GasUsageCategory } from '@/src/pages/EnergyInsights/types';
import { BreakdownWeek } from '@/src/pages/EnergyInsights/types/bill-breakdown.types';
import {
  mapCategoriesToActions,
  shuffle,
} from '@/src/pages/EnergyInsights/utils/spotlights';
import { TipContent } from '@/src/pages/EnergyInsights/utils/tips';
import AnalyticsService from '@/src/services/analyticsService';
interface Props {
  breakdownWeek: BreakdownWeek;
  lastWeekBreakdown?: BreakdownWeek;
  fuel: Fuel;
}

const FlexBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  ${({ theme: { space } }) => `
    margin-left: ${space[8]}
  `};
`;

const Disclaimer = styled(P)`
  width: 100%;
  font-size: 16px;
  height: 50px;

  ${mqSmallDown(`
    height: auto;
    font-size: 14px;
  `)}
`;

const FuelBreakdownContent = ({
  breakdownWeek,
  fuel,
  lastWeekBreakdown,
}: Props) => {
  const [showLastWeek, setShowLastWeek] = useState(false);
  const [showPrice, setShowPrice] = useState(true);

  const topCategoriesTips = useTopCategoriesTips(breakdownWeek, fuel);
  const tipInteractions = useGetTipInteractionsFromLocalStorage();

  useEffect(() => {
    if (!lastWeekBreakdown) {
      setShowLastWeek(false);
    }
  }, [lastWeekBreakdown]);

  const showCostToggle = !!breakdownWeek[fuel]?.categoryBreakdown?.[0]?.cost;

  return (
    <>
      <CollapsibleGroup>
        <FlexBar>
          {showCostToggle ? (
            <TogglePrice
              showPrice={showPrice}
              setShowPrice={setShowPrice}
              lastWeekHasCost={
                !!lastWeekBreakdown?.[fuel]?.categoryBreakdown?.[0]?.cost
              }
              showLastWeek={showLastWeek}
            />
          ) : null}

          {lastWeekBreakdown ? (
            <CompareBreakdownToggle
              fuel={fuel}
              toggleLastWeek={setShowLastWeek}
              showLastWeek={showLastWeek}
              showingCostToggle={showCostToggle}
            />
          ) : null}
          <Disclaimer>
            {showCostToggle && showPrice
              ? '* All categories in breakdown are estimated, excludes standing charge, VAT and any other cost. \n(This information is not used to calculate your bill.)'
              : '* All categories in breakdown are estimated.'}
          </Disclaimer>
        </FlexBar>

        {breakdownWeek[fuel]?.categoryBreakdown?.map((usageCategory, i) => {
          const breakdownInfo =
            fuel === Fuel.ELECTRICITY
              ? electricUsageBreakdownInfoMap[usageCategory.name]
              : gasUsageBreakdownInfoMap[
                  usageCategory.name as GasUsageCategory
                ];

          const lastWeekBreakdownCorrectCategory = lastWeekBreakdown
            ? lastWeekBreakdown[fuel]?.categoryBreakdown?.find(
                cat => cat.name === usageCategory.name,
              )
            : null;

          return (
            <UsageBreakdownSectionContent
              key={i}
              data-testid={`${usageCategory.name}-section`}
            >
              <CollapsibleSection
                onToggle={collapsed => {
                  if (collapsed) {
                    AnalyticsService.dispatch(
                      ENERGY_INSIGHTS.spotlightsExpandedCategory(
                        usageCategory.name,
                      ),
                    );
                  }
                }}
              >
                <CollapsibleHeader
                  headerContent={
                    <UsageCollapsibleHeader
                      showLastWeek={showLastWeek}
                      maxConsumption={breakdownWeek[fuel]?.maxUsageInLast2Weeks}
                      currentWeekConsumption={usageCategory.usage}
                      currentWeekPrice={usageCategory.cost}
                      fuel={fuel}
                      breakdownInfo={breakdownInfo}
                      lastWeekConsumption={
                        lastWeekBreakdownCorrectCategory?.usage ?? 0
                      }
                      lastWeekPrice={
                        lastWeekBreakdownCorrectCategory?.cost ?? 0
                      }
                      showPrice={showPrice}
                    />
                  }
                />
                <CollapsiblePanel>
                  <UsageBreakdownContent
                    category={usageCategory.name}
                    consumption={usageCategory.usage}
                    fuel={fuel}
                    breakdownInfo={breakdownInfo}
                    tipInteractions={tipInteractions}
                  />
                </CollapsiblePanel>
              </CollapsibleSection>
            </UsageBreakdownSectionContent>
          );
        })}
        <EnergySavingTips
          tips={topCategoriesTips}
          category="randomTipsAllCategories"
          testId="breakdown-toptips"
          tipInteractions={tipInteractions}
        />
      </CollapsibleGroup>
    </>
  );
};

export default FuelBreakdownContent;

function useTopCategoriesTips(breakdownWeek: BreakdownWeek, fuel: Fuel) {
  const [topCategoriesTips, setTopCategoriesTips] = useState<TipContent[]>([]);
  const topCats = breakdownWeek?.[fuel]?.topCategories;

  useEffect(() => {
    const shuffledTips = !topCats
      ? []
      : shuffle(mapCategoriesToActions(topCats, fuel)).slice(0, 6);

    setTopCategoriesTips(shuffledTips);
  }, [breakdownWeek, topCats, fuel]);

  return topCategoriesTips;
}
