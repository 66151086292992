import { Heading4 } from '@ovotech/nebula';
import { isSameDay, parse } from 'date-fns';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import UsageBarAndFigure from '../../../Hub/Components/BreakdownWidget/Components/UsageBarAndFigure';
import { StyledFlexcard, StyledContainer } from './index.styled';
import { BreakdownScreenResponse, Fuel } from '@/src/types/Response';
import { setBarWidth } from '@/src/pages/EnergyInsights/utils/usage';

type Props = {
  fromDate: Date;
  fuel: Fuel;
  similarHomes: BreakdownScreenResponse['similarHomes'];
};

export const SimilarHomes = ({ fuel, fromDate, similarHomes }: Props) => {
  const theme = useContext(ThemeContext);
  const fuelKey = toFuelKey(fuel);

  const weekDataForSelectedFuel = similarHomes?.find(week =>
    isSameDay(parse(week.fromDate), fromDate),
  )?.[fuelKey];

  const [yourHomeBar, similarHomesBar] = weekDataForSelectedFuel?.bars ?? [];

  if (
    !weekDataForSelectedFuel ||
    !yourHomeBar?.usage ||
    !similarHomesBar?.usage
  ) {
    return null;
  }

  const barWidths = setBarWidth(yourHomeBar.usage, similarHomesBar.usage);

  return (
    <StyledFlexcard data-testid="similar-homes">
      <Heading4>{weekDataForSelectedFuel.heading}</Heading4>

      <StyledContainer>
        <UsageBarAndFigure
          totalUsage={yourHomeBar.usage}
          barWidth={barWidths.yourBarWidth}
          barColour={theme.colors.brand.primary.lighter}
          barText={yourHomeBar.title}
          testId="usageBarAndFigure-yourHome"
        />
        <UsageBarAndFigure
          totalUsage={similarHomesBar.usage}
          barWidth={barWidths.averageBarWidth}
          barColour={theme.colors.brand.grey.base}
          barText={similarHomesBar.title}
          testId="usageBarAndFigure-similarHome"
        />
      </StyledContainer>
    </StyledFlexcard>
  );
};

type FuelKey = 'electricity' | 'gas';
function toFuelKey(f: Fuel): FuelKey {
  return f.toLocaleLowerCase() as FuelKey;
}
