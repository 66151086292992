import fridgeIcon from '@/src/resources/fridge-icon.svg';
import laptopIcon from '@/src/resources/laptop-icon.svg';
import plugAndSocketIcon from '@/src/resources/plug-and-socket-icon.svg';
import washingMachineIcon from '@/src/resources/washing-machine-icon.svg';

export const powerTipsContent = [
  {
    key: 1,
    imgSrc: washingMachineIcon,
    heading: 'Time your washing machine',
    body: 'Set your washing machine or dishwasher to start when your morning alarm goes off.',
  },
  {
    key: 2,
    imgSrc: fridgeIcon,
    heading: 'Prepare meals ahead of time',
    body: 'Cook meals outside of peak-times to avoid switching on the oven or any other electricity guzzlers.',
  },
  {
    key: 3,
    imgSrc: laptopIcon,
    heading: 'Power up tech in advance ',
    body: 'Charge your laptop (or even your electric car) at off peak times like the early morning or late evening.',
  },
  {
    key: 4,
    imgSrc: plugAndSocketIcon,
    heading: 'Switch off devices',
    body: 'Try turning off any devices at the wall instead of leaving them on standby.',
  },
];
