import { CTAButton, Heading3, Margin, P } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React from 'react';
import { ButtonWrapper } from '../HomeProfileForm/HomeProfileForm.styled';
import { HomeProfileFormWizardStep } from '../HomeProfileForm/HomeProfileFormWizardStep';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';

type Props = {
  onClickCTA: VoidFunction;
};

export const HomeProfileOutro = ({ onClickCTA }: Props) => (
  <HomeProfileFormWizardStep visible>
    <Heading3>Thank you for telling us about your home</Heading3>
    <Margin bottom={12} top={12}>
      <P>
        The information you’ve shared with us will help improve your
        personalised insights. It’ll take 24 hours for these insights to update
        so it’s worth checking back tomorrow.
      </P>
    </Margin>
    <ButtonWrapper>
      <AnalyticsClick
        name={ENERGY_INSIGHTS.BUTTON_CLICK_HOME_PROFILE_SEE_ENERGY_SPOTLIGHTS}
      >
        <CTAButton
          type="button"
          data-testid="SeeSpotlightsCloseModal"
          onClick={onClickCTA}
        >
          Finish
        </CTAButton>
      </AnalyticsClick>
    </ButtonWrapper>
  </HomeProfileFormWizardStep>
);
