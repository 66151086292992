import { UsageView } from '@ovotech/energy-cx';
import { Stack } from '@ovotech/nebula';
import React from 'react';
import { HomeProfileWidget } from './components/HomeProfileWidget';
import { PageWithNavigationAndSectionPadding } from '@/src/components';
import { useHomeProfileWidget } from './components/HomeProfileWidget/useHomeProfileWidget';

export const UsagePageView = () => {
  const { widgetPosition } = useHomeProfileWidget();
  return (
    <PageWithNavigationAndSectionPadding title="Your energy use">
      <Stack spaceBetween={4}>
        {/* We add these position checks to ensure the Stack doesn't display empty spaces if the component returns null */}
        {widgetPosition === 'top' && <HomeProfileWidget pagePosition="top" />}
        <UsageView />
        {widgetPosition === 'bottom' && (
          <HomeProfileWidget pagePosition="bottom" />
        )}
      </Stack>
    </PageWithNavigationAndSectionPadding>
  );
};
