import { Heading3, SecondaryCTALink, P, Strong } from '@ovotech/nebula';
import { useAccountContext } from '@ovotech/ui-tools';
import React, { PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';
import { BulletWithIcon } from '@/src/components';
import {
  ActionCentreBanner,
  Banner,
} from '@/src/components/ActionCentre/Banners/Banner';
import { SMART_BOOKING_URL } from '@/src/constants/endpoints';

const Bullet: React.FC<PropsWithChildren> = ({ children, ...rest }) => {
  const theme = useTheme();
  return (
    <BulletWithIcon
      {...rest}
      iconColor={theme.colors.brand.primary.base}
      iconName="check"
      iconThemeSize={2}
    >
      {children}
    </BulletWithIcon>
  );
};

export const SmartMeterBookingMandate: ActionCentreBanner = ({
  dismiss,
  onClick,
}) => {
  const { accountId } = useAccountContext();

  return (
    <Banner icon="smartMeter" dismiss={dismiss} data-testid="smb-renter">
      <Heading3 as="h2">Don't wait to get a smart meter</Heading3>
      <P>
        {' '}
        <Strong> Remember:</Strong> you need a smart meter to stay on your
        Better Smart plan. <br /> Why not upgrade now?{' '}
      </P>
      <Bullet>It takes just minutes to book</Bullet>
      <Bullet>Appointments to suit you</Bullet>
      <Bullet>A smart meter means more accurate bills</Bullet>
      <SecondaryCTALink
        href={`${SMART_BOOKING_URL}?accountId=${accountId}&utm_source=banner&utm_medium=MyOVO&utm_campaign=smart-meter-booking`}
        data-testid="sm-booking-link"
        onClick={onClick}
      >
        Book my free appointment
      </SecondaryCTALink>
    </Banner>
  );
};
