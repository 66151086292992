import { Card } from '@ovotech/nebula';
import React from 'react';
import { useHomeProfilePromptPosition } from '../../hooks/useHomeProfilePromptPosition';
import { GridContainer } from '../GridContainer';
import { HomeProfileProps, HomeProfilePrompt } from './HomeProfilePrompt';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';

import AnalyticsService from '@/src/services/analyticsService';

export const HomeProfilePromptHubContainer = ({
  homeProfile,
}: Omit<HomeProfileProps, 'position'>) => {
  const { homeProfilePromptPosition, sendHomeProfilePromptToBottom } =
    useHomeProfilePromptPosition(homeProfile);

  return (
    <GridContainer>
      <Card>
        <HomeProfilePrompt
          position={homeProfilePromptPosition}
          homeProfile={homeProfile}
          updateHomeProfileOnClick={() => {
            AnalyticsService.dispatch(
              ENERGY_INSIGHTS.BUTTON_CLICK_UPDATE_HOME_PROFILE_HUB,
            );
          }}
          dismissHomeProfileOnClick={() => {
            try {
              AnalyticsService.dispatch(
                ENERGY_INSIGHTS.HOME_PROFILE_PROMPT_DISMISSED_HUB,
              );
              sendHomeProfilePromptToBottom();
            } catch (e) {
              console.log(e);
            }
          }}
        />
      </Card>
    </GridContainer>
  );
};
