import { useQuery } from 'react-query';
import { carbonZeroBffEndpoints } from '@/src/constants/endpoints';
import { HomeProfileWidgetResponse } from '@/src/types/Response';
import { useFeature } from '@/src/utils/optimizely';
import { apiService } from '@/src/services/api-service';

interface Params {
  accountId: string | null | undefined;
  enabled: boolean;
}

export const getHomeProfileWidgetQueryKey = (accountId?: string | null) =>
  `home-profile-widget-${accountId}`;

export function useHomeProfileWidgetQuery({
  accountId,
  enabled = true,
}: Params) {
  const [flagEnabled] = useFeature('home_profile_widget');

  return useQuery(
    getHomeProfileWidgetQueryKey(accountId),
    () =>
      !accountId
        ? null
        : apiService.get<HomeProfileWidgetResponse>({
            responseType: 'json',
            url: carbonZeroBffEndpoints.homeProfileWidget(accountId),
          }),
    { enabled: flagEnabled && enabled && !!accountId },
  );
}
