export type ElectricityUsageCategory =
  | 'alwaysOn'
  | 'lighting'
  | 'other'
  | 'cooking'
  | 'entertainment'
  | 'refrigeration'
  | 'washing'
  | 'waterHeating'
  | 'heating'
  | 'ev';

export type GasUsageCategory = 'waterHeating' | 'cooking' | 'heating';

export type AnyUsageCategory = ElectricityUsageCategory | GasUsageCategory;

export enum EnergyInsightsPageName {
  Hub = 'Energy Insights - Hub',
  BillBreakdown = 'Energy Insights - Bill Breakdown',
  CarbonIntensity = 'Energy Insights - Carbon Intensity',
  Terms = 'Energy Insights - Terms',
}
