import { Modal } from '@ovotech/nebula';

import React, { useState } from 'react';
import { HomeProfileData } from '../../types';
import { HomeProfileForm } from './HomeProfileForm/HomeProfileForm';
import { HomeProfileIntro } from './HomeProfileIntro/HomeProfileIntro';
import { HomeProfileOutro } from './HomeProfileOutro/HomeProfileOutro';

enum JOURNEY_STATE {
  INTRO = 'intro',
  FORM = 'form',
  OUTRO = 'outro',
}

type Props = {
  homeProfile: HomeProfileData | null;
  onClose: () => void;
};

export const HomeProfileModal = ({ homeProfile, onClose }: Props) => {
  const [journeyState, setJourneyState] = useState(JOURNEY_STATE.INTRO);

  return (
    <Modal title="" isOpen onClose={onClose}>
      {journeyState === JOURNEY_STATE.INTRO ? (
        <HomeProfileIntro
          onClickCTA={() => setJourneyState(JOURNEY_STATE.FORM)}
        />
      ) : journeyState === JOURNEY_STATE.FORM ? (
        <HomeProfileForm
          existingHomeProfileData={homeProfile}
          goBackToIntroScreen={() => setJourneyState(JOURNEY_STATE.INTRO)}
          goToOutroScreen={() => setJourneyState(JOURNEY_STATE.OUTRO)}
        />
      ) : (
        <HomeProfileOutro onClickCTA={onClose} />
      )}
    </Modal>
  );
};
