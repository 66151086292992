import { P, Card, Badge, Heading2, TextLink, DataTable } from '@ovotech/nebula';
import styled, { css } from 'styled-components';

export const Title = styled(P)`
  font-size: 1.2em;
  font-weight: 700;
`;

export const StyledCard = styled(Card)`
  padding: 0;
  overflow: hidden;
  background-color: transparent;
`;

export const StyledChallengeInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme: { mediaQueries } }) => `
    ${mediaQueries.mediumAndUp} {
      flex-direction: row;
    }
  `};
`;

export const PrescriptionSection = styled.div`
  flex: 1;
  padding: 12px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #006945;

  ${({ theme: { mediaQueries } }) => `
  
    ${mediaQueries.mediumAndUp} {
      padding: 40px 12px;
    }

    &::before {
      content: '';
      width: 0px;
      height: 0px;
      position: absolute;
      border: 0.8em solid transparent;
      left: calc(50% - 13px);
      bottom: -19px;
      border-width: 20px 12px 0 12px;
      border-color: #006945 transparent transparent transparent;
      
      ${mediaQueries.mediumAndUp} {
        top: calc(50% - 17px);
        left: auto;
        bottom: auto;
        right: -19px;
        border-width: 17px 0 17px 20px;
        border-color: transparent transparent transparent #006945;
      }
    }
  `};
`;

export const IconWrapper = styled.div`
  display: flex;
  ${({ theme: { space } }) => `
    margin: ${space[3]} ${space[6]};
  `}
`;

export const StyledBadge = styled(Badge)<{ status: 'isJoined' }>`
  font-size: 12px;
  padding: 1px 8px;
  line-height: 18px;
  position: absolute;
  left: 12px;
  top: 12px;

  ${({ theme: { colors } }) => {
    const color = colors.brand.mission.base;

    return css`
      border-color: ${color};
      background-color: ${color};
    `;
  }};
`;

export const PrescriptionH2 = styled(Heading2)`
  color: white;
  text-align: center;

  ${({ theme: { mediaQueries } }) => `
    font-size: 24px;
    line-height: 32px;
  
    ${mediaQueries.mediumAndUp} {
        font-size: 32px;
        line-height: 40px;
      };
  `};
`;

export const PrescriptionP = styled(P)`
  color: white;
  text-align: center;

  ${({ theme: { mediaQueries } }) => `
    font-size: 20px;
    line-height: 24px;
    
    ${mediaQueries.mediumAndUp} {
      font-size: 24px;
      line-height: 28px;
    `};
`;

export const PrescriptionChallengePeriod = styled(P)`
  color: white;
  text-align: center;

  font-size: 14px;
  line-height: 20px;
`;

export const TargetRewardBlockTitle = styled(P)`
  margin-bottom: 0;
  ${({ theme: { fontWeights } }) => `
    font-weight: ${fontWeights.bold};
  `}
`;

export const TargetRewardSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  ${({ theme: { mediaQueries, colors } }) => `
    padding: 24px 12px 12px 12px;
    background-color: ${colors.brand.white};
    
    ${mediaQueries.mediumAndUp} {
      justify-content: center;
      padding: 48px 73px 24px 44px;
    `};
`;

export const AdditionInfo = styled(P)`
  margin-top: 0;
  ${({ theme: { colors } }) => `
    color: ${colors.brand.grey.dark};
  `}
`;

export const StyledTextLink = styled(TextLink)`
  cursor: pointer;
  margin-top: 12px;
`;

export const StyledChallengePPContainer = styled.div`
  padding: 12px;
  background-color: white;

  ${({ theme: { mediaQueries } }) => `
    ${mediaQueries.mediumAndUp} {
      padding: 24px;
    }
  `};
`;

export const StyledDataTable = styled(DataTable)`
  ${({ theme: { colors } }) => `
    & > thead > tr > th {
      background-color: ${colors.backgroundGlobal};
    }
    & > tbody > tr:nth-child(odd) {
      background-color: ${colors.borderMuted};
    }
    & > tbody > tr:nth-child(even) {
      background-color: ${colors.backgroundGlobal};
    }
    & > tbody > tr:nth-child(1) > td {
      font-weight: bold;
    }
  `};
`;
