import {
  Card,
  Col,
  Heading3,
  Heading4,
  P,
  Row,
  SecondaryCTALink,
} from '@ovotech/nebula';
import React, { ReactNode } from 'react';

import {
  StyledFeatureImageWrapper,
  IconWrapper,
  Heading4NoMargin,
  StyledInfoWrapper,
  StyledFeatureRow,
  StyledFeatureCol,
  StyledCard,
  GreyCard,
  PTextCenter,
} from './FeaturesCard.styled';
import { TermsAndConditionsFootnote } from './footnotes';
import { TADO_COMPATIBILITY_GUIDE } from '@/src/constants/routes';
import { ReactComponent as DirectDebitIcon } from '@/src/resources/direct-debit-date-icon.svg';
import { ReactComponent as DoorIcon } from '@/src/resources/door-icon.svg';
import HomeImage from '@/src/resources/home-image.png';
import { ReactComponent as LeafIcon } from '@/src/resources/leaf_icon.svg';
import WindowImage from '@/src/resources/open-window.png';
import SmartScheduleImage from '@/src/resources/smart-schedule.png';
import { ReactComponent as SunIcon } from '@/src/resources/sun-icon.svg';
import WeatherImage from '@/src/resources/weather-adapt.png';
import { SMART_THERMOSTATS } from '@/src/constants/analytics';
import { AnalyticsClick } from '@ovotech/ui-tools';

const StyledInfo = ({
  icon,
  title,
  description,
}: {
  icon: ReactNode;
  title: string;
  description: ReactNode;
}) => {
  return (
    <StyledInfoWrapper>
      <IconWrapper>{icon}</IconWrapper>
      <Heading4NoMargin as={Heading4}>{title}</Heading4NoMargin>
      <P>{description}</P>
    </StyledInfoWrapper>
  );
};

const FeatureInfo = ({
  icon,
  title,
  description,
  isImageFirst,
  imgSrc,
}: {
  icon: ReactNode;
  title: string;
  description: ReactNode;
  isImageFirst: boolean;
  imgSrc: string;
}) => {
  return (
    <>
      <StyledFeatureRow as={Row} isNested isImageFirst={isImageFirst}>
        {isImageFirst && (
          <>
            <StyledFeatureCol as={Col} medium={6} isImageFirst={isImageFirst}>
              <StyledFeatureImageWrapper src={imgSrc} />
            </StyledFeatureCol>
            <Col medium={6}>
              <StyledInfo icon={icon} title={title} description={description} />
            </Col>
          </>
        )}
        {!isImageFirst && (
          <>
            <Col medium={6}>
              <StyledInfo icon={icon} title={title} description={description} />
            </Col>
            <StyledFeatureCol as={Col} medium={6} isImageFirst={isImageFirst}>
              <StyledFeatureImageWrapper src={imgSrc} />
            </StyledFeatureCol>
          </>
        )}
      </StyledFeatureRow>
    </>
  );
};

export const FeaturesCard = () => {
  return (
    <StyledCard as={Card}>
      <FeatureInfo
        icon={<DirectDebitIcon aria-hidden />}
        title="Fit your heating around your routine"
        description="Smart Schedule lets you plan your temperature by the hour. So your heating is always just right."
        isImageFirst={false}
        imgSrc={SmartScheduleImage}
      />
      <FeatureInfo
        icon={<SunIcon aria-hidden />}
        title="Works with the weather"
        description="tado° adapts to your local weather forecast. So on a sunny day, tado° turns down the heating to save energy."
        isImageFirst={true}
        imgSrc={WeatherImage}
      />
      <FeatureInfo
        icon={<LeafIcon aria-hidden />}
        title="Keep that lovely heat in"
        description="tado° detects open windows and reminds you to turn down your heating to save energy."
        isImageFirst={false}
        imgSrc={WindowImage}
      />
      <FeatureInfo
        icon={<DoorIcon aria-hidden />}
        title="Never heat an empty home"
        description="With Geofencing, tado° reminds you to turn down the heating when you leave the house, and to turn it up before you get home."
        isImageFirst={true}
        imgSrc={HomeImage}
      />
      <GreyCard as={Col}>
        <Heading3>Will tadoº work in my home?</Heading3>
        <PTextCenter as={P}>
          tado° works with most boilers including combination and system boilers
          (with separate hot water tanks)
          <TermsAndConditionsFootnote />. Take a look to see if your home is
          compatible.
        </PTextCenter>
        <AnalyticsClick
          name={
            SMART_THERMOSTATS.MY_THERMOSTATS_PAGE.PRODUCT_PAGE.CLICKED
              .COMPATIBILITY_GUIDE
          }
        >
          <SecondaryCTALink href={TADO_COMPATIBILITY_GUIDE} target="_blank">
            Compatibility guide
          </SecondaryCTALink>
        </AnalyticsClick>
      </GreyCard>
    </StyledCard>
  );
};
