const SLUG_DD = '/direct-debit';
const SLUG_MAKE_CARD_PAYMENT = '/make-card-payment';
const SLUG_REFUNDS = '/refunds';

export const ROUTE_HOME = '/';
export const ROUTE_PROFILE = '/profile'; /* Configured as Universal Link */
export const ROUTE_EDIT_PROFILE_LEGACY = '/profile/edit';
export const ROUTE_EDIT_PROFILE_EMAIL = '/profile/edit-email';
export const ROUTE_EDIT_PROFILE_PHONE = '/profile/edit-phone';
export const ROUTE_EDIT_PROFILE_MARKETING = '/profile/edit-marketing-details';
export const ROUTE_EDIT_METER_READ_FREQUENCY = '/profile/meter-read-frequency';
export const ROUTE_OUR_PARTNERS = '/profile/partners';
export const ROUTE_PARTNERS_TESCO = '/profile/partners/tesco';
export const ROUTE_USAGE = '/usage';
export const ROUTE_USAGE_YEARLY = `${ROUTE_USAGE}/yearly`;
export const ROUTE_USAGE_MONTHLY = `${ROUTE_USAGE}/monthly`;
export const ROUTE_USAGE_DAILY = `${ROUTE_USAGE}/daily`;
export const ROUTE_HELP = '/help';
export const ROUTE_PAYMENTS = '/payments';
export const OLD_ROUTE_CHANGE_DD_AMOUNT = `${ROUTE_PAYMENTS}/edit-direct-debit`;
export const OLD_ROUTE_CHANGE_DD_DATE = `${ROUTE_PAYMENTS}/change-direct-debit-date`;
export const ROUTE_CHANGE_DD_AMOUNT = `${ROUTE_PAYMENTS}${SLUG_DD}/change-amount`;
export const ROUTE_CHANGE_DD_AMOUNT_CONFIRM = `${ROUTE_CHANGE_DD_AMOUNT}/confirm`;
export const ROUTE_ADJUST_DD_AMOUNT_CALCULATION = `${ROUTE_PAYMENTS}${SLUG_DD}/change-ddamount`;
export const ROUTE_ONE_CLICK_PAYMENT_REDUCTION_CONFIRMATION = `${ROUTE_PAYMENTS}${SLUG_DD}/payment-reduction/confirm`;
export const ROUTE_CHANGE_DD_DATE = `${ROUTE_PAYMENTS}${SLUG_DD}/change-date`;
export const ROUTE_CHANGE_DD_DATE_CONFIRM = `${ROUTE_CHANGE_DD_DATE}/confirm`;
export const ROUTE_DD_CALCULATOR = `${ROUTE_PAYMENTS}${SLUG_DD}/calculator`;
export const ROUTE_RECOMMENDED_DD = `${ROUTE_PAYMENTS}${SLUG_DD}/recommended-direct-debit`;
export const ROUTE_SETUP_DD = `${ROUTE_PAYMENTS}${SLUG_DD}/setup`;
export const ROUTE_SETUP_DD_CONFIRM = `${ROUTE_SETUP_DD}/confirm`;
export const ROUTE_MAKE_CARD_PAYMENT = `${ROUTE_PAYMENTS}${SLUG_MAKE_CARD_PAYMENT}`;
export const ROUTE_MAKE_CARD_PAYMENT_STATUS = `${ROUTE_MAKE_CARD_PAYMENT}/status`;
export const ROUTE_MAKE_CARD_PAYMENT_CONFIRM_FROM_APP = `${ROUTE_MAKE_CARD_PAYMENT}/app/confirm`;
export const ROUTE_MAKE_CARD_PAYMENT_CANCELLED_FROM_APP = `${ROUTE_MAKE_CARD_PAYMENT}/app/cancelled`;
export const ROUTE_MAKE_CARD_PAYMENT_PENDING_FROM_APP = `${ROUTE_MAKE_CARD_PAYMENT}/app/pending`;
export const ROUTE_MAKE_CARD_PAYMENT_ERROR_FROM_APP = `${ROUTE_MAKE_CARD_PAYMENT}/app/error`;
export const ROUTE_REFUNDS_ONE_STOP = `${ROUTE_PAYMENTS}${SLUG_REFUNDS}`;
export const ROUTE_REFUNDS_CONFIRM = `${ROUTE_PAYMENTS}${SLUG_REFUNDS}/confirm`;
export const ROUTE_REFUNDS_NOT_ELIGIBLE = `${ROUTE_PAYMENTS}${SLUG_REFUNDS}/not-eligible`;
export const ROUTE_RENEWAL = '/renewal';
export const ROUTE_RENEWAL_SVT = '/renewal/svt';
export const ROUTE_RENEWAL_SVT_COMM = '/renew/svt'; // Comm uses wrong path
export const ROUTE_RENEWAL_SUCCESS = `${ROUTE_RENEWAL}/success`;
export const ROUTE_RENEWAL_EOC = `${ROUTE_RENEWAL}/end-of-contract-options`;
export const ROUTE_SELECT_ACCOUNT = '/select-account';
export const ROUTE_PAYG = '/prepayment';
export const ROUTE_GLOBAL_ERROR = '/error';
export const ROUTE_PLAN = '/plan';
export const ROUTE_METER_READINGS = '/meter-readings';
export const ROUTE_METER_READINGS_NEW = `${ROUTE_METER_READINGS}/new`;
export const ROUTE_METER_READINGS_HISTORY = `${ROUTE_METER_READINGS}/history`;
export const ROUTE_OPENING_METER_READINGS = '/opening-meter-readings'; // @deprecated use meter readings routes instead.
export const ROUTE_OPENING_METER_READINGS_NEW = `${ROUTE_OPENING_METER_READINGS}/new`; // @deprecated use meter readings routes instead.
export const ROUTE_BILLING_HISTORY = '/billing-history';
export const ROUTE_BILLING_DETAILS = '/billing-details';

const KONG_API_ROOT = process.env.NX_KONG_BASE_URL;
export const BAL_API_ROOT = `${KONG_API_ROOT}/bal`;
export const BAL_ROUTE_BILLING_DOWNLOAD_BILL = `${BAL_API_ROOT}/bill`;

export const ROUTE_BILLING_DOWNLOAD_STATEMENT = `${BAL_API_ROOT}/statements`;

export const ROUTE_GREENER_ENERGY = '/greener-energy';
export const ROUTE_GREENER_ENERGY_SIGNUP = `${ROUTE_GREENER_ENERGY}/signup`;
export const ROUTE_GREENER_ENERGY_SIGNUP_SUCCESS = `${ROUTE_GREENER_ENERGY}/signup/success`;

export const ROUTE_GREENER_ELECTRICITY = '/greener-electricity';
export const ROUTE_GREENER_ELECTRICITY_SIGNUP = `${ROUTE_GREENER_ELECTRICITY}/signup`;
export const ROUTE_GREENER_ELECTRICITY_SIGNUP_SUCCESS = `${ROUTE_GREENER_ELECTRICITY}/signup/success`;

export const ROUTE_ENERGY_INSIGHTS = {
  HUB: '/energy-insights/hub',
  CARBON_INTENSITY: '/energy-insights/carbon-intensity',
  BILL_BREAKDOWN: '/energy-insights/bill-breakdown',
  TERMS: '/energy-insights/terms',
};

export const ROUTE_OFFERS = '/offers';

export const ROUTE_MOVING_HOME = '/moving-home';
export const ROUTE_MOVE_IN = '/move-in';
export const ROUTE_MOVING_HOME_MOVE_IN = `${ROUTE_MOVING_HOME}${ROUTE_MOVE_IN}`;
export const ROUTE_MOVE_IN_SUBPAGES = {
  Bank: `${ROUTE_MOVE_IN}/bank`,
  Details: `${ROUTE_MOVE_IN}/details`,
  GetQuote: `${ROUTE_MOVE_IN}/get-quote`,
  Meters: `${ROUTE_MOVE_IN}/meters`,
  Plan: `${ROUTE_MOVE_IN}/plan`,
  Quote: `${ROUTE_MOVE_IN}/quote`,
  Upgrades: `${ROUTE_MOVE_IN}/upgrades`,
  Success: `${ROUTE_MOVE_IN}/success`,
};

export const ROUTE_MOVING_HOME_CONFIRM = '/moving-home/confirm';
export const EXTERNAL_ROUTE_HELP = 'https://help.ovoenergy.com';
export const EXTERNAL_ROUTE_SPOTLIGHTS =
  'https://dashboard.carbon-zero.ovoenergy.com/spotlights';
export const EXTERNAL_ROUTE_HELP_INTEREST_REWARD_CALCULATION = `${EXTERNAL_ROUTE_HELP}/article/4cc20459-0cdf-42bc-9881-131543e10cee-web`;
export const ROUTE_HOLDING = '/holding';

export const ROUTE_SMART_THERMOSTATS_OFFER =
  ROUTE_OFFERS + '/smart-thermostats';
export const ROUTE_MY_THERMOSTAT = '/tado/my-thermostat';
export const ROUTE_EV = '/my-ev';
export const ROUTE_EV_DISCONNECT = `${ROUTE_EV}/disconnect-smartcharger`;
export const ROUTE_EV_DISCONNECT_CONFIRMATION = `${ROUTE_EV_DISCONNECT}/confirmation`;
export const EXTERNAL_ROUTE_HELP_GLOBAL_ERROR = `${EXTERNAL_ROUTE_HELP}/article/d599d701-59f4-490c-bc48-b6557af3bfd0-orion`;
export const ROUTE_ANYTIME = '/anytime';
export const ROUTE_CHARGE_ANYTIME_ELIGIBILITY = '/anytime/eligibility';

export const ROUTE_ANYTIME_ELIGIBILITY = {
  SMART_CHARGER: `${ROUTE_ANYTIME}/smart-charger`,
  VEHICLE_AND_HOME_SETUP: `${ROUTE_ANYTIME}/vehicle-and-home-setup`,
  CONSENT: `${ROUTE_ANYTIME}/consent`,
  PROMO: `${ROUTE_ANYTIME}/promo`,
  CONFIRMATION: `${ROUTE_ANYTIME}/confirmation`,
  FALLOUT: `${ROUTE_ANYTIME}/fallout`,
  CHARGE_ANYTIME_APP: `${ROUTE_ANYTIME}/charge-anytime-app`,
  VOLVO_APP: `${ROUTE_ANYTIME}/volvo-app`,
};

const GREENLIGHT = process.env.NX_GREENLIGHT_URL;
export const EXTERNAL_ROUTE_GREENLIGHT = `${GREENLIGHT}/ovo-greenlight`;
export const EXTERNAL_ROUTE_GREENLIGHT_BILL_BREAKDOWN = `${GREENLIGHT}/ovo-greenlight/bill-breakdown`;

export const RENEWABLE_GENERATION_LINK =
  'https://www.ovoenergy.com/renewable-ppas';
export const TRACEABLE_ELECTRICITY_LINK =
  'https://www.ovoenergy.com/greener-energy';

export const GREENER_ENERGY_TERMS =
  'https://www.ovoenergy.com/terms/greener-energy';

export const GREENER_ELECTRICITY_TERMS =
  'https://www.ovoenergy.com/terms/greener-electricity';

export const GREENER_ELECTRICITY_INFO_PAGE =
  'https://www.ovoenergy.com/greener-electricity';

export const ROUTE_POWER_MOVE = '/power-move';
const EXTERNAL_ROUTE_SWITCH = 'https://switch.ovoenergy.com';
export const EXTERNAL_ROUTE_SWITCH_WITH_UTM = `${EXTERNAL_ROUTE_SWITCH}?utm_source=MyOVO&utm_medium=PowerMoveException&utm_campaign=PowerMove_Quote`;

export const HOME_PLAN_BOILER_COVER_PROMO =
  'https://homeservices.ovoenergy.com/?utm_medium=banner&utm_source=nba&utm_campaign=137_incentive_xsell&promoCode=SAVE';

export const HOME_PLAN_BOILER_COVER_FREE_FOUR_MONTHS_PLUS_CREDIT_PROMO =
  'utm_medium=banner&utm_source=nba&utm_campaign=svt_addon_q4_offer';

export const HOME_PLAN_BOILER_COVER_WINBACK =
  'https://homeservices.ovoenergy.com/?utm_medium=banner&utm_source=nba&utm_campaign=139_winback_xsell&promoCode=SAVE';

export const HOME_PLAN_FIXED_ADDON_Q1_OFFER =
  'utm_medium=banner&utm_source=nba&utm_campaign=fixed_addon_q1_offer';

export const HOME_PLAN_SVT_ADDON_Q1_OFFER =
  'utm_medium=banner&utm_source=nba&utm_campaign=svt_addon_q1_offer';

export const HOME_PLAN_ENERGY_CREDITS_PROMO_FIXED_OFFER =
  'utm_medium=banner&utm_source=nba&utm_campaign=fixed_addon_q1_offer';

export const HOME_PLAN_BLACK_FRIDAY_OFFER_SVT_PLUS_BC =
  'utm_medium=banner&utm_source=nba&utm_campaign=svt_addon_black_friday';

export const HOME_PLAN_ENERGY_CREDITS_PROMO_SVT =
  '&utm_medium=banner&utm_source=nba&utm_campaign=svt_addon';

export const ROUTE_SMART_THERMOSTAT_OFFER_WITH_UTM = `${ROUTE_SMART_THERMOSTATS_OFFER}?utm_source=greenlight&utm_medium=web&utm_campaign=heating_tip`;

export const ROUTE_ENERGY_EXPERTS = '/energy-experts';

export const TERMS_AND_CONDITIONS_LINK = 'https://www.ovoenergy.com/terms';
export const PRIVACY_POLICY_LINK = 'https://www.ovoenergy.com/privacy-policy';

export const TADO_GUIDE_LINK =
  'https://www.ovoenergy.com/guides/energy-guides/how-to-install-a-tado-wireless-smart-thermostat.html';
export const TADO_SPEC_LINK =
  'https://www.tado.com/gb-en/wireless-smart-thermostat-specifications-overview';
export const TADO_GUIDE =
  'https://www.ovoenergy.com/guides/energy-guides/smart-thermostats-explained.html';
export const TADO_SUPPORT_LINK = 'http://www.support.tado.com/en';
export const TADO_CHAT_LINK = 'http://www.tado.com/en';
export const TADO_TERMS_AND_CONDITIONS =
  'https://www.tado.com/gb-en/terms-and-conditions';
export const SMART_THERM_TERMS_AND_CONDITIONS_LINK =
  'https://www.ovoenergy.com/terms/smart-thermostat';
export const SMART_THERM_PHONE_NUMBER = 'tel:+443301759691';
export const SMART_THERM_EMAIL_ADDRESS = 'smartthermostats@ovoenergy.com';

export const TADO_COMPATIBILITY_GUIDE =
  'https://www.ovoenergy.com/guides/energy-guides/tado-smart-thermostat-compatibility';

export const CHECK_A_TRADE = 'https://www.checkatrade.com/';

export const SMART_METERS_LINK = 'https://www.ovoenergy.com/smart-meters';

export const BLACK_FRIDAY_TERMS_LINK_2021 = `https://www.ovoenergy.com/campaigns/smart-thermostat/green-friday-2021/terms`;

export const TADO_STUDY = 'https://www.tado.com/gb-en/fraunhofer-study';

export const FAQ_LINK = 'https://www.ovoenergy.com/help';

export const ROUTE_BOILER_COVER = '/boiler-cover';

export const CHARGE_ANYTIME_EMAIL_ADDRESS = 'chargeanytime@ovo.com';

export const SUPPORT_EMAIL_ADDRESS = 'ovoapp.support@ovo.com';

export const OHME_SUPPORT_EMAIL = 'help@ohme-ev.com';
export const OHME_SUPPORT_PHONE = '020 3375 1586';

export const INDRA_SUPPORT_EMAIL = 'support@indra.co.uk';
export const INDRA_SUPPORT_PHONE = '01684 770 631';
