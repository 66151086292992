import { format, isFuture, isPast, isToday, isValid } from 'date-fns';
import { UseQueryResult } from 'react-query';
import { PowerMoveChallengeType, MappedPowerMoveChallenge } from '../types';
import { PRODUCT_IDS } from '@/src/constants/products';
import {
  BoltonInstanceV2,
  CreditingConfigType,
  PowerMoveCreditingConfig,
  PowerMoveTierV2,
  TieredPowerMoveCreditingConfig,
  TieredPowerMoveCreditingConfigV2,
} from '@/src/types/Products';
import { ProductCatalogueV2 } from '@/src/types/Response';

export const formatTime = (time?: string) => {
  try {
    if (!time) return 'unknown time';

    const date = new Date();
    const [hours, minutes] = time.split(':');
    date.setHours(parseInt(hours));
    date.setMinutes(parseInt(minutes));

    if (date.getMinutes() > 0) return format(date, 'h:mma');
    return format(date, 'ha');
  } catch (error) {
    return 'unknown time';
  }
};

export const startCase = (string: string) => {
  if (!string) return '';

  const lowerCasedString = string.toLowerCase();
  const upperCasedFirstLetter = string.charAt(0).toUpperCase();

  return upperCasedFirstLetter + lowerCasedString.slice(1);
};

export const getChallengePeriod = (
  startDate?: Date | null,
  endDate?: Date | null,
): string | null => {
  if (!startDate || !isValid(startDate)) {
    return null;
  }

  if (!endDate) {
    if (isToday(startDate)) {
      return 'Starts today!';
    }
    if (isPast(startDate)) {
      return `Started ${format(startDate, 'D MMMM')}`;
    }
    if (isFuture(startDate)) {
      return `Starts ${format(startDate, 'D MMMM')}`;
    }
  }
  if (endDate && isValid(endDate)) {
    return `${format(startDate, 'D MMMM')} to ${format(endDate, 'D MMMM')}`;
  }

  return null;
};

export const getChallengeType = (
  creditingConfig:
    | PowerMoveCreditingConfig
    | TieredPowerMoveCreditingConfig
    | TieredPowerMoveCreditingConfigV2,
): PowerMoveChallengeType => {
  if ('tiers' in creditingConfig) return PowerMoveChallengeType.MULTI_TIER;
  return PowerMoveChallengeType.SINGLE_TIER;
};

export const getChallengeTiers = (
  creditingConfig:
    | PowerMoveCreditingConfig
    | TieredPowerMoveCreditingConfig
    | TieredPowerMoveCreditingConfigV2,
): PowerMoveTierV2[] => {
  // Power Move
  if (creditingConfig.type === CreditingConfigType.PeakUsageReduction) {
    return [
      {
        greaterThanOrEqualTotalPercentage: '0',
        lessThanOrEqualTotalPercentage:
          creditingConfig.lessThanTotalPercentage.toString(),
        monthlyReward: creditingConfig.monthlyReward,
      },
    ];
  }

  // Tiered Power Move
  return creditingConfig.tiers.map<PowerMoveTierV2>(tier => ({
    lessThanOrEqualTotalPercentage: tier.lessThanOrEqualTotalPercentage,
    monthlyReward: tier.monthlyReward,
    greaterThanOrEqualTotalPercentage:
      'greaterThanOrEqualTotalPercentage' in tier
        ? tier.greaterThanOrEqualTotalPercentage
        : tier.greaterThanTotalPercentage,
  }));
};

export const findHighestLessThanOrEqualTotalPercentage = (
  creditingConfig:
    | PowerMoveCreditingConfig
    | TieredPowerMoveCreditingConfig
    | TieredPowerMoveCreditingConfigV2,
): number => {
  // Power Move
  if (creditingConfig?.type === CreditingConfigType.PeakUsageReduction) {
    return creditingConfig.lessThanTotalPercentage;
  }

  // Tiered Power Move
  const highestLessThanOrEqualTotalPercentage = Math.max(
    ...creditingConfig.tiers.map(tier =>
      Number(tier.lessThanOrEqualTotalPercentage),
    ),
  );

  return highestLessThanOrEqualTotalPercentage;
};

export const findHighestMonthlyReward = (
  creditingConfig:
    | PowerMoveCreditingConfig
    | TieredPowerMoveCreditingConfig
    | TieredPowerMoveCreditingConfigV2,
): number => {
  // Power Move
  if (creditingConfig?.type === CreditingConfigType.PeakUsageReduction) {
    return creditingConfig.monthlyReward;
  }

  // Tiered Power Move
  const highestMonthlyReward = Math.max(
    ...creditingConfig.tiers.map(tier => tier.monthlyReward),
  );

  return highestMonthlyReward;
};

export const getActiveUntilDate = (
  versionId: string,
  catalogueBoltonsQuery: UseQueryResult<ProductCatalogueV2, unknown>,
): Date | null => {
  const bolton = catalogueBoltonsQuery.data?.boltons.find(
    b => b.versionId === versionId,
  );
  if (bolton && bolton.catalogueEndDate) {
    return new Date(bolton.catalogueEndDate);
  }
  return null;
};

type JoinedChallenge = {
  versionId: string;
  activeUntil?: string;
};

export const getJoinedChallengesData = (boltons: Array<BoltonInstanceV2>) =>
  boltons.reduce<JoinedChallenge[]>((acc, curr) => {
    if (
      curr.productId === PRODUCT_IDS.powerMove &&
      (curr.status === 'Initialized' || curr.status === 'Active')
    ) {
      const { versionId, activeUntil } = curr;
      acc.push({ versionId, activeUntil });
    }
    return acc;
  }, []);

export const formatTiersArray = (
  challenge: MappedPowerMoveChallenge,
): Array<[string, string]> => {
  const transformedArray = [...(challenge?.tiers ?? [])]
    .sort((a, b) => b.monthlyReward - a.monthlyReward)
    .reduce((acc: Array<[string, string]>, curr) => {
      const lessThanOrEqualTotalPercentage = `${curr?.greaterThanOrEqualTotalPercentage}% to ${curr?.lessThanOrEqualTotalPercentage}%`;
      const monthlyReward = `£${curr?.monthlyReward}`;

      acc.push([lessThanOrEqualTotalPercentage, monthlyReward]);
      return acc;
    }, []);

  return transformedArray;
};

export const formatHighestReward = (reward: number): string => {
  const roundedNumber = Number(reward.toFixed(2));
  return Number.isInteger(roundedNumber)
    ? roundedNumber.toString()
    : roundedNumber.toFixed(2);
};
