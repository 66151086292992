import { Heading1, Heading2 } from '@ovotech/nebula';
import { themeConfig } from '@ovotech/ovoenergy-theme';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { mqDesktopNav } from '@/src/utils/mediaQuery';

interface Props {
  icon: ReactNode;
  title: string;
  subTitle?: string;
}

const StyledTitle = styled(Heading1)`
  ${({ theme: { fontSizes, fontWeights } }) => `
    color: ${themeConfig.colors.primary};

    font-weight: ${fontWeights.bold};
    margin: 0;

    ${mqDesktopNav(`
      font-size: ${fontSizes[7]};
    `)}
  `}
`;

const StyledSubTitle = styled(Heading2)`
  ${({ theme: { fontSizes, fontWeights, space } }) => `
    color: ${themeConfig.colors.primary};
    font-weight: ${fontWeights.normal};
    font-size: ${fontSizes[2]};
    margin: ${space[2]} 0;

    ${mqDesktopNav(`
        font-size: ${fontSizes[3]};
    `)}
  `}
`;

export const IconWithContent = ({ icon, title, subTitle }: Props) => (
  <>
    {icon}
    <StyledTitle>{title}</StyledTitle>
    {!!subTitle && <StyledSubTitle>{subTitle}</StyledSubTitle>}
  </>
);
