import { EnergyProvider, profileLifecycleEvents } from '@ovotech/energy-cx';
import { formatPath, NavigateHook } from '@ovotech/ui-tools';

import React from 'react';
import {
  Redirect as RouterRedirect,
  Route,
  useHistory,
} from 'react-router-dom-v5';
import { InternalLink } from '@/src/components/Navigation/InternalLink';
import { links } from '@/src/core/links';
import { modify } from '@/src/core/modify';
import theme, { icons } from '@/src/core/theme';
import { useTranslations } from '@/src/core/translations';
import { store } from './WrappedApp';
import { getCustomerAccessToken } from '@/src/api/utils/getCustomerAccessToken';
import SwitchWithNotFound from '@/src/components/SwitchWithNotFound/SwitchWithNotFound';
import {
  AddressField,
  ErrorBoundary,
  ErrorSection,
  Pii,
  TransactionTitle,
  Wrapper,
} from '@/src/components';
import { routes } from '@/src/core/routes';
import { mixpanelTracker } from '@/src/core/trackers';
import { saveSelectedAccountCookie } from '@/src/utils/account-selection';
import { selectAccount } from '@/src/redux/ducks/user';
import { MARKETING_EMAIL_REFERRER_URL } from '@/src/constants/links';

const getFeatureOverrides = () => {
  const featuresFromLocalStorage = localStorage.getItem('CXFeatureSelection');
  return featuresFromLocalStorage
    ? { features: JSON.parse(featuresFromLocalStorage) }
    : null;
};

const Redirect = ({ to }: { to: string }) => {
  return <RouterRedirect to={to} />;
};

const useCustomNavigate: NavigateHook = () => {
  const history = useHistory();
  return (to, options) => {
    if (typeof to === 'number') {
      history.go(to);
    } else {
      if (options && options.replace) {
        history.replace(formatPath(to));
      } else {
        history.push(formatPath(to));
      }
    }
  };
};

// Currently using hello.ovoenergy.com
const skipRedirectForReferrer = (referrer: string | undefined) => {
  if (!referrer) {
    return false;
  }
  return document.referrer.includes(referrer);
};

const Routes = () => (
  <SwitchWithNotFound>
    {routes.map(({ path, component: Component, exact }) => {
      return (
        <Route key={path} path={path} exact={exact}>
          <Component />
        </Route>
      );
    })}
  </SwitchWithNotFound>
);

const App = () => {
  // Applies any overrides set by cypress CXFeatureSelection.
  const featureOverrides = getFeatureOverrides();
  const features = featureOverrides || {
    timeout: 2000,
  };

  const translations = useTranslations();

  return (
    <EnergyProvider
      useNavigateHook={useCustomNavigate}
      analytics={{
        data: {
          project: {
            name: 'Orion Web',
            brand: 'OVO Energy',
          },
        },
        trackers: [mixpanelTracker],
      }}
      subscriptions={[
        {
          key: profileLifecycleEvents.ACCOUNT_SELECTED,
          // @ts-ignore
          handler: (data: { accountId: string }) => {
            saveSelectedAccountCookie(data.accountId);
            store.dispatch(selectAccount(data.accountId));
            window.location.href = '/';
          },
        },
      ]}
      i18nConfig={{
        resources: translations,
        fallbackLng: 'en',
      }}
      links={links}
      config={{
        siteName: 'OVO Energy',
        api: {
          baseUrl: `${process.env.NX_KONG_BASE_URL}`,
        },
        auth: {
          loginUrl: `${process.env.NX_LOGIN_REDIRECT}`,
          redirectParam: 'redirect',
          useRedirectCheck: !skipRedirectForReferrer(
            MARKETING_EMAIL_REFERRER_URL,
          ),
          getCustomerAccessToken,
        },
        retailer: {
          region: 'UK',
          currencyCode: 'GBP',
        },
        kapi: {
          profile: false,
          accountSummary: false,
          plan: false,
        },
        feedbackPrompt: true,
      }}
      components={{
        ErrorSection,
        Pii,
        InternalLink,
        Redirect,
        TransactionTitle,
        AddressField,
      }}
      // @ts-ignore.
      theme={theme}
      modify={modify}
      feature={features}
      icons={icons}
    >
      <ErrorBoundary errorId="usage-error">
        <Wrapper>
          <Routes />
        </Wrapper>
      </ErrorBoundary>
    </EnergyProvider>
  );
};

export default App;
