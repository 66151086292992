import { Card, ErrorNotification, Heading1, Margin, P } from '@ovotech/nebula';
import React from 'react';
import { StyledBreadCrumb, PageWithNavigation } from '@/src/components';
import { ROUTE_HOME } from '@/src/constants/routes';

type ErrorProps = {
  message: string;
};

export const PowerMoveErrorPage = ({ message }: ErrorProps) => (
  <PageWithNavigation title={'Power Move'}>
    <Margin top={8}>
      <Card data-testid="power-move-error">
        <StyledBreadCrumb data-testid="back-to-payments" href={ROUTE_HOME}>
          Back to home screen
        </StyledBreadCrumb>
        <Heading1>Power Move</Heading1>
        <ErrorNotification id="eligibility-error">
          <P>{message}</P>
        </ErrorNotification>
      </Card>
    </Margin>
  </PageWithNavigation>
);
