import { BannerId } from '../../../ActionCentre/Banners/banners';

type EligibilityVars = {
  hasMoveOutDate: boolean;
  hasAFinalStatement: boolean;
  hasBothFinalStatements: boolean;
  hasNextPlan: boolean;
  lossCompleteForBothFuels: boolean;
  inLoss: boolean;
  inLossForDualFuel: boolean;
  isDualFuel: boolean;
  smartBookingPresent: boolean;
  showPaymentMaintenance: boolean;
  oseSmartBookingOffer: boolean;
};

// These are mock NBA action codes. Service banners are not driven from the NBA API.
// These are created as a single, generic way to id cards whether they are hardcoded or fetched from Contentful.
// They can be removed as service banners are migrated to NBA.
export type ServiceActionCodes =
  | '_service_banner_first_login_generic'
  | '_service_banner_move_out_complete'
  | '_service_banner_move_out_in_progress'
  | '_service_banner_final_statement_ready'
  | '_service_banner_final_statement_one_staying'
  | '_service_banner_final_statement_one_ready'
  | '_service_banner_final_statement_one_in_loss'
  | '_service_banner_loss_pending_single_loss'
  | '_service_banner_loss_pending_single_fuel'
  | '_service_banner_loss_pending_duel_fuel'
  | '_service_banner_smart_meter_manage_booking'
  | '_service_banner_next_plan'
  | '_service_banner_planned_maintenance';

export type ServiceBannerConfig = {
  id: BannerId;
  actionCode: ServiceActionCodes;
  shown: boolean;
  dismissDurationDays?: number;
};

export const ruleset = (vars: EligibilityVars): Array<ServiceBannerConfig> => {
  const {
    hasMoveOutDate,
    hasAFinalStatement,
    hasBothFinalStatements,
    inLoss,
    inLossForDualFuel,
    isDualFuel,
    lossCompleteForBothFuels,
    hasNextPlan,
    smartBookingPresent,
    showPaymentMaintenance,
    oseSmartBookingOffer,
  } = vars;
  // the return order is important here
  // we could define the order elsewhere but it's fine here. for now.

  return [
    {
      id: 'moveOutComplete',
      actionCode: '_service_banner_move_out_complete',
      shown: hasMoveOutDate && hasAFinalStatement,
    },
    {
      id: 'moveOutInProgress',
      actionCode: '_service_banner_move_out_in_progress',
      shown: hasMoveOutDate && !hasAFinalStatement,
    },
    {
      id: 'finalStatementReady',
      actionCode: '_service_banner_final_statement_ready',
      shown: hasBothFinalStatements || (!isDualFuel && hasAFinalStatement),
    },
    {
      id: 'finalStatementOneStaying',
      actionCode: '_service_banner_final_statement_one_staying',
      shown: hasAFinalStatement && isDualFuel && !inLossForDualFuel,
    },
    {
      id: 'finalStatementOneReady',
      actionCode: '_service_banner_final_statement_one_ready',
      shown:
        hasAFinalStatement &&
        lossCompleteForBothFuels &&
        !hasBothFinalStatements,
    },
    {
      id: 'finalStatementOneInLoss',
      actionCode: '_service_banner_final_statement_one_in_loss',
      shown:
        hasAFinalStatement &&
        !hasBothFinalStatements &&
        !lossCompleteForBothFuels,
    },
    {
      id: 'lossPendingSingleLoss',
      actionCode: '_service_banner_loss_pending_single_loss',
      shown: inLoss && isDualFuel && !inLossForDualFuel,
    },
    {
      id: 'lossPendingSingleFuel',
      actionCode: '_service_banner_loss_pending_single_fuel',
      shown: inLoss && !isDualFuel,
    },
    {
      id: 'lossPendingDualFuel',
      actionCode: '_service_banner_loss_pending_duel_fuel',
      shown: inLoss,
    },
    {
      id: 'smartMeterManageBooking',
      actionCode: '_service_banner_smart_meter_manage_booking',
      shown: smartBookingPresent && oseSmartBookingOffer,
    },
    {
      id: 'nextPlan',
      actionCode: '_service_banner_next_plan',
      shown: hasNextPlan,
    },
    {
      id: 'plannedMaintenance',
      actionCode: '_service_banner_planned_maintenance',
      shown: showPaymentMaintenance,
    },
  ];
};
