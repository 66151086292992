import { AvailablePlan } from './types';

export const VAT_RATE_MULTIPLIER = 1.05; // Add 5% to the cost.

export type UnitRateLabel =
  | 'Day unit rate'
  | 'Night unit rate'
  | 'Peak unit rate'
  | 'Off-peak unit rate'
  | 'Heating unit rate'
  | 'Evening and weekend unit rate'
  | 'Unit rate';

export enum PlanFamily {
  threeYearFixed = '3 Year Fixed',
  twoYearFixed = '2 Year Fixed',
  twoYearFixAndProtect = '2 Year Fix And Protect',
  betterEnergy = 'Better Energy',
  oneYearFixedBoilerCover = '1 Year Fixed + Boiler Cover',
  oneYearFixed = '1 Year Fixed',
  simplerEnergy = 'Simpler Energy',
  evEverywhere = 'EV Everywhere',
  drive = 'Drive',
  memberSpecial = 'Member Special',
  stayAndSave = '1 Year Fixed Stay & Save',
  betterSmart = 'Better Smart',
  oneYearFixedGreenerEnergy = '1 Year Fixed + Greener Energy',
  oneYearFixedLoyalty = '1 Year Fixed Loyalty',
  twoYearFixedAndHeatingControl = '2 Year Fixed + Heating Control',
  oneYearFixedGoSmart = '1 Year Fixed Go Smart',
  oneYearFixedGreenerElectricity = '1 Year Fixed + Greener Electricity',
}

type PlanDetails = Pick<
  AvailablePlan,
  'title' | 'description' | 'features' | 'planType'
>;

const yearsFixedFor = (years: number) => ({
  text: `Fixed unit rates for ${years === 1 ? '1 year' : `${years} years`}`,
});

const greenerBundle = {
  id: 'greenerBundle',
  text: '12 months’ Greener Energy upgrade included for the price of 10',
};

const greenerElectricityBundle = {
  id: 'greenerElectricityBundle',
  text: '12 months’ Greener Electricity upgrade included for the price of 10',
};

const greenElectric = {
  id: 'greenElectric',
  text: '100% renewable electricity from UK generators',
};

const greenElectricity = {
  id: 'greenElectricity',
  text: '100% renewable electricity from UK generators',
};

const greenGas = {
  id: 'greenGas',
  text: '10% green gas and 90% carbon offset',
};

const renewables = {
  id: 'renewables',
  text: '£20 contribution each year to support new renewable generators',
};

const smartThermostat = {
  id: 'smartThermostat',
  text: 'Includes a tado° Smart Thermostat',
};

const twoYearsFixedHC = {
  id: 'twoYearsFixedHC',
  text: 'Fixed unit rates and standing charges for 2 years',
};

const heatingCosts = {
  id: 'heatingCosts',
  text: 'Save up to 28% in heating usage',
};
const controlYourHeating = {
  id: 'controlYourHeating',
  text: 'Control your heating from your phone',
};

export const PLAN_DETAILS_MAP: {
  [key in PlanFamily]: PlanDetails;
} = {
  [PlanFamily.threeYearFixed]: {
    title: '3 Year Fixed',
    description:
      'Get peace of mind against energy price rises with our longest term fixed rate plan.',
    features: [yearsFixedFor(3)],
    planType: PlanFamily.threeYearFixed,
  },
  [PlanFamily.twoYearFixed]: {
    title: '2 Year Fixed',
    description:
      'Enjoy price safety and fix your rates for 2 years with our most popular plan.',
    features: [yearsFixedFor(2)],
    planType: PlanFamily.twoYearFixed,
  },
  [PlanFamily.twoYearFixAndProtect]: {
    title: '2 Year Fixed Loyalty Bundle',
    description:
      'Secure your rates for 2 whole years and keep ahead of energy market changes.',
    features: [yearsFixedFor(2)],
    planType: PlanFamily.twoYearFixAndProtect,
  },
  [PlanFamily.oneYearFixedBoilerCover]: {
    title: '1 Year Fixed + Boiler Cover',
    description:
      'Secure your rates for a whole year and keep ahead of energy market changes.',
    features: [yearsFixedFor(2)],
    planType: PlanFamily.oneYearFixedBoilerCover,
  },
  [PlanFamily.betterEnergy]: {
    title: '1 Year Fixed',
    description:
      'Protect yourself from price rises and fix your rates for a year.',
    features: [yearsFixedFor(1)],
    planType: PlanFamily.betterEnergy,
  },
  [PlanFamily.oneYearFixed]: {
    title: '1 Year Fixed',
    description:
      'Protect yourself from price rises and fix your rates for a year.',
    features: [yearsFixedFor(1)],
    planType: PlanFamily.oneYearFixed,
  },
  [PlanFamily.simplerEnergy]: {
    title: 'Variable',
    description:
      'Our rolling plan where prices can go up and down following market changes.',
    features: [
      {
        text: 'Rolling contract',
      },
    ],
    planType: PlanFamily.simplerEnergy,
  },
  [PlanFamily.evEverywhere]: {
    title: 'EV 2 year fixed',
    description:
      'Our exclusive 2 year fixed plan for electric vehicle drivers.',
    features: [yearsFixedFor(2)],
    planType: PlanFamily.evEverywhere,
  },
  [PlanFamily.drive]: {
    title: 'EV 1 year fixed',
    description:
      'Our exclusive 1 year fixed plan for electric vehicle drivers.',
    features: [yearsFixedFor(1)],
    planType: PlanFamily.drive,
  },
  [PlanFamily.memberSpecial]: {
    title: '1 Year Fixed Loyalty',
    description:
      'Just for you: our plan made for loyal members, with fair prices fixed for a year when you renew.',
    features: [yearsFixedFor(1)],
    planType: PlanFamily.memberSpecial,
  },
  [PlanFamily.stayAndSave]: {
    title: '1 Year Fixed Loyalty',
    description:
      'Just for you: our plan made for loyal members, with fair prices fixed for a year when you renew.',
    features: [yearsFixedFor(1)],
    planType: PlanFamily.stayAndSave,
  },
  [PlanFamily.betterSmart]: {
    title: '1 Year Fixed Smart',
    description:
      'For new and existing members who already have, or would like a smart meter installed',
    features: [yearsFixedFor(1)],
    planType: PlanFamily.betterSmart,
  },
  [PlanFamily.oneYearFixedGreenerEnergy]: {
    title: '1 Year Fixed + Greener Energy',
    description:
      "Our greenest plan – stay in control of your energy costs and get our green upgrade included at a discounted rate. It's our traceable and transparent way to bring you renewable energy.",
    features: [
      yearsFixedFor(1),
      greenerBundle,
      greenElectric,
      greenGas,
      renewables,
    ],
    planType: PlanFamily.oneYearFixedGreenerEnergy,
  },
  [PlanFamily.oneYearFixedLoyalty]: {
    title: '1 Year Fixed Loyalty',
    description:
      'Control your energy costs for less with our cheapest fixed energy plan – exclusive to existing customers. No exit fees will be applied if you choose to switch to another OVO fixed tariff at any time during your plan term.',
    features: [yearsFixedFor(1)],
    planType: PlanFamily.oneYearFixedLoyalty,
  },
  [PlanFamily.twoYearFixedAndHeatingControl]: {
    title: '2 Year Fixed + Heating Control',
    description:
      'Enjoy energy price security for 2 years and control your heating costs with a tado° Smart Thermostat included.',
    features: [
      smartThermostat,
      heatingCosts,
      controlYourHeating,
      twoYearsFixedHC,
    ],
    planType: PlanFamily.twoYearFixedAndHeatingControl,
  },
  [PlanFamily.oneYearFixedGoSmart]: {
    title: '1 Year Fixed Go Smart',
    description:
      'Get a smart meter installed within 90 days to unlock our cheapest plan.',
    features: [yearsFixedFor(1)],
    planType: PlanFamily.oneYearFixedGoSmart,
  },
  [PlanFamily.oneYearFixedGreenerElectricity]: {
    title: '1 Year Fixed + Greener Electricity',
    description:
      "Our greenest plan. Stay in control of your energy costs and get our green upgrade included at a discounted rate. It's our traceable and transparent way to bring you renewable electricity",
    features: [
      yearsFixedFor(1),
      greenerElectricityBundle,
      greenElectricity,
      renewables,
    ],
    planType: PlanFamily.oneYearFixedGreenerElectricity,
  },
} as const;

export function getPlanConstants(
  planName: string
): PlanDetails & { subtitle: string } {
  const planKey = Object.keys(PLAN_DETAILS_MAP)
    .sort()
    .reverse()
    .find((planKey) => planName.startsWith(planKey)) as PlanFamily | undefined;

  // If title is not any of the above, it would not be in any computation.
  // So it can be assumed to be a title, but will always fail if used.
  return planKey
    ? { ...PLAN_DETAILS_MAP[planKey], subtitle: planName }
    : {
        title: planName,
        subtitle: '',
        description: '',
        features: [],
      };
}
