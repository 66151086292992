import { useBootstrapLegacyQuery } from '@ovotech/energy-cx';
import { mapKapiToLegacy } from '../utils/mapKapiBootstrap';
import { USE_KAPI_BOOTSTRAP } from '@/src/constants/feature-flags';
import { optimizely } from '@/src/utils/optimizely';
import { useQuery } from '@apollo/client';
import { graphql } from './__generated__';

const BOOTSTRAP_QUERY = graphql(/* kapi-codegen-graphql */ `
  query Bootstrap($customerId: ID!) {
    customer_nextV1(id: $customerId) {
      id
      customerAccountRelationships {
        edges {
          node {
            account {
              accountNo
              id
              accountSupplyPoints {
                ...AccountSupplyPoint
              }
            }
          }
        }
      }
    }
  }

  fragment AccountSupplyPoint on AccountSupplyPoint {
    startDate
    supplyPoint {
      sprn
      fuelType
      meterTechnicalDetails {
        meterSerialNumber
        mode
        type
      }
      address {
        addressLines
        postCode
      }
    }
  }
`);

export const useBootstrapQuery = (
  customerId: string | null | undefined,
  optimizelyReady: boolean,
) => {
  const kapiEnabled =
    !!customerId &&
    optimizelyReady &&
    optimizely.isFeatureEnabled(USE_KAPI_BOOTSTRAP);

  const {
    data: kapiData,
    loading: kapiLoading,
    error: kapiError,
  } = useQuery(BOOTSTRAP_QUERY, {
    variables: { customerId: customerId ?? '' },
    skip: !kapiEnabled || !customerId,
  });

  const legacyQuery = useBootstrapLegacyQuery({
    enabled: optimizelyReady && !kapiEnabled,
  });

  return {
    loading: kapiLoading || legacyQuery.isFetching,
    error: kapiError || legacyQuery.status === 'error',
    data: kapiData
      ? mapKapiToLegacy(kapiData)
      : legacyQuery.status === 'data'
      ? legacyQuery.data
      : undefined,
  };
};
