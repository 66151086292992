import { P, Strong } from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';
import { mqSmallDown } from '@/src/utils/mediaQuery';

interface Props {
  imgSrc: string;
  heading: string;
  body: string;
}

const StyledDiv = styled.div(
  ({ theme: { colors } }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: solid ${colors.borderMuted};
  padding: 24px 0px;
  width: 50%;

  ${mqSmallDown(`
    width: 100%;
  `)}
`,
);

const StyledStrong = styled(Strong)`
  align-self: baseline;
  margin: 0 4rem 1rem 0;
`;

const StyledP = styled(P)`
  text-align: left;
  margin: 0px 10px;
  align-self: baseline;
  margin: 0 4rem 0 0;

  ${mqSmallDown(`
    margin: 0;
  `)}
`;

const StyledContainerForIcon = styled.div`
  margin-left: 0;
  margin-right: auto;
`;

export const PowerMoveTip = ({ imgSrc, heading, body }: Props) => (
  <StyledDiv data-testid="power-tip-container">
    <StyledContainerForIcon>
      <img
        style={{ margin: '20px auto' }}
        width={49}
        height={49}
        src={imgSrc}
        alt="icon"
      />
    </StyledContainerForIcon>
    <StyledStrong>{heading}</StyledStrong>
    <StyledP>{body}</StyledP>
  </StyledDiv>
);
