import { PowerMoveTierV2, ProductId } from '@/src/types/Products';

export enum PowerMoveChallengeType {
  SINGLE_TIER = 'SINGLE_TIER',
  MULTI_TIER = 'MULTI_TIER',
}

type PeakHours = {
  from: string;
  to: string;
};

export type MappedPowerMoveChallenge = {
  id: ProductId;
  versionId: string;
  displayName: string;
  activeFrom: Date;
  activeUntil: Date | null;
  isUpcoming: boolean;
  isJoined: boolean;
  challengeType: PowerMoveChallengeType | null;
  peakHours: PeakHours | null;
  highestLessThanOrEqualTotalPercentage: number;
  highestMonthlyReward: number;
  tiers: Array<PowerMoveTierV2>;
  areWeekendsIncluded: boolean;
};
