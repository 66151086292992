import { Heading1, P, TextLink, Col } from '@ovotech/nebula';
import React from 'react';
import { Media } from '@/src/components/Media';

import { Page } from '@/src/components/Pages/Pages';
import { ANYTIME_ERROR } from '@/src/constants/analytics-subjects';
import RenewalHeader from '@/src/pages/Renewal/ovo-retail/RenewalHeader/RenewalHeader';
import {
  Container,
  Content,
  LargeAndUpContentContainer,
  Row,
} from './AnytimeNotEligible.styled';
import { breakpoints } from '@/src/utils/mediaQuery';

// TODO: update anytime url
export const AnytimeNotEligible = () => (
  <div data-testid="anytime-not-eligible">
    <Media query={breakpoints.largeDown}>
      <RenewalHeader />
    </Media>
    <Container>
      <Content>
        <Media query={breakpoints.largeDown}>
          <Page
            title="Charge Anytime - Error"
            analytics={{ name: ANYTIME_ERROR }}
          >
            <Heading1 id="renewal-forbidden">
              That’s a shame – you don’t have Charge Anytime
            </Heading1>
            <P>
              Sorry. We can’t show you this page as the Charge Anytime add-on
              isn’t currently part of your OVO Energy plan.
            </P>
            <P>
              <TextLink href="https://www.ovoenergy.com" opensInNewWindow>
                Learn all about Charge Anytime
              </TextLink>
            </P>
            <P>
              <TextLink href="/anytime/eligibility">
                Find out if you're eligible
              </TextLink>
            </P>
          </Page>
        </Media>
      </Content>
      <Media query={breakpoints.largeUp}>
        <LargeAndUpContentContainer>
          <Row>
            <Col span={7}>
              <RenewalHeader />
              <Page
                title="Charge Anytime - Error"
                analytics={{ name: ANYTIME_ERROR }}
              >
                <Heading1 id="renewal-forbidden">
                  That’s a shame – you don’t have Charge Anytime
                </Heading1>
                <P>
                  Sorry. We can’t show you this page as the Charge Anytime
                  add-on isn’t currently part of your OVO Energy plan.
                </P>
                <P>
                  <TextLink href="https://www.ovoenergy.com" opensInNewWindow>
                    Learn all about Charge Anytime
                  </TextLink>
                </P>
                <P>
                  <TextLink href="/anytime/eligibility">
                    Find out if you're eligible
                  </TextLink>
                </P>
              </Page>
            </Col>
          </Row>
        </LargeAndUpContentContainer>
      </Media>
    </Container>
  </div>
);
