import { Heading3, Heading4, Li, Modal, P, Ul } from '@ovotech/nebula';
import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const StyledHeading3 = styled(Heading3)`
  color: #0a9828;
  font-size: larger;
`;

const StyledHeading4 = styled(Heading4)`
  color: #0a9828;
`;

export const CalculationsModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => (
  <Modal
    title={<StyledHeading3>How we calculate your peak use</StyledHeading3>}
    isOpen={isOpen}
    onClose={onClose}
    data-testid="calculation-modal"
    id="calculation-modal"
  >
    <StyledContainer>
      <StyledHeading4>When weekends are included</StyledHeading4>
      <P>
        Imagine your home uses 240 kilowatt hours (kWh) in the month and 12 kWh
        during peak hours (6pm to 9pm, Monday to Friday).
      </P>
      <Ul>
        <Li>Total monthly use = 240 kWh</Li>
        <Li>Total peak use = 12 kWh</Li>
      </Ul>
      <P>Here’s how we work out the peak use percentage:</P>
      <P>(12 kWh ÷ 240 kWh) x 100 = 5%</P>
      <P>This means your peak use would be 5% for the month. </P>
    </StyledContainer>
  </Modal>
);
