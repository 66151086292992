import { TextLink } from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';

import React from 'react';

import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import { ROUTE_SMART_THERMOSTAT_OFFER_WITH_UTM } from '@/src/constants/routes';

import { Link } from '@/src/components';
import {
  AnyUsageCategory,
  ElectricityUsageCategory,
  GasUsageCategory,
} from '@/src/pages/EnergyInsights/types';

export const thermostatTip = {
  title: 'Go smart',
  description: (
    <>
      If you haven’t already, think about getting yourself a{' '}
      <AnalyticsClick name={ENERGY_INSIGHTS.BUTTON_CLICK_SMART_THERMOSTAT_TIP}>
        <TextLink as={Link} to={ROUTE_SMART_THERMOSTAT_OFFER_WITH_UTM}>
          smart thermostat
        </TextLink>
      </AnalyticsClick>
      . This clever tech gives you the ultimate control over your heating,
      straight from your phone. You can see all kinds of data to help you waste
      less energy and save money on bills.
    </>
  ),
};

export type TipContent = {
  title: string;
  description: string | JSX.Element;
};
export type TipsByCategory<Category extends AnyUsageCategory> = Record<
  Category,
  TipContent[]
>;

const tipsLibrary = {
  cooking: {
    slowCooker: {
      title: 'Using a slow cooker',
      description: `If you've got a slow cooker, they only use a little more energy than a traditional lightbulb. Plus, you can leave it to do its thing and cook your food while you go about your day.`,
    },
    microwave: {
      title: 'Microwaving your food',
      description:
        "Microwaves use less energy than ovens and they don't need preheating, making them an efficient way to cook and heat up food. They also don't give off as much heat as regular ovens – so they're a good choice in summer, for keeping you (and your kitchen) cooler.",
    },
    potsAndPans: {
      title: 'Pots and pans',
      description:
        'Using your smallest pan on the biggest burner can waste up to 40% of the energy it takes to keep the heat going.',
    },
  },
  dishWasherEco: {
    title: 'Switching to eco mode',
    description:
      'The age-old energy-saving question. Well, a full dishwasher requires less than a third of the water it takes to hand wash those same dishes in the sink. Plus, instead of heating the water with gas, your dishwasher runs on electricity. And, if you have a smart meter, that electricity is 100% renewable.',
  },
  waterHeating: {
    dishwasherOrHand: {
      title: 'Dishwasher or hand-wash?',
      description:
        'The age-old energy-saving question. Well, a full dishwasher requires less than a third of the water it takes to hand wash those same dishes in the sink. Plus, instead of heating the water with gas, your dishwasher runs on 100% renewable electricity!',
    },
    turnOffHotWater: {
      title: 'Turning the hot water off',
      description:
        'If you’re not at home in summer, try turning your hot water off on your boiler. And, if you’re away in the winter, you can also turn your hot water and heating down to the lowest level (so your pipes don’t freeze).',
    },
    insulateCylinder: {
      title: 'Insulating your water cylinder',
      description:
        "If you've got a hot water cylinder, giving it a hot water jacket can help the tank retain heat and warm your water up faster. They cost about £15 and could save you up to £18 every year.",
    },
    showerHeads: {
      title: 'Water-efficient showerheads',
      description:
        'If you can, buying a new water-efficient showerhead will make the water flow feel stronger than it actually is, so you use less. They’re easy to install, plus they’ll save you water, energy and about £38 a year on your gas bills.',
    },
    shorterShowers: {
      title: 'Shorter showers',
      description: (
        <>
          Shaving just 1 minute off showers could save a family of 4{' '}
          <TextLink href="https://energysavingtrust.org.uk/simple-ways-to-save-water-at-home">
            £32 every year
          </TextLink>
          . Plus, getting a more efficient shower head could save you £38 on hot
          water.
        </>
      ),
    },
  },
  heating: {
    draughts: {
      title: 'Defeating draughts',
      description:
        'If you can, draught-proofing your windows and doors could cut those carbon emissions – and save on heating bills each year. Wiper seals (or dirt excluders), compression strips and silicone sealant are all good choices for windows and doors.',
    },
    heatLoss: {
      title: 'Stopping heat loss',
      description:
        'A quarter of heat is lost through the roof. If you can insulate your loft or roof space, this will make your home warmer – and reduce heating bills in the long run.',
    },
    leakyDoors: {
      title: 'Replacing leaky doors and windows',
      description:
        "If you're able to replace heat-leaking doors and windows with double-glazed (or even triple-glazed) ones, this will make your home warmer and more energy efficient.",
    },
    insulateWalls: {
      title: 'Insulating your walls',
      description:
        "If you're able to, insulating walls is a good idea. Even if you have solid walls they can still be insulated – either from the inside or the outside. Savings on heating bills should offset cost in the long run.",
    },
    bleedRadiators: {
      title: 'Bleeding your radiators',
      description:
        'It can seem tricky, but it’s super simple. And releasing air trapped in your heating system makes it work more efficiently. Just fire up the heating and, if the radiators take ages to warm up or have cold spots, it might be worth getting that excess air out.',
    },
    insulatePipes: {
      title: 'Insulating your central heating pipes',
      description:
        'You can find foam tubes in DIY shops – also known as “lagging”. Your boiler won’t have to work so hard - plus, they reduce heat loss, keeping your water hotter for longer.',
    },
    boilerPressure: {
      title: 'Getting the right boiler pressure',
      description:
        'Low pressure is bad news for a boiler’s efficiency – and how much it costs to run. High pressure can also stop it working well, too. Between 1-2 bars of atmospheric pressure on the gauge is usually about right.',
    },
    curtains: {
      title: 'Upgrading the curtains',
      description:
        "Curtains can be a fairly simple fix for draughty windows (and even doors). If you're able to hang up some heavy thermal curtains, this will help make your home cosier. It's also possible to add some special thermal draught-proof lining to the curtains you already have.",
    },
    turnDownHeating: {
      title: 'Turning down the heating',
      description:
        'Dialling it down by 1 degree could save around £80 a year (it is important to keep it at a safe temperature of between 18C and 21C though in winter).',
    },
    draughtProofing: {
      title: 'Draught-proofing on a budget',
      description:
        'Stop warm air escaping or cold air getting in through any gaps under your doors or windows with our handy guide to draught-proofing. If you can feel a draught under a door, consider making or buying a draught excluder.',
    },
    placeThermostat: {
      title: 'Placing your thermostat',
      description:
        'Try to keep your thermostat away from the TV, the back of the fridge, lamps – anything that gives off heat. The heat will cause it to reach its set temperature too quickly and switch off, but then restart – which means your boiler will waste energy by switching on and off constantly.',
    },
    reflectorPanels: {
      title: 'Reflector panels',
      description:
        'Radiator reflector panels are a great way to stop energy from escaping outside through your walls. Put one behind a radiator on an outside wall. It’ll bounce the heat back into the room instead of letting it escape, keeping you toasty.',
    },
  },
};

export const electricTipsByCategory: TipsByCategory<ElectricityUsageCategory> =
  {
    cooking: [...Object.values(tipsLibrary.cooking)],
    heating: [
      tipsLibrary.heating.draughts,
      tipsLibrary.heating.heatLoss,
      tipsLibrary.heating.leakyDoors,
      tipsLibrary.heating.insulateWalls,
      tipsLibrary.heating.curtains,
      tipsLibrary.heating.draughtProofing,
      tipsLibrary.heating.placeThermostat,
      tipsLibrary.heating.reflectorPanels,
    ],
    waterHeating: [
      tipsLibrary.waterHeating.shorterShowers,
      tipsLibrary.waterHeating.showerHeads,
      tipsLibrary.waterHeating.dishwasherOrHand,
    ],
    alwaysOn: [
      {
        title: 'Flick off the switch',
        description:
          "The average UK household spends £35 a year powering stuff left on standby. So whether it’s remembering to switch the TV off at the wall before going to bed, or switching off the coffee machine after it's made you a cup, it all adds up.",
      },
      {
        title: 'Trying a smart plug',
        description:
          'Smart plugs can do the hard work for you when it comes to switching off at the wall, by helping you to turn off all your standby appliances in one go and on a timer.',
      },
      {
        title: "Saving energy when you're away",
        description:
          "Lots of home appliances still use energy when they’re left on standby. If you're leaving the house for a while, switching off and unplugging everything you can help save energy – as well as turning your thermostat down to the lowest safe setting.",
      },
      {
        title: 'Being an energy-rating expert',
        description:
          "If you need to buy a new appliance for your home, try to make sure you've checked its energy rating. Finding out which models have the best scores can help save energy down the line.",
      },
      {
        title: 'Switching off appliances',
        description:
          'Did you know, kitchen appliances can use power even when they’re on standby? Switch all your appliances off at the wall when you’re not using them and you could save up to £30 a year (apart from your fridge-freezer of course!).',
      },
    ],
    lighting: [
      {
        title: 'Try a dimmer switch',
        description:
          'If you can, try swapping to a dimmer switch. Dimmers reduce the flow of electricity to your bulbs when you want lower lighting, meaning they last longer and save you energy.',
      },
    ],
    other: [
      thermostatTip,
      {
        title: 'Keeping the heat in (and out)',
        description:
          "Closing the doors of rooms you don't use much during the day is a good idea. In the winter, you’ll keep the warm air in, giving your radiators less work to do. And in the summer, you'll stop that lovely cool air escaping (especially if the curtains are closed too), so there’s less need for a fan.",
      },
      {
        title: 'Feeling the flow',
        description:
          "If you use a dehumidifier in your home or workplace, placing it away from walls and large items of furniture is a good idea. That's because dehumidifiers work better if air can circulate around them. Cleaning the dehumidifier regularly also keeps it free from mould and bacteria that could stop it working so well.",
      },
    ],
    entertainment: [
      {
        title: 'Did you know?',
        description:
          'Audio and video appliances are usually labelled from A to G, with A being the most energy-efficient. Choose those if you can: they tend to be kinder to the planet.',
      },
      {
        title: 'Switching off your devices',
        description:
          'Up late gaming or watching TV? Try switching all your tech off at the wall before going to bed (instead of leaving things on standby).',
      },
      {
        title: 'Powering down',
        description:
          "If you use a laptop or a computer, closing it down when you're done will use less energy – and extend its lifespan too.",
      },
    ],
    refrigeration: [
      {
        title: 'The ideal settings',
        description:
          "Is your appliance set to the ideal temperature? For a fridge this is 0C to 4C, and for a freezer it's -18C to -16C. If yours is too low, tweak those dials to save a little extra power.",
      },
      {
        title: 'Defrosting the freezer',
        description:
          'Defrosting the freezer may not be a glamorous job, but a good melting and scraping session once in a while could save money. Leaving it to get too icy in there will mean it needs more energy to keep things cold.',
      },
      {
        title: 'Checking your seals',
        description:
          'Check the seals on your fridge and freezer to make sure they’re still airtight – the appliance has to work harder if cold air’s leaking out, and uses more energy to keep the temperature steady.',
      },
    ],

    washing: [
      {
        title: 'Washing at lower temperatures',
        description:
          'A 7kg washing machine costs about £25-35 a year to run (when used an average of 220 times). And that uses a lot of water, too. Washing a full load, turning it down to 30C, and using the shortest cycle will save energy and water.',
      },
      tipsLibrary.dishWasherEco,
      {
        title: 'Cleaning out the dryer filter',
        description:
          'If you use a tumble dryer, cleaning the lint filter after each load should save energy. A clogged filter means an inefficient dryer, which will take longer and end up using more power.',
      },
      {
        title: 'Dishwasher settings',
        description:
          'If you need a new dishwasher, look for one that includes an “eco” or “overnight dry” setting. This uses less energy and lets your pots and pans dry naturally, rather than using heat.',
      },
      {
        title: 'Choosing efficiently',
        description:
          'If you’re buying a washing machine or dishwasher, choose one with a Water Efficient Product Label and/or the Waterwise Recommended Checkmark – which should help you to save water, energy and money.',
      },
    ],
    ev: [
      {
        title: 'Lightening the load',
        description:
          'Roof racks mean extra weight for your car to carry (which means using more fuel). They also cause wind resistance, increasing the amount of fuel you’re using even more. So if you’re not using yours, try removing it.',
      },
      {
        title: 'Clearing out the boot',
        description:
          "Less stuff in the boot means you’re carrying less weight – and your car won’t use as much fuel. So if you've got bits and bobs in there that you don't need, take them out.",
      },
      {
        title: 'Checking your tyre pressure',
        description:
          'Try checking your car’s tyre pressure regularly – especially before long journeys. Under-inflated tyres create more resistance to the road, so you’ll use more fuel. You’ll find the correct pressures for front and back tyres in your handbook (or often on a sticker inside the driver’s door).',
      },
      {
        title: 'Servicing your car',
        description:
          "A car that’s running efficiently uses less fuel, so it's a good idea to get your car serviced regularly – that will make sure it's in tip top shape.",
      },
    ],
  };

const gasTipsByCategory: TipsByCategory<GasUsageCategory> = {
  cooking: [...Object.values(tipsLibrary.cooking)],
  heating: [...Object.values(tipsLibrary.heating)],
  waterHeating: [...Object.values(tipsLibrary.waterHeating)],
};

export const mergedTipsByCategory: TipsByCategory<
  ElectricityUsageCategory & GasUsageCategory
> = {
  ...electricTipsByCategory,
  ...gasTipsByCategory,
};
