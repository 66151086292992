import { Card } from '@ovotech/nebula';
import styled, { css } from 'styled-components';
import { mqMediumDown } from '@/src/utils/mediaQuery';

export const NoUsageBreakdownBG = styled.img`
  position: absolute;
  padding: 10px 0 15px;
  z-index: 0;
  left: 0;
  min-width: 760px;
  min-height: 580px;
`;

export const StyledNoUsageBreakdown = styled.div`
  ${({ theme: { colors } }) => `
    background-color: ${colors.brand.grey.lightest};
    position: relative;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
  `}
`;

export const NoUsageBreakdownContainerModal = styled(Card)`
  display: flex;
  flex-direction: column;
  ${({ theme: { colors, space } }) => css`
    background-color: ${colors.brand.grey.lightest};
    padding: ${space[6]};
    width: 75%;
    z-index: 2;
    position: relative;
    box-shadow: 0px 2px 8px rgba(209, 214, 222, 0.8);
    max-height: 500px;
    ${mqMediumDown(`
      padding: ${space[2]};
  `)}
  `}
`;

export const NoUsageModalBody = styled.div`
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;

  ${({ theme: { fontWeights } }) => `
    font-weight: ${fontWeights.semiBold};
  `}
`;
