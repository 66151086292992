import { Li, Ul } from '@ovotech/nebula';
import { AvailablePlanFeature } from '@monovo/energy-renewals';
import React from 'react';
import { StyledTitle } from './Features.styled';
import { filterFeatures } from '@/src/utils/filter-features';

type FeatureProps = {
  exitFees: number;
  partialFeatures: readonly AvailablePlanFeature[];
};

function sanitiseFeatures({
  exitFees,
  partialFeatures,
}: FeatureProps): AvailablePlanFeature[] {
  const features = [...partialFeatures];
  features.push({
    text: exitFees === 0 ? 'No exit fees' : `£${exitFees} exit fee`,
  });

  return features;
}

export function Features(props: FeatureProps) {
  const features = sanitiseFeatures(props);
  return (
    <>
      <StyledTitle>Plan features</StyledTitle>
      <Ul data-testid="features-list">
        {features
          .filter(({ text }) => filterFeatures(text))
          .map(({ id, text }) => (
            <Li key={text} data-testid={`feature-${id}`}>
              {text}
            </Li>
          ))}
      </Ul>
    </>
  );
}
