import { useQuery } from 'react-query';
import { carbonZeroBffEndpoints } from '@/src/constants/endpoints';
import { BreakdownScreenResponse } from '@/src/types/Response';
import { apiService } from '@/src/services/api-service';

const breakdownScreenQueryKey = (accountId?: string | null) =>
  `breakdownScreen-${accountId}`;

export const useBreakdownScreenQuery = (accountId?: string | null) => {
  return useQuery(
    breakdownScreenQueryKey(accountId),
    () =>
      !accountId
        ? null
        : apiService.get<BreakdownScreenResponse>({
            url: carbonZeroBffEndpoints.getBreakdownScreen(accountId),
          }),
    { enabled: !!accountId },
  );
};
