import styled from 'styled-components';

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => `${theme.space[4]} 0`};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
