export const homeProfileModalTestIDs = {
  intro: {
    container: 'home-profile-intro',
    info: 'home-profile-intro-info',
    cta: 'home-profile-intro-cta',
  },
  form: {
    container: 'home-profile-form',
    step: (stepNumber: number) => `home-profile-form-step-${stepNumber}`,
    next: (stepNumber: number) => `home-profile-form-next-${stepNumber}`,
    previous: (stepNumber: number) =>
      `home-profile-form-previous-${stepNumber}`,
    submit: 'home-profile-form-submit',
    dismiss: 'nebula-modal-dismiss', // from design system
  },
};
