import {
  getPlanConstants,
  PlanFamily,
  UnitRateLabel,
  VAT_RATE_MULTIPLIER,
} from '../planConstants';
import {
  AvailablePlanKaluza,
  AvailablePlanKaluzaTariff,
  AvailablePlan,
  AvailablePlanTariff,
  UnitRate,
  UnitRateWithVAT,
} from '../types';

export type RefinePlanOptions = {
  canRenewToDrive?: boolean;
  canRenewToStayAndSave?: boolean;
  canRenewFixAndProtect?: boolean;
  canGetInterestReward?: boolean;
  isP1P2Renewal: boolean;
  isGreenerEligible?: boolean;
};

export function refineAvailablePlans(
  availablePlansKaluza: ReadonlyArray<AvailablePlanKaluza>,
  {
    canRenewToDrive = true,
    canRenewToStayAndSave = true,
    canRenewFixAndProtect = false,
    isP1P2Renewal,
    isGreenerEligible,
  }: RefinePlanOptions
): AvailablePlan[] {
  return availablePlansKaluza
    .filter(({ name }) => canRenewToDrive || !name.includes(PlanFamily.drive))
    .filter(
      ({ name }) =>
        canRenewToStayAndSave || !name.includes(PlanFamily.stayAndSave)
    )
    .filter(
      ({ name }) =>
        canRenewFixAndProtect || !name.includes(PlanFamily.twoYearFixAndProtect)
    )
    .filter((plan) =>
      isP1P2Renewal
        ? isConversionTariffPlan(plan)
        : !isConversionTariffPlan(plan)
    )
    .filter(
      ({ name }) =>
        isGreenerEligible ||
        !name.includes(PlanFamily.oneYearFixedGreenerElectricity)
    )
    .map((plan) => mapKaluzaAvailablePlanToAvailablePlan(plan))
    .sort(sortAvailablePlans);
}

export function sortAvailablePlans(
  p1: AvailablePlan,
  p2: AvailablePlan
): number {
  return p1.planType === PlanFamily.simplerEnergy
    ? 1
    : p2.planType === PlanFamily.simplerEnergy
    ? -1
    : Number(p1.estimatedAnnualCost) - Number(p2.estimatedAnnualCost);
}

function isConversionTariffPlan({ tariffs }: AvailablePlanKaluza): boolean {
  const electricityTariff = tariffs.find(
    (tariff) => tariff.fuel === 'electricity'
  );

  if (!electricityTariff) {
    return false;
  }

  const baseRate = electricityTariff.unitRates[0]?.unitRate;
  return (
    electricityTariff.unitRates.length > 1 &&
    electricityTariff.unitRates.every(({ unitRate }) => unitRate === baseRate)
  );
}

function mapKaluzaAvailablePlanToAvailablePlan(
  plan: AvailablePlanKaluza
): AvailablePlan {
  const planConstants = getPlanConstants(plan.name);

  const exitFeeTariff = plan.tariffs.find(
    (tariff) => tariff.exitFee && tariff.exitFee.amount !== '0.00'
  );

  const exitFeeFeatureText = {
    text: exitFeeTariff
      ? `£${Number(exitFeeTariff.exitFee.amount).toFixed(0)} exit fee per fuel${
          planConstants.planType === PlanFamily.oneYearFixedLoyalty
            ? ' if you change supplier'
            : ''
        }`
      : `No exit fees`,
  };

  const sortedTariffs = plan.tariffs
    .slice()
    .sort((t1, t2) => t1.fuel.localeCompare(t2.fuel));

  return {
    ...plan,
    ...planConstants,
    features: [...planConstants.features, exitFeeFeatureText],
    estimatedMonthlyCost: Number(plan.estimatedMonthlyCost.amount).toFixed(0),
    estimatedAnnualCost: Number(plan.estimatedAnnualCost.amount).toFixed(2),
    tariffs: sortedTariffs.map(toPlanTariff),
  };
}

function addVat(rate: string | number) {
  return (Number(rate) * VAT_RATE_MULTIPLIER).toFixed(2);
}

export function toPlanTariff(
  tariff: AvailablePlanKaluzaTariff
): AvailablePlanTariff {
  return {
    ...tariff,
    tariffId: tariff.id,
    defaultPercentageRenewable: tariff.fuel === 'gas' ? 0 : 100,
    additionalProducts: 'None',
    unitRates: tariff.unitRates.map(toUnitRateWithVAT).sort(sortUnitRates),
    standingRate: {
      ...tariff.standingRate,
      amountWithVAT: addVat(tariff.standingRate.amount),
    },
  };
}

function toUnitRateWithVAT(unitRate: UnitRate): UnitRateWithVAT {
  return {
    ...unitRate,
    label: getLabelForUnitRate(unitRate),
    unitRateWithVAT: addVat(unitRate.unitRate),
    unitRateInPence: {
      ...unitRate.unitRateInPence,
      amountWithVAT: addVat(unitRate.unitRateInPence.amount),
    },
  };
}

function getLabelForUnitRate(unitRate: UnitRate): UnitRateLabel {
  switch (unitRate.timingCategory) {
    case 'peak':
      return 'Peak unit rate';
    case 'offpeak':
      return 'Off-peak unit rate';
    case 'heating':
      return 'Heating unit rate';
    case 'evening_and_weekend':
      return 'Evening and weekend unit rate';
    case 'anytime':
      return 'Unit rate';
    default:
      return 'Unit rate';
  }
}

const UNIT_RATES_SORT_VALUES: { [key in UnitRateLabel]: number } = {
  'Unit rate': 1,
  'Day unit rate': 2,
  'Night unit rate': 3,
  'Peak unit rate': 4,
  'Off-peak unit rate': 5,
  'Evening and weekend unit rate': 6,
  'Heating unit rate': 7,
};

function sortUnitRates(first: UnitRateWithVAT, second: UnitRateWithVAT) {
  return (
    UNIT_RATES_SORT_VALUES[first.label] - UNIT_RATES_SORT_VALUES[second.label]
  );
}
