import { Card, Heading3 } from '@ovotech/nebula';
import styled, { css } from 'styled-components';

const closeButtonSpace = '30px';

export const Styled = {
  Card: styled(Card)`
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 0px;
    padding-right: 0px;

    @media (min-width: 700px) {
      flex-direction: row;
    }
  `,

  LeftContent: styled.div`
    ${({ theme }) => css`
      flex: 1;
      align-items: flex-start;
      padding-bottom: ${theme.space[3]};

      @media (min-width: 700px) {
        padding-bottom: ${theme.space[9]};
      }
    `}
  `,

  Heading: styled(Heading3)`
    ${({ theme: { colors } }) => css`
      color: ${colors.primary};
    `}
    padding-right: ${closeButtonSpace};
  `,

  Image: styled.img`
    align-self: flex-end;
    width: 300px;
  `,

  CloseButton: styled.button`
    position: absolute;
    right: 12px;
    top: 12px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  `,
};
