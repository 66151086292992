import {
  Col,
  Heading2,
  P,
  PrimaryCTALink,
  Row,
  TextLink,
} from '@ovotech/nebula';
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  EE_TERMS_LINK,
  EE_TERTIARY_LINK,
  ENERGY_EXPERT_FF_VARIABLE,
  INCLUDED_LIST,
  SMART_THERMOSTAT_FF_VARIABLE,
} from '../constants';
import { GetSmartMeterCard } from './GetSmartMeterCard';
import { Price } from './Price';
import { PtzOffer } from './PtzOffer';
import { UnorderedListSection } from './UnorderedListSection';
import { ThermostatsOffer } from './cards/ThermostatsOffer';
import { DisplayFlex } from '@/src/components';
import {
  CLICKED_OFFER_EVENT,
  NEW_OFFERS_PAGE,
} from '@/src/constants/analytics';
import { createKantanOceanSavingSolutionsJourneyUrl } from '@/src/constants/endpoints';
import { SHOW_PATH_TO_ZERO_OFFERS } from '@/src/constants/feature-flags';
import { PROMO_CODES } from '@/src/constants/products';
import { useEncryptedToken } from '@/src/pages/EnergyExperts/hooks/use-encrypted-token';
import analyticsService from '@/src/services/analyticsService';
import { mqMediumUp } from '@/src/utils/mediaQuery';
import { useDecision } from '@/src/utils/optimizely';
import { openInNewTab } from '@/src/utils/openInNewTab';
import EnergyExpertsImage from '@/src/resources/energy-exp.png';
import { getIsPTZOffersAvailable } from '../utils';
import { useEligibilityInfo } from '../../SmartThermostats/hooks/useEligibilityInfo';
import { usePricing } from '../../SmartThermostats/hooks/usePricing';

const trackEvent = (trackingId: string) => {
  analyticsService.dispatch(CLICKED_OFFER_EVENT, { offerType: trackingId });
};

const StyledHeading2 = styled(Heading2)`
  margin-bottom: 24px;

  ${mqMediumUp(`
    margin-bottom: 32px;
  `)}
`;

// move this into OffersAndUpgrades.tsx if we want to make offers interchangeable
export const PtzOffers = () => {
  const [pathToZeroOffersDecision] = useDecision(SHOW_PATH_TO_ZERO_OFFERS);
  const [encryptionError, setEncryptionError] = useState<boolean>(false);
  const { isEncrypting, getEncryptedToken } = useEncryptedToken();
  const thermostatsPricing = usePricing(PROMO_CODES.smartThermostatBlackFriday);
  const smartThermostatsEligibility = useEligibilityInfo().eligible;

  const smartThermostatsHidden =
    !pathToZeroOffersDecision?.variables?.[SMART_THERMOSTAT_FF_VARIABLE] ||
    !smartThermostatsEligibility;

  const submitHandler = async () => {
    setEncryptionError(false);

    const encryptedToken = await getEncryptedToken();

    if (encryptedToken) {
      openInNewTab(createKantanOceanSavingSolutionsJourneyUrl(encryptedToken));
    } else {
      setEncryptionError(true);
    }
  };

  return (
    <>
      {pathToZeroOffersDecision.enabled &&
        getIsPTZOffersAvailable(
          pathToZeroOffersDecision.variables,
          smartThermostatsEligibility,
        ) && (
          <>
            <GetSmartMeterCard />
            <Row isNested>
              {!!pathToZeroOffersDecision?.variables?.[
                ENERGY_EXPERT_FF_VARIABLE
              ] && (
                <Col medium={6} key={`offer-energy-experts`} as={DisplayFlex}>
                  <PtzOffer
                    trackingId={NEW_OFFERS_PAGE.ENERGY_EXPERT_ID}
                    imagePath={EnergyExpertsImage}
                    imageAlt="Energy Expert home visit"
                    title="Energy Expert visit just £25"
                    description="Get personalized advice on the home upgrades you could make to save energy and money."
                  >
                    <UnorderedListSection
                      title="What’s included:"
                      list={INCLUDED_LIST}
                    />
                    <Price price="£25" strikeOutText="Usually £100" />
                    <P>
                      View the{' '}
                      <TextLink href={EE_TERMS_LINK} opensInNewWindow>
                        Energy Expert terms
                      </TextLink>
                    </P>

                    <PrimaryCTALink
                      onClick={() => {
                        submitHandler();
                        trackEvent(NEW_OFFERS_PAGE.ENERGY_EXPERT_ID);
                      }}
                      iconRight="off"
                    >
                      {encryptionError
                        ? 'Try again'
                        : isEncrypting
                        ? 'Loading...'
                        : 'Book your home visit'}
                    </PrimaryCTALink>
                    <TextLink
                      style={{ textAlign: 'center' }}
                      href={EE_TERTIARY_LINK}
                      opensInNewWindow
                    >
                      How it works
                    </TextLink>
                  </PtzOffer>
                </Col>
              )}

              {thermostatsPricing && !smartThermostatsHidden && (
                <Col
                  data-testid="offer-thermostats"
                  medium={6}
                  key={`offer-tado`}
                  as={DisplayFlex}
                >
                  <ThermostatsOffer pricing={thermostatsPricing} />
                </Col>
              )}
            </Row>
            <StyledHeading2>More offers and upgrades</StyledHeading2>
          </>
        )}
    </>
  );
};
