import {
  Card,
  Heading4,
  Li,
  P,
  SkeletonCircle,
  SkeletonCTA,
  SkeletonHeading,
  SkeletonText,
  Strong,
  Ul,
} from '@ovotech/nebula';
import React from 'react';
import { useTadoPricingQuery } from '../hooks/use-tado-pricing';
import { OrderType, PaymentType } from '../utils/types';
import {
  AboutYourTadoContentWrapper,
  AboutYourTadoTextWrapper,
  BulletListContainer,
  Divider,
  OrderSummaryCard,
  OrderSummaryWrapper,
  SmartThermostatImageContainer,
  SmartThermostatImageWrapper,
  StyledProductImageCard,
  StyledProductImageWrapper,
  StyledProductTextWrapper,
  StyledProductTitleWrapper,
} from './TadoOrderSummary.styled';
import TadoSmartThermostatImage from '@/src/resources/smart-thermostat.png';
import TadoStarterKitImage from '@/src/resources/tado-starter-kit.png';
import { getQueryParamValue } from '@/src/utils/getQueryParamValue';
import { getBoltonPrice } from '../utils/utils';

export const TadoOrderSummary = () => {
  const promoCode = getQueryParamValue('promoCode');
  const tadoPricing = useTadoPricingQuery(promoCode);

  if (tadoPricing.isLoading) {
    return (
      <Card>
        <SkeletonHeading />
        <SkeletonText lines={3} />
        <SkeletonCircle />
        <SkeletonCTA />
      </Card>
    );
  }

  if (tadoPricing.isError || tadoPricing.data === undefined) {
    return <P>Something went wrong</P>;
  }

  const upfrontBoltonPricing = getBoltonPrice(
    tadoPricing.data,
    PaymentType.FullAmountPayment,
    OrderType.SelfInstall,
  );

  return (
    <OrderSummaryCard>
      <Heading4>Your tado° order</Heading4>
      <OrderSummaryWrapper>
        <StyledProductImageCard>
          <StyledProductImageWrapper src={TadoStarterKitImage} />
        </StyledProductImageCard>
        <StyledProductTitleWrapper>
          <Strong>tado° Wireless Smart Thermostat Starter Kit V3+</Strong>
        </StyledProductTitleWrapper>
        <StyledProductTextWrapper>
          <Strong>Qty: 1</Strong>
          <Strong>£{Number(upfrontBoltonPricing).toFixed(2)}</Strong>
        </StyledProductTextWrapper>
      </OrderSummaryWrapper>
      <Divider margin={4} />
      <AboutYourTadoContentWrapper>
        <AboutYourTadoTextWrapper>
          <Heading4>About your tado°</Heading4>
          <BulletListContainer>
            <Ul>
              <Li>
                You will receive one wireless <Strong>tado°</Strong> smart
                thermostat
              </Li>
              <Li>
                We can currently only support one <Strong>tado°</Strong> in
                OVO's usage insights
              </Li>
            </Ul>
          </BulletListContainer>
        </AboutYourTadoTextWrapper>
        <SmartThermostatImageContainer>
          <SmartThermostatImageWrapper src={TadoSmartThermostatImage} />
        </SmartThermostatImageContainer>
      </AboutYourTadoContentWrapper>
    </OrderSummaryCard>
  );
};
