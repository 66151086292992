import { CTALink, Heading2, Modal, P } from '@ovotech/nebula';
import { useAnalytics } from '@ovotech/ui-tools';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { MappedPowerMoveChallenge } from '../types';
import { formatHighestReward } from '../utils/utils';
import { PATH_TO_ZERO } from '@/src/constants/analytics';
import { mqMobileNav } from '@/src/utils/mediaQuery';
import { ReactComponent as MedalIcon } from '@/src/resources/medal.svg';
import { ReactComponent as StarIcon } from '@/src/resources/star.svg';

const StyledModal = styled(Modal)`
  flex: 1;
  display: flex;
  flex-direction: column;

  ${mqMobileNav(`
    & > div {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  `)}
`;

const StyledContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`;

type Props = {
  isOpen: boolean;
  tipsEnabled: boolean;
  challenge: MappedPowerMoveChallenge | undefined;
  onClose: () => void;
  onShowTips: () => void;
};

export const PowerMoveChallengeSuccessfullySignedUpModal = (props: Props) => {
  const { isOpen, challenge, tipsEnabled, onClose, onShowTips } = props;
  const { dispatch } = useAnalytics();

  useEffect(() => {
    if (isOpen) {
      dispatch({
        name: PATH_TO_ZERO.POWER_MOVE_JOIN_CHALLENGE_SUCCESS_MODAL_VIEWED,
        type: 'view',
        properties: {
          challenge: challenge?.displayName,
        },
      });
    }
    // dispatch should be stable across renders, but sadly it is not which leads to multiple events being dispatched
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <StyledModal
      title=""
      isOpen={isOpen}
      data-testid="power-move-challenge-successfully-signed-up-modal"
      onClose={onClose}
    >
      {!challenge?.isUpcoming ? (
        <>
          <StyledContentContainer>
            <MedalIcon />
            <Heading2>{`Exciting! You’ve joined the ${challenge?.displayName.toLocaleLowerCase()}`}</Heading2>
            <P>Check out our energy top tips to start power moving.</P>
          </StyledContentContainer>
          {tipsEnabled ? (
            <CTALink
              className="show-me-tips"
              iconRight="off"
              onClick={onShowTips}
            >
              Show me tips
            </CTALink>
          ) : (
            <CTALink className="show-me-tips" iconRight="off" onClick={onClose}>
              OK, thanks
            </CTALink>
          )}
        </>
      ) : (
        <>
          <StyledContentContainer>
            <StarIcon />
            <Heading2>You’ve joined this challenge</Heading2>
            <P data-testid="successfully-joined-upcoming-challenge-content">
              From {format(challenge.activeFrom, 'd MMMM')}, you could earn up
              to £{formatHighestReward(challenge.highestMonthlyReward)} a month
              by shifting your energy habits.
            </P>
          </StyledContentContainer>
          <CTALink className="show-me-tips" iconRight="off" onClick={onClose}>
            Close
          </CTALink>
        </>
      )}
    </StyledModal>
  );
};
