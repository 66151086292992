import {
  P,
  Modal,
  Callout,
  Heading2,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from '@ovotech/nebula';
import { useAnalytics } from '@ovotech/ui-tools';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { MappedPowerMoveChallenge } from '../types';
import { usePowerMoveChallengeOptOutMutation } from '@/src/api';
import { PATH_TO_ZERO } from '@/src/constants/analytics';
import { mqMediumDown } from '@/src/utils/mediaQuery';
import { ReactComponent as Icon } from '@/src/resources/loss-complete-icon.svg';

const StyledContentContainer = styled.div`
  flex: 1;
  display: flex;
  padding-bottom: 16px;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: row;

  ${mqMediumDown(`
    flex-direction: column;
  `)};
`;

interface PowerMoveChallengeOptOutModalProps {
  isOpen: boolean;
  challenge: MappedPowerMoveChallenge | null;
  onClose: () => void;
}

export const PowerMoveChallengeOptOutModal = (
  props: PowerMoveChallengeOptOutModalProps,
) => {
  const { isOpen, challenge, onClose } = props;
  const lowerCaseChallengeName =
    challenge?.displayName?.toLocaleLowerCase() ?? '';
  const { dispatch } = useAnalytics();
  const { isError, isLoading, reset, mutateAsync } =
    usePowerMoveChallengeOptOutMutation();

  useEffect(() => {
    if (isOpen && challenge?.displayName) {
      dispatch({
        name: PATH_TO_ZERO.POWER_MOVE_LEAVE_CHALLENGE_VIEWED,
        type: 'view',
        properties: {
          challenge: challenge.displayName,
        },
      });
    }
    // `dispatch` method is not memoized and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, challenge]);

  const onCloseHandler = () => {
    reset();
    onClose();

    dispatch({
      name: PATH_TO_ZERO.POWER_MOVE_CLOSE_PAGE_CLICKED,
      type: 'click',
    });
  };

  const onKeepGoingHandler = () => {
    reset();
    onClose();

    dispatch({
      name: PATH_TO_ZERO.POWER_MOVE_KEEP_GOING_CLICKED,
      type: 'click',
    });
  };

  const onConfirmLeaveChallengeHandler = async () => {
    if (challenge?.versionId) {
      dispatch({
        name: PATH_TO_ZERO.POWER_MOVE_LEAVE_CHALLENGE_CLICKED,
        type: 'click',
        properties: {
          challenge: challenge.displayName,
        },
      });

      try {
        await mutateAsync(challenge?.versionId);
        onClose();
      } catch {
        // do nothing
      }
    }
  };

  return (
    <Modal
      title=""
      isOpen={isOpen}
      data-testid="power-move-challenge-opt-out-modal"
      onClose={onCloseHandler}
    >
      <StyledContentContainer>
        <Icon />
        <Heading2>Leave the {lowerCaseChallengeName}</Heading2>
        <P>
          Just a heads up – you’ll lose any credit you might have earned if you
          leave the challenge now.
        </P>
      </StyledContentContainer>

      <ButtonsContainer>
        <PrimaryCTAButton
          style={{ textAlign: 'center' }}
          disable={isLoading}
          onClick={onKeepGoingHandler}
        >
          I’ll keep going
        </PrimaryCTAButton>
        <SecondaryCTAButton
          disable={isLoading}
          onClick={onConfirmLeaveChallengeHandler}
        >
          {isLoading ? 'Leaving...' : `Leave ${lowerCaseChallengeName}`}
        </SecondaryCTAButton>
      </ButtonsContainer>
      {isError && (
        <Callout style={{ marginTop: 24 }} variant="error">
          <P>Sorry, something went wrong. Please try again.</P>
        </Callout>
      )}
    </Modal>
  );
};
