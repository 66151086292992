import { HomeProfileWidgetPosition } from './HomeProfileWidget.types';

const testIDs = {
  heading: 'home-profile-widget-heading',
  body: 'home-profile-widget-body',
  cta: 'home-profile-widget-cta',
  closeButton: 'home-profile-widget-close-button',
  widgetPosition: (position: HomeProfileWidgetPosition) =>
    `widget-positioned-${position}`,
};

export default testIDs;
