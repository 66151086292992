import { P } from '@ovotech/nebula';
import React from 'react';
import TipSlideshow from '../../../Hub/Components/TipSlideshow/TipSlideshow';
import { CollapsibleLearnMore } from './CollapsibleLearnMore';
import { Fuel } from '@/src/types/Response';
import { Blur } from '@/src/pages/EnergyInsights/components';
import {
  BreakdownInfo,
  electricTipsByCategory,
  gasTipsByCategory,
} from '@/src/pages/EnergyInsights/constants/usage';
import { UseGetTipInteractionsFromLocalStorage } from '@/src/pages/EnergyInsights/hooks/useGetTipInteractionsFromLocalStorage';
import {
  ElectricityUsageCategory,
  GasUsageCategory,
} from '@/src/pages/EnergyInsights/types';

const UsageBreakdownContent = ({
  category,
  fuel,
  breakdownInfo,
  tipInteractions,
}: {
  category: ElectricityUsageCategory | GasUsageCategory;
  consumption: number;
  fuel: Fuel;
  breakdownInfo: BreakdownInfo;
  tipInteractions: UseGetTipInteractionsFromLocalStorage;
}) => {
  const actions =
    fuel === Fuel.ELECTRICITY
      ? electricTipsByCategory[category]
      : gasTipsByCategory[category as GasUsageCategory];

  return (
    <>
      <Blur>
        <P>{breakdownInfo.description}</P>
      </Blur>
      <CollapsibleLearnMore breakdownInfo={breakdownInfo} category={category} />
      {Boolean(actions.length) && (
        <TipSlideshow
          tips={actions}
          category={category}
          tipInteractions={tipInteractions}
        />
      )}
    </>
  );
};

export default UsageBreakdownContent;
