import { FunctionComponent } from 'react';
import { D2VDownloadCard } from './ChargeAnytime/D2VDownloadCard';
import { CheckContactDetails } from './CheckContactDetails/CheckContactDetails';
import { EnergyExpertVisits } from './EnergyExpert/EnergyExpertVisits';
import { HandraiserCard } from './Handraiser/Handraiser';
import { LowChurnCard } from './Handraiser/LowChurnCard';
import { ControlHomePlanBanner } from './HomePlan/ControlHomePlanBanner';
import { HomePlanBundle } from './HomePlan/HomePlanBundle';
import { HomePlanFixedAddonQ1Offer } from './HomePlan/HomePlanFixedAddonQ1Offer';
import { HomePlanFreeForFourMonthsPlusCredit } from './HomePlan/HomePlanFreeForFourMonthsPlusCredit';
import { HomePlanEnergyCreditsPromoFixed } from './HomePlan/HomePlanEnergyCreditsPromoFixed';
import { HomePlanMixedPanel } from './HomePlan/HomePlanMixedPanel';
import { HomePlanWinback } from './HomePlan/HomePlanWinback';
import { HomeRecoverBanner } from './HomeRecover/HomeRecover';
import { FinalStatementOneInLoss } from './Loss/FinalStatement/FinalStatementOneInLoss';
import { FinalStatementOneReady } from './Loss/FinalStatement/FinalStatementOneReady';
import { FinalStatementOneStaying } from './Loss/FinalStatement/FinalStatementOneStaying';
import { FinalStatementReady } from './Loss/FinalStatement/FinalStatementReady';
import { LossCompleteDualLoss } from './Loss/LossComplete/LossCompleteDualLoss';
import { LossCompleteDualLossDifferentDates } from './Loss/LossComplete/LossCompleteDualLossDifferentDates';
import { LossCompletePending } from './Loss/LossComplete/LossCompletePending';
import { LossCompleteSingleFuel } from './Loss/LossComplete/LossCompleteSingleFuel';
import { LossCompleteSingleLoss } from './Loss/LossComplete/LossCompleteSingleLoss';
import { LossPendingDualFuel } from './Loss/LossPending/LossPendingDualFuel';
import { LossPendingSingleFuel } from './Loss/LossPending/LossPendingSingleFuel';
import { LossPendingSingleLoss } from './Loss/LossPending/LossPendingSingleLoss';
import { MoveOutComplete } from './MoveOutComplete';
import { MoveOutInProgress } from './MoveOutInProgress';
import { NextPlan } from './NextPlan';
import { DirectDebitSetup } from './Payments/DirectDebitSetup';
import { HighRiskDDCancellation } from './Payments/HighRiskDDCancellation';
import { PlannedMaintenance } from './Payments/PlannedMaintenance';
import { SVTDirectDebitSetup } from './Payments/SVTDirectDebitSetup';
import { PromoteOnTrustpilot } from './PromoteOnTrustpilot/PromoteOnTrustpilot';
import { FixedPlanRenewalCard } from './Renewal/FixedPlanRenewalCard';
import { Renewal } from './Renewal/Renewal';
import ManageSmartMeterBooking from './SmartMeterBooking/ManageSmartMeterBooking';
import { SmartMeterBookingBookingNowAvailable } from './SmartMeterBooking/SmartMeterBookingBookingNowAvailable';
import { SmartMeterBookingMandate } from './SmartMeterBooking/SmartMeterBookingMandate';
import SmartMeterBookingOffer from './SmartMeterBooking/SmartMeterBookingOffer';
import { SmartMeterBookingRecerts } from './SmartMeterBooking/SmartMeterBookingRecerts';
import { SmartMeterBookingRenter } from './SmartMeterBooking/SmartMeterBookingRenter';
import { SmartMeterNoReadingNecessary } from './SmartMeterBooking/SmartMeterNoReadingNecessary';
import { SolarPanelsCard } from './Solar/SolarPanels';
import { SVTFixedSmartEligible } from './SwitchPlan/SVTFixedSmartEligible';
import { HomePlanChangeOfTariff } from '@/src/components/ActionCentre/Banners/HomePlan/HomePlanChangeOfTariff';
import { HomePlanSmartInstall } from '@/src/components/ActionCentre/Banners/HomePlan/HomePlanSmartInstall';
import { DirectDebitReduction } from '@/src/components/ActionCentre/Banners/Payments/DirectDebitReduction';
import {
  EnergyTrackerFirstTimeBanner,
  EnergyTrackerReturningBanner,
} from '@/src/components/ActionCentre/Banners/EnergyTracker/EnergyTrackerBanner';
import { GreenerEnergyBanner } from '@/src/components/ActionCentre/Banners/GreenerEnergy/GreenerEnergyBanner';
import { ActionMetaDataKey } from '@/src/types/NextBestAction';

type MetaData = Partial<Record<ActionMetaDataKey, string>>;
import { HomePlanEnergyCreditsPromoSVT } from '@/src/components/ActionCentre/Banners/HomePlan/HomePlanEnergyCreditsPromoSVT';

export interface ActionCentreBannerProps {
  'data-testid'?: string;
  utmSource?: string;
  onClick: () => void;
  dismiss: () => void;
  metaData?: MetaData;
}

export type ActionCentreBanner = FunctionComponent<ActionCentreBannerProps>;

export type BannerId = keyof typeof banners;
export const banners = {
  d2VDownloadCard: D2VDownloadCard,
  checkContactDetails: CheckContactDetails,
  svtDirectDebitSetup: SVTDirectDebitSetup,
  highRiskDDCancellation: HighRiskDDCancellation,
  moveOutComplete: MoveOutComplete,
  moveOutInProgress: MoveOutInProgress,
  energyExpertVisits: EnergyExpertVisits,
  finalStatementReady: FinalStatementReady,
  finalStatementOneReady: FinalStatementOneReady,
  finalStatementOneStaying: FinalStatementOneStaying,
  finalStatementOneInLoss: FinalStatementOneInLoss,
  lossCompleteSingleFuel: LossCompleteSingleFuel,
  lossCompleteSingleLoss: LossCompleteSingleLoss,
  lossCompleteDualLoss: LossCompleteDualLoss,
  lossCompleteDualLossDifferentDates: LossCompleteDualLossDifferentDates,
  lossCompletePending: LossCompletePending,
  lossPendingSingleLoss: LossPendingSingleLoss,
  lossPendingSingleFuel: LossPendingSingleFuel,
  lossPendingDualFuel: LossPendingDualFuel,
  renewal: Renewal,
  nextPlan: NextPlan,
  promoteOnTrustPilot: PromoteOnTrustpilot,
  smartMeterManageBooking: ManageSmartMeterBooking,
  smartMeterBookingOffer: SmartMeterBookingOffer,
  smartMeterBookingRenter: SmartMeterBookingRenter,
  smartMeterBookingMandate: SmartMeterBookingMandate,
  smartMeterBookingRecerts: SmartMeterBookingRecerts,
  smartMeterBookingBookingNowAvailable: SmartMeterBookingBookingNowAvailable,
  homeplan: ControlHomePlanBanner,
  homeplanBundle: HomePlanBundle,
  greenerEnergy: GreenerEnergyBanner,
  greenlightNew: EnergyTrackerFirstTimeBanner,
  greenlightViewAgain: EnergyTrackerReturningBanner,
  plannedMaintenance: PlannedMaintenance,
  noReadingNecessary: SmartMeterNoReadingNecessary,
  directDebitSetup: DirectDebitSetup,
  homePlanCot: HomePlanChangeOfTariff,
  homePlanSmartInstall: HomePlanSmartInstall,
  homePlanMixedPanel: HomePlanMixedPanel,
  homePlanWinback: HomePlanWinback,
  handraiserTest: HandraiserCard,
  handraiserControl: HandraiserCard,
  handraisersOnDemand: LowChurnCard,
  handraisersDirectDebit: LowChurnCard,
  solarPanels: SolarPanelsCard,
  directDebitReduction: DirectDebitReduction,
  renewalWindow: FixedPlanRenewalCard,
  homeRecover: HomeRecoverBanner,
  homePlanFixedTariff: HomePlanFixedAddonQ1Offer,
  homePlanEnergyCreditsPromoSVT: HomePlanEnergyCreditsPromoSVT,
  homePlanEnergyCreditsPromoFixed: HomePlanEnergyCreditsPromoFixed,
  homePlanFreeForFourMonthsPlusCredit: HomePlanFreeForFourMonthsPlusCredit,
  svtFixedSmartEligible: SVTFixedSmartEligible,
};
