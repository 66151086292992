import { Card, CTALink, P, Heading1 } from '@ovotech/nebula';
import { useAccountContext, useAnalytics } from '@ovotech/ui-tools';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { PowerMoveTip } from './PowerMoveTip';
import { powerTipsContent } from './PowerTipsContent';
import { PATH_TO_ZERO } from '@/src/constants/analytics';
import { EXTERNAL_ROUTE_GREENLIGHT_BILL_BREAKDOWN } from '@/src/constants/routes';

const StyledHeading1 = styled(Heading1)`
  ${({ theme: { colors } }) => `
    font-size: 2rem;
    border-bottom: solid ${colors.borderMuted};
    padding-bottom: 2rem;
    margin: 0;
  `}
`;

const StyledP = styled(P)`
  font-weight: bold;
  align-self: baseline;
  margin: 0;
  padding: 24px 0;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const PowerMoveTips = forwardRef<HTMLDivElement>((_props, ref) => {
  const { accountId } = useAccountContext();
  const { dispatch } = useAnalytics();

  const onClickHandler = () => {
    dispatch({
      name: PATH_TO_ZERO.POWER_MOVE_MY_ENERGY_USE_CLICKED,
      type: 'click',
    });
  };

  return (
    <Card ref={ref}>
      <StyledHeading1>Tips on shifting energy use</StyledHeading1>
      <StyledContainer>
        {powerTipsContent.map(tip => (
          <PowerMoveTip
            key={tip.key}
            imgSrc={tip.imgSrc}
            heading={tip.heading}
            body={tip.body}
          />
        ))}
      </StyledContainer>
      <StyledP>
        Please don’t turn off any essential electricity. Power Move is about
        developing greener energy habits - not going without.
      </StyledP>
      <CTALink
        variant="secondary"
        href={`${EXTERNAL_ROUTE_GREENLIGHT_BILL_BREAKDOWN}?accountId=${accountId}`}
        iconRight="off"
        data-testid="power-tips-energy-use-button"
        onClick={onClickHandler}
      >
        Your energy use
      </CTALink>
    </Card>
  );
});
