import { Icon, P, SecondaryCTAButton, Strong } from '@ovotech/nebula';

import React, { useState } from 'react';
import { HomeProfilePromptPosition } from '../../hooks/useHomeProfilePromptPosition';
import { energyInsightsStrings as strings } from '../../strings';
import { HomeProfileData } from '../../types';

import { HomeProfileModal } from '../HomeProfileModal/HomeProfileModal';
import {
  FlexedContainer,
  HomeProfilePromptDismiss,
  StyledHomeProfileContainer,
} from './HomeProfilePrompt.styled';
import { homeProfilePromptTestIDs as testIDs } from './testIDs';

export type HomeProfileProps = {
  position: HomeProfilePromptPosition;
  homeProfile: HomeProfileData | null;
};

export const HomeProfilePrompt = ({
  updateHomeProfileOnClick,
  dismissHomeProfileOnClick,
  homeProfile,
  position,
}: {
  updateHomeProfileOnClick: () => void;
  dismissHomeProfileOnClick?: () => void;
} & HomeProfileProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <StyledHomeProfileContainer
        onKeyDown={e => {
          if (e.key === 'Escape') {
            closeModal();
          }
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        tabIndex={-1}
        data-testid={`home-profile-prompt-${position}`}
      >
        <FlexedContainer>
          <Strong>
            {homeProfile
              ? 'Making changes or moving?'
              : 'Tell us about your home'}
          </Strong>

          <P>
            {homeProfile
              ? `If you’ve moved, or made big changes to your home lately, let us know, as this’ll help keep ${strings.productDisplayName} as accurate as possible.`
              : `This’ll help make ${strings.productDisplayName} as accurate as possible.`}
          </P>
        </FlexedContainer>
        <SecondaryCTAButton
          style={{ height: 'fit-content', flex: 'none' }}
          data-testid={testIDs.cta}
          onClick={() => {
            updateHomeProfileOnClick();
            openModal();
          }}
          iconRight="chevron-right"
        >
          Update home profile
        </SecondaryCTAButton>
        {position === 'bottom' ? null : (
          <HomeProfilePromptDismiss
            type="button"
            data-testid={testIDs.dismiss}
            onClick={dismissHomeProfileOnClick}
            aria-label="Close modal"
          >
            <Icon name="cross" role="tab" />
          </HomeProfilePromptDismiss>
        )}
      </StyledHomeProfileContainer>

      {/* THE MODAL */}
      {modalOpen ? (
        <HomeProfileModal homeProfile={homeProfile} onClose={closeModal} />
      ) : null}
    </>
  );
};
