import { useAccountContext } from '@ovotech/ui-tools';
import { useEffect, useMemo } from 'react';
import { UsePowerMoveEligibilityReturn } from '../types/powerMoveEligibility';
import { useAccountMetersQuery } from '@/src/api';
import { Fuel, ReadingFrequency } from '@/src/types/Response';
import AnalyticsService from '@/src/services/analyticsService';

export const usePowerMoveEligibility = (): UsePowerMoveEligibilityReturn => {
  const { accountId } = useAccountContext();
  const meters = useAccountMetersQuery(accountId);

  const elecMeters =
    meters.data?.meters.filter(meter => meter.fuelType === Fuel.ELECTRICITY) ??
    [];
  const gasMeters =
    meters.data?.meters.filter(meter => meter.fuelType === Fuel.GAS) ?? [];

  const hasElecSmartMeter =
    Boolean(elecMeters.length) && elecMeters.every(meter => meter.isSmart);
  const hasGasMeterOnly = elecMeters.length === 0 && Boolean(gasMeters.length);
  const hasElecHalfHourlySmartMeterReadsEnabled =
    (Boolean(elecMeters.length) &&
      elecMeters.every(
        meter => meter.readingFrequency === ReadingFrequency.HALFHOURLY,
      )) ||
    false;

  const hasValidFrequency =
    (Boolean(elecMeters.length) &&
      elecMeters.every(meter => meter.readingFrequency)) ||
    false;

  const canUpdateMeterReadFrequency =
    (Boolean(elecMeters.length) &&
      elecMeters.every(meter => meter.deviceId && meter.brand)) ||
    false;

  useEffect(() => {
    if (!meters.isLoading)
      AnalyticsService.register({
        isSmart: hasElecSmartMeter,
        isHalfHourly: hasElecHalfHourlySmartMeterReadsEnabled,
      });
  }, [
    hasElecSmartMeter,
    hasElecHalfHourlySmartMeterReadsEnabled,
    meters.isLoading,
  ]);

  const updatingElecSmartMeterFrequencyToHalfHourly =
    elecMeters.some(
      meter =>
        meter.readingFrequencyChangeInProgress === true &&
        meter.readingFrequencyChangingTo === ReadingFrequency.HALFHOURLY,
    ) || false;

  const eligibilityValues = useMemo(
    () => ({
      isLoading: meters.isFetching,
      isEligible: hasElecSmartMeter && hasElecHalfHourlySmartMeterReadsEnabled,
      isError: meters.isError,
      isMetersApiError: meters.isError,
      hasElecSmartMeter,
      hasElecHalfHourlySmartMeterReadsEnabled,
      updatingElecSmartMeterFrequencyToHalfHourly,
      hasGasMeterOnly,
      canUpdateMeterReadFrequency,
      hasValidFrequency,
    }),
    [
      hasElecHalfHourlySmartMeterReadsEnabled,
      hasElecSmartMeter,
      meters.isFetching,
      meters.isError,
      updatingElecSmartMeterFrequencyToHalfHourly,
      hasGasMeterOnly,
      canUpdateMeterReadFrequency,
      hasValidFrequency,
    ],
  );

  return eligibilityValues;
};
