import { PLAN_DETAILS_MAP, PlanFamily } from '../planConstants';
import { AvailablePlan, PaymentMethod } from '../types';
import { PossibleAvailablePlans } from './generateMockPlan';

type MockPlanDetails = Omit<AvailablePlan, 'tariffs' | 'exitFeesTotal'>;

const variableFeatures = [{ text: 'No exit fee' }];
const variablePlanDetails = (
  name: PlanFamily,
  paymentMethod: PaymentMethod
): MockPlanDetails => ({
  ...PLAN_DETAILS_MAP[name],
  type: 'variable',
  paymentMethod,
  name,
  subtitle: name,
  features: [...PLAN_DETAILS_MAP[name].features, ...variableFeatures],
  estimatedAnnualCost: '2234.01',
  estimatedMonthlyCost: '186',
});

const fixedFeatures = [{ text: '£30 exit fee per fuel' }];
const fixedPlanDetails = (
  name: PlanFamily,
  lengthInMonths: number
): MockPlanDetails => ({
  ...PLAN_DETAILS_MAP[name],
  lengthInMonths,
  name: name,
  subtitle: name,
  features: [...PLAN_DETAILS_MAP[name].features, ...fixedFeatures],
  type: 'fixed',
  paymentMethod: 'Direct Debit',
  estimatedAnnualCost: '2234.01',
  estimatedMonthlyCost: '186',
});

export const mockAvailablePlans: {
  [key in PossibleAvailablePlans]: MockPlanDetails;
} = {
  'Simpler Energy DD': variablePlanDetails(
    PlanFamily.simplerEnergy,
    'Direct Debit'
  ),
  'Simpler Energy OD': variablePlanDetails(
    PlanFamily.simplerEnergy,
    'On Demand'
  ),
  'Three Year Fixed': fixedPlanDetails(PlanFamily.threeYearFixed, 36),
  'Two Year Fixed': fixedPlanDetails(PlanFamily.twoYearFixed, 24),
  'Two Year Fix And Protect': fixedPlanDetails(
    PlanFamily.twoYearFixAndProtect,
    24
  ),
  'One Fixed Boiler Cover': fixedPlanDetails(
    PlanFamily.oneYearFixedBoilerCover,
    12
  ),
  'One Year Fixed': fixedPlanDetails(PlanFamily.oneYearFixed, 12),
  'Better Energy': fixedPlanDetails(PlanFamily.betterEnergy, 12),
  Drive: fixedPlanDetails(PlanFamily.drive, 12),
  'Member Special': fixedPlanDetails(PlanFamily.memberSpecial, 12),
  'Stay and Save': fixedPlanDetails(PlanFamily.stayAndSave, 12),
  '1 Year Fixed + Greener Energy': fixedPlanDetails(
    PlanFamily.oneYearFixedGreenerEnergy,
    12
  ),
  '1 Year Fixed Loyalty': fixedPlanDetails(PlanFamily.oneYearFixedLoyalty, 12),
  '2 Year Fixed + Heating Control': fixedPlanDetails(
    PlanFamily.twoYearFixedAndHeatingControl,
    24
  ),
  '1 Year Fixed Go Smart': fixedPlanDetails(PlanFamily.oneYearFixedGoSmart, 12),
  '1 Year Fixed + Greener Electricity': fixedPlanDetails(
    PlanFamily.oneYearFixedGreenerElectricity,
    12
  ),
};
