import { Card } from '@ovotech/nebula';
import styled from 'styled-components';
import { mqMediumDown, mqSmallDown } from '@/src/utils/mediaQuery';

export const StyledHomeProfileContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: ${({ theme: { space } }) => space[4]};

  ${({ theme: { space } }) => `
    ${mqMediumDown(` 
      flex-direction: column;
      text-align: center;
      gap: ${space[2]};
      
      img {
        display: block;
      }
      `)}
    `}
`;

export const FlexedContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  ${({ theme: { space } }) => `
    padding-right: ${space[8]}
  `}

  p {
    font-size: 1.4rem;
  }

  ${mqMediumDown(`
    width: 100%;
  `)}
`;

export const HomeProfilePromptDismiss = styled.button`
  width: 36px;
  height: 36px;
  position: absolute;
  top: -32px;
  right: -32px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border: 0;
  background: none;

  ${mqSmallDown(`
    top: -16px;
    right: -16px;
  `)}
`;

export const PlainCard = styled(Card)`
  border: none;
  box-shadow: none;
  padding: 32px 12px;

  ${mqSmallDown(`
  overflow: hidden;
  `)}
`;
