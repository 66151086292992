import { TextLink } from '@ovotech/nebula';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { StyledSection, Title } from './UsageOvoGreenlightLink.styled';
import { CLICKED_OVO_GREENLIGHT_FROM_USAGE } from '@/src/constants/analytics';
import { EXTERNAL_ROUTE_GREENLIGHT } from '@/src/constants/routes';
import { State as ReduxState } from '@/src/types/State';

export const UsageOvoGreenlightLink = () => {
  const theme = useTheme();

  //TODO: see https://github.com/ovotech/orion-ui/issues/2905
  const accountId = useSelector(
    (state: ReduxState) => state.user.selectedAccountId,
  );

  const ovoGreenlightUrl = `${EXTERNAL_ROUTE_GREENLIGHT}?accountId=${accountId}`;
  // Class names to allow us to target these elements from the web Optimizely client
  return (
    <StyledSection>
      <Title className="usage-greenlight-title">Want to know</Title>
      <TextLink
        style={{ fontSize: theme.fontSizes[2] }}
        href={ovoGreenlightUrl}
        data-event-name={CLICKED_OVO_GREENLIGHT_FROM_USAGE}
        className="usage-greenlight-link"
      >
        where your energy’s going?
      </TextLink>
    </StyledSection>
  );
};
