import { Col, Row } from '@ovotech/nebula';
import React from 'react';

interface Props {
  includeRow?: boolean;
  spanSmall?: number;
  spanMedium?: number;
  spanLarge?: number;
  span?: number;
  children: React.ReactNode;
}

const defaultProps: Props = {
  includeRow: true,
  spanSmall: 12,
  spanMedium: 12,
  spanLarge: 12,
  span: 12,
  children: null,
};

const AddRowComponent = ({
  includeRow,
  children,
}: {
  includeRow: boolean;
  children: JSX.Element;
}) => (includeRow ? <Row isNested={true}>{children}</Row> : children);

export const GridContainer = ({
  includeRow = true,
  spanSmall,
  spanMedium,
  spanLarge,
  span,
  children,
} = defaultProps) => (
  <AddRowComponent includeRow={includeRow}>
    <Col span={span} small={spanSmall} medium={spanMedium} large={spanLarge}>
      {children}
    </Col>
  </AddRowComponent>
);
