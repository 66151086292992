import { useAccountContext } from '@ovotech/ui-tools';
import { useAccountMetersQuery } from '@/src/api';
import { Fuel } from '@/src/types/Response';

export const useSmartMeter = () => {
  const { accountId } = useAccountContext();
  const { data: metersData, isLoading } = useAccountMetersQuery(accountId);
  const meters = metersData?.meters;

  const elecMeters =
    metersData?.meters.filter(meter => meter.fuelType === Fuel.ELECTRICITY) ??
    [];
  const hasElecSmartMeter =
    !!elecMeters?.length && elecMeters.every(meter => meter.isSmart);

  const hasSmartMeter =
    !!meters?.length && meters.every(meter => meter.isSmart);

  return {
    isLoading,
    hasElecSmartMeter,
    hasSmartMeter,
  };
};
