import {
  AnyUsageCategory,
  ElectricityUsageCategory,
  GasUsageCategory,
} from '../types';
import { Fuel } from '@/src/types/Response';
import { gasTipsByCategory } from '@/src/pages/EnergyInsights/constants/usage';
import {
  TipContent,
  TipsByCategory,
  electricTipsByCategory,
} from '@/src/pages/EnergyInsights/utils/tips';

export const pickRandomActions = (
  tips:
    | TipsByCategory<ElectricityUsageCategory>
    | TipsByCategory<GasUsageCategory>,
  amountToPick: number,
) => {
  const tipsFlatArr = Object.values(tips).flat();
  return tipsFlatArr.sort(() => 0.5 - Math.random()).slice(0, amountToPick);
};

export const shuffle = (xs: any[]) => xs.sort(() => 0.5 - Math.random());

export const mapCategoriesToActions = (
  categories: AnyUsageCategory[],
  fuelType: Fuel,
): TipContent[] => {
  const tips = (
    fuelType === Fuel.ELECTRICITY ? electricTipsByCategory : gasTipsByCategory
  ) as TipsByCategory<AnyUsageCategory>;

  return categories
    .map(category => tips[category])
    .flat()
    .filter(Boolean);
};
