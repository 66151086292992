import { TextLink } from '@ovotech/nebula';
import { Sandwich } from '@ovotech/ui-tools';
import React, { useContext } from 'react';
import MovingHomeLink from '../Links/MovingHomeLink';
import NavLink from '../Links/NavLink';
import { useContractsQuery } from '@/src/api';
import AnalyticsWrapper from '@/src/components/AnalyticsWrapper/AnalyticsWrapper';
import { StyledNavLink } from '@/src/components/Header/Header.styled';

import {
  CLICKED_USAGE_IN_NAV,
  MENU,
  SMB_NAV_BOOKING_CLICKED,
  SMB_NAV_BOOKING_VIEWED,
} from '@/src/constants/analytics';
import { SMART_BOOKING_URL } from '@/src/constants/endpoints';
import { SHOW_POWER_MOVE, SHOW_RENEWALS } from '@/src/constants/feature-flags';
import {
  ROUTE_BILLING_HISTORY,
  ROUTE_HOME,
  ROUTE_METER_READINGS,
  ROUTE_OFFERS,
  ROUTE_PAYMENTS,
  ROUTE_PLAN,
  ROUTE_POWER_MOVE,
  ROUTE_RENEWAL,
  ROUTE_USAGE,
} from '@/src/constants/routes';
import { sandwichIds } from '@/src/constants/sandwich-ids';
import { hasRenewingContracts } from '@/src/pages/Renewal/selectors';
import {
  OptimizelyContext,
  OptimizelyExperiment,
  OptimizelyVariation,
  useFeature,
} from '@/src/utils/optimizely';
import { NewNavItemBadge } from '@/src/components/Badges/NewNavItemBadge';

type Props = {
  hasGreenerEnergy: boolean;
  path: string;
  accountId: string;
  smartEligible: boolean;
  hasSmartMeterBooking: boolean | undefined;
};

const InLifeSideNavigationLinks = ({
  path,
  hasGreenerEnergy,
  accountId,
  smartEligible,
  hasSmartMeterBooking,
}: Props) => {
  const { optimizely } = useContext(OptimizelyContext);
  const [showPowerMove] = useFeature(SHOW_POWER_MOVE);
  const [showRenewals] = useFeature(SHOW_RENEWALS);

  const contractQuery = useContractsQuery(accountId);
  const isInRenewal =
    contractQuery.status === 'success' &&
    hasRenewingContracts(contractQuery.data);

  return (
    <ul>
      <Sandwich id={sandwichIds.sideNavigation.home}>
        <li>
          <NavLink path={path} route={ROUTE_HOME} label="Home" />
        </li>
      </Sandwich>
      <Sandwich id={sandwichIds.sideNavigation.usage}>
        <li>
          <NavLink
            data-event-name={CLICKED_USAGE_IN_NAV}
            data-testid="usage-link"
            path={path}
            route={ROUTE_USAGE}
            label="Usage"
          />
        </li>
      </Sandwich>
      <Sandwich id={sandwichIds.sideNavigation.getSmartMeter}>
        {/* @ts-ignore react-18 children props */}
        {smartEligible && !hasSmartMeterBooking && (
          <OptimizelyExperiment experiment="smart_book_menu">
            <OptimizelyVariation variation="original"></OptimizelyVariation>
            <OptimizelyVariation variation="booking_in_side_nav">
              <li>
                <AnalyticsWrapper name={SMB_NAV_BOOKING_VIEWED}>
                  <StyledNavLink
                    as={TextLink}
                    href={`${SMART_BOOKING_URL}?accountId=${accountId}&utm_source=menu&utm_medium=MyOVO&utm_campaign=smart-meter-booking`}
                    opensInNewWindow={false}
                    data-testid="smb-side-nav"
                    data-event-name={SMB_NAV_BOOKING_CLICKED}
                  >
                    Get a smart meter
                  </StyledNavLink>
                </AnalyticsWrapper>
              </li>
            </OptimizelyVariation>
          </OptimizelyExperiment>
        )}
      </Sandwich>
      <Sandwich id={sandwichIds.sideNavigation.meterReadings} props={{ path }}>
        <li>
          <NavLink
            data-event-name={MENU.METER_READINGS}
            path={path}
            route={`${ROUTE_METER_READINGS}`}
            label="Meter readings"
            data-testid="meter-readings-link"
          />
        </li>
      </Sandwich>
      <Sandwich id={sandwichIds.sideNavigation.billing}>
        <li>
          <NavLink
            path={path}
            route={`${ROUTE_BILLING_HISTORY}`}
            label="Billing history"
            data-testid="billing-history-link"
            data-event-name={MENU.BILLING_HISTORY}
          />
        </li>
      </Sandwich>
      <Sandwich id={sandwichIds.sideNavigation.payments}>
        <li>
          <NavLink
            data-event-name={MENU.PAYMENTS}
            path={path}
            route={ROUTE_PAYMENTS}
            label="Payments"
          />
        </li>
      </Sandwich>
      <Sandwich
        id={sandwichIds.sideNavigation.plan}
        props={{ path, hasGreenerEnergy, accountId }}
      >
        <li>
          <NavLink
            data-event-name={MENU.PLAN_DETAILS}
            path={path}
            route={ROUTE_PLAN}
            label="Plan"
            data-testid="plan-link"
          />
        </li>
      </Sandwich>
      <Sandwich id={sandwichIds.sideNavigation.offers}>
        <li>
          <NavLink
            data-event-name={MENU.OFFERS}
            path={path}
            route={ROUTE_OFFERS}
            label="Offers"
          />
        </li>
      </Sandwich>

      {showPowerMove && (
        <Sandwich id={sandwichIds.sideNavigation.powerMove}>
          <li>
            <NavLink
              data-event-name={MENU.POWER_MOVE}
              path={path}
              route={ROUTE_POWER_MOVE}
              label="Power Move"
              onClick={() => {
                optimizely?.track('navigate_to_power_move_page_clicked');
              }}
              badge={<NewNavItemBadge />}
            />
          </li>
        </Sandwich>
      )}

      <Sandwich id={sandwichIds.sideNavigation.movingHome}>
        <li>
          <MovingHomeLink path={path} />
        </li>
      </Sandwich>
      {showRenewals && isInRenewal && (
        <Sandwich id={sandwichIds.sideNavigation.renewPlan}>
          <li>
            <NavLink
              path={path}
              route={`${ROUTE_RENEWAL}?utm_source=renew-your-plan&utm_medium=MyOVO`}
              label="Renew your plan"
              data-testid="renew-link"
            />
          </li>
        </Sandwich>
      )}
    </ul>
  );
};

const SideNavigationLinks = (props: Props) => (
  <InLifeSideNavigationLinks {...props} />
);

export default SideNavigationLinks;
