import { Card, Theme } from '@ovotech/nebula';
import styled from 'styled-components';
import { mqLargeDown, mqSmallDown } from '@/src/utils/mediaQuery';

export const OrderSummaryCard = styled(Card)`
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

export const OrderSummaryWrapper = styled.div`
  display: flex;
  gap: 30px;
  flex-shrink: 2;
  margin-top: 50px;
  ${mqLargeDown(`
    display: block;
`)}
`;

export const StyledProductImageCard = styled.div`
  ${({ theme: { space } }) => `
    margin: ${space[2]};
`}
`;

export const StyledProductImageWrapper = styled.img`
  max-width: 160px;
  max-height: 80px;
`;

export const StyledProductTitleWrapper = styled.div`
  ${({ theme: { space } }) => `
    margin: ${space[2]};
  `}
  max-width: 325px;
`;

export const StyledProductTextWrapper = styled.div`
  ${({ theme: { space } }) => `
    margin: ${space[2]};
  `}
  display: inline-flex;
  gap: 30px;
  min-width: 135px;
`;

export const Divider = styled.div<{
  margin: keyof Theme['space'];
}>`
  background-color: #d1d6de;
  height: 1px;
  border: 0;
  ${({ margin, theme: { space } }) => `
    margin-bottom: ${space[margin]};
    margin-top: ${space[margin]};
  `}
`;

export const BulletListContainer = styled.div`
  max-width: 90%;
  ${mqSmallDown(`
    margin-top: 15px;
`)}
`;

export const SmartThermostatImageWrapper = styled.img`
  width: auto;
  height: auto;
`;

export const SmartThermostatImageContainer = styled.div`
  display: flex;
  align-self: center;
  ${mqSmallDown(`
    position: absolute;
    top: -15px;
    left: 150px;
    width: 55px;
    height: 55px;
`)}
`;

export const AboutYourTadoContentWrapper = styled.div`
  ${({ theme: { space } }) => `
     margin-top: ${space[8]};
  `}
  display: flex;
  position: relative;
  ${mqSmallDown(`
    display: flex;
`)}
`;

export const AboutYourTadoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
