import React from 'react';
import { Card, CTAButton, Heading4, P } from '@ovotech/nebula';
import styled from 'styled-components';
import { mqMediumDown } from '@/src/utils/mediaQuery';
import {
  useAccountMeterReadFrequencyMutation,
  useAccountMetersQuery,
} from '@/src/api';
import { ReadingFrequency } from '@/src/types/Response';
import { AnalyticsClick, useAccountContext } from '@ovotech/ui-tools';
import { isHalfHourlySmartMeterReadsEnabled } from '@/src/utils/meters';
import { EV } from '@/src/constants/analytics';
import { ReturnToHomePage } from '@/src/components/ReturnToHomePage/ReturnToHomePage';
import { SkeletonCard } from '@/src/components/SkeletonLoading/SkeletonCard';

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  ${mqMediumDown(`
    flex-direction: column-reverse;
  `)};
`;

const CardWrapper = styled(Card)`
  padding: 0;
`;

export const SmartMeterReadFrequency = () => {
  const { accountId } = useAccountContext();
  const halfHourlyOptInMutation = useAccountMeterReadFrequencyMutation(
    accountId ?? '',
  );
  const meters = useAccountMetersQuery(accountId);
  const hasElecHalfHourlySmartMeterReadsEnabled =
    isHalfHourlySmartMeterReadsEnabled(meters.data?.meters ?? []);

  const optIn = () => {
    if (
      !hasElecHalfHourlySmartMeterReadsEnabled &&
      !halfHourlyOptInMutation.isLoading
    ) {
      halfHourlyOptInMutation.mutate({
        readingFrequency: ReadingFrequency.HALFHOURLY,
      });
    }
  };

  if (halfHourlyOptInMutation.isError) {
    return (
      <CardWrapper data-testid={'success-card'}>
        <Heading4>Sorry, we couldn’t switch to half-hourly.</Heading4>
        <P>
          Our team are aware of the problem, and are working to fix it as soon
          as possible.
        </P>
        <P>Please try again or check back soon. </P>
        <AnalyticsClick
          name={
            EV.ELIGIBILITY
              .EVS_OVO_CHARGE_ANYTIME_OUTCOME_INELIGIBLE_SWITCH_TO_HALF_HOURLY_READS_CLICKED
          }
        >
          <CTAButton
            data-event-name={
              'EVS - EVS OVO Charge Anytime D2V Outcome Ineligible Switch to half hourly reads clicked'
            }
            onClick={optIn}
            iconRight="chevron-right"
          >
            Try again
          </CTAButton>
        </AnalyticsClick>
      </CardWrapper>
    );
  }

  if (halfHourlyOptInMutation.isLoading) {
    return <SkeletonCard data-testid={'hh-loader'} />;
  }

  if (halfHourlyOptInMutation.isSuccess) {
    return (
      <CardWrapper data-testid={'success-card'}>
        <Heading4>Success! You’ve switched to half-hourly</Heading4>
        <P>
          We’ll start receiving half-hour readings within 24 hours, but it could
          be sooner.
        </P>
        <P>
          Once we do, you might be able to get Charge Anytime. So check back
          soon!
        </P>
        <ActionsContainer>
          <ReturnToHomePage />
        </ActionsContainer>
      </CardWrapper>
    );
  }

  return (
    <CardWrapper data-testid={'opt-in-card'}>
      <Heading4>Send us half-hourly readings</Heading4>
      <P>
        Charge Anytime needs a smart meter that sends half-hourly readings to
        connect your EV or charger to your OVO account, and the grid. If you
        don’t already have an electricity smart meter, we’ll install one for
        free.
      </P>
      <AnalyticsClick
        name={
          EV.ELIGIBILITY
            .EVS_OVO_CHARGE_ANYTIME_OUTCOME_INELIGIBLE_SWITCH_TO_HALF_HOURLY_READS_CLICKED
        }
      >
        <CTAButton
          data-event-name={
            'EVS - EVS OVO Charge Anytime D2V Outcome Ineligible Switch to half hourly reads clicked'
          }
          onClick={optIn}
          iconRight="chevron-right"
        >
          Switch to half-hourly
        </CTAButton>
      </AnalyticsClick>
    </CardWrapper>
  );
};
