import { parse } from 'date-fns';
import React from 'react';
import { useWeeklyBreakdown } from '../../../../useWeeklyBreakdown';
import { SimilarHomes } from '../../../SimilarHomes';
import WeekNavigation from '../../../WeeklyNavigation';
import { FuelBreakdown } from '../FuelBreakdown/FuelBreakdown';
import { BreakdownScreenResponse, Fuel } from '@/src/types/Response';

interface Props extends BreakdownScreenResponse {
  fuel: Fuel;
}

export const FuelTabContent = (props: Props) => {
  const { breakdown, fuel, similarHomes } = props;

  const {
    currentBreakdown,
    lastWeekBreakdown,
    backDisabled,
    forwardDisabled,
    backWeek,
    forwardWeek,
  } = useWeeklyBreakdown(breakdown);

  return currentBreakdown ? (
    <>
      <WeekNavigation
        fromDate={parse(currentBreakdown.fromDate)}
        backDisabled={backDisabled}
        forwardDisabled={forwardDisabled}
        forward={forwardWeek}
        backward={backWeek}
      />
      <SimilarHomes
        fuel={fuel}
        fromDate={parse(currentBreakdown.fromDate)}
        similarHomes={similarHomes}
      />
      <FuelBreakdown
        {...props}
        breakdownWeek={currentBreakdown}
        lastWeekBreakdown={lastWeekBreakdown}
      />
    </>
  ) : null;
};
