import { Heading3, Icon, Modal, P } from '@ovotech/nebula';
import { AnalyticsClick, AnalyticsView } from '@ovotech/ui-tools';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Card, SkinnyModal } from '../GreenerElectricitySignup.styled';
import {
  GREENER_ELECTRICITY_SIGNUP_TREE_PLANTING_CARD,
  GREENER_ELECTRICITY_SIGNUP_TREE_PLANTING_CARD_MODAL,
} from '@/src/constants/analytics-subjects';
import { mqLargeDown } from '@/src/utils/mediaQuery';
import smallEnergyTechnology from '@/src/resources/small-energy-technology-2.png';

const FixedHeightCard = styled(Card)`
  height: 362px;

  ${mqLargeDown(`
    height: 310px;
  `)}
`;

const ClippedContent = styled.div`
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  justify-content: flex-end;
  clip-path: polygon(calc(100% - 30px) 25%, 100% 59%, 100% 100%, 0 100%, 0 44%);
  ${({ theme: { colors, space } }) => `
    padding: ${space[4]};
    background-color: ${colors.canvas};
  `};
`;

const CarbonNeutralBackgroundImg = styled.div`
  width: 100%;
  height: 70%;
  background-repeat: no-repeat;
  background-image: url(${smallEnergyTechnology});
  background-position: 100% 80%;
  background-size: cover;
`;

const Wrapper = styled.div`
  position: absolute;
  top: calc(50%);
  width: 90%;
`;

const LinkTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  &:hover {
    cursor: pointer;
  }

  ${({ theme: { colors, responsiveFontSizes } }) => `
    color: ${colors.primary};
    text-decoration: underline;

    ${mqLargeDown(`
      font-size: ${responsiveFontSizes.body[0]};
    `)}
  `}
`;
const IconWrapper = styled.div`
  min-width: 20px;
  padding-top: 10px;
`;

export const RenewablePower = () => {
  const [showModal, setShowModal] = useState(false);
  const title = 'Supercharged support for renewable power in the UK';
  return (
    <AnalyticsView name={GREENER_ELECTRICITY_SIGNUP_TREE_PLANTING_CARD}>
      <FixedHeightCard>
        <ClippedContent>
          <Wrapper>
            <Heading3>{title}</Heading3>
            <AnalyticsClick
              name={GREENER_ELECTRICITY_SIGNUP_TREE_PLANTING_CARD_MODAL}
            >
              <LinkTextWrapper onClick={() => setShowModal(true)}>
                All the good you do with Greener Electricity
                <IconWrapper>
                  <Icon name="chevron-right" />
                </IconWrapper>
              </LinkTextWrapper>
            </AnalyticsClick>
          </Wrapper>
        </ClippedContent>
        <CarbonNeutralBackgroundImg />
        <SkinnyModal
          as={Modal}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          title="Independent renewable power"
        >
          <P>
            With our green upgrade, you get 100% renewable electricity from
            traceable sources.
          </P>
          <P>
            On top of this, for every year you have Greener Electricity, we’ll
            contribute £20 to helping more independent renewable power get up
            and running. Without our support, these projects might not get off
            the ground. They can’t get subsidies, so our commitment to buy power
            from them, once they’re up and running, means they’re more likely to
            get other funding too.
          </P>
        </SkinnyModal>
      </FixedHeightCard>
    </AnalyticsView>
  );
};
