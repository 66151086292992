import React from 'react';
import styled from 'styled-components';
import { ActionCentreBanner } from '@/src/components/ActionCentre/Banners/Banner';
import { HANDRAISER_BANNER } from '@/src/constants/analytics-subjects';
import { HANDRAISER_LINK } from '@/src/constants/links';
import { ClippedImageBanner } from '@/src/components/ActionCentre/Banners/ClippedImageBanner/ClippedImageBanner';
import handraiserImg from '@/src/resources/handraiser-banner.jpg';
import { mqMediumDown } from '@/src/utils/mediaQuery';

const ClippedImg = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-image: url(${handraiserImg});
  background-size: 370px auto;
  clip-path: polygon(17% 0%, 100% 0%, 100% 72%, 32% 92%);

  ${mqMediumDown(`
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    background-size: cover;
    background-position: top;
  `)}
`;

export const HandraiserCard: ActionCentreBanner = ({ onClick, dismiss }) => {
  return (
    <ClippedImageBanner
      analyticsName={HANDRAISER_BANNER}
      heading="Looking to switch energy plan? We're here to help."
      body="Make sure you're one of the first to hear with our updates."
      linkTo={HANDRAISER_LINK}
      onClick={onClick}
      onDismiss={dismiss}
      ctaTitle="Sign up now"
      cancelTitle="Close"
      CroppedImage={ClippedImg}
    />
  );
};
