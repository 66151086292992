import { createTheme } from '@ovotech/nebula';
import {
  themeConfig,
  AdditionalConfig,
  icons as themeIcons,
} from '@ovotech/ovoenergy-theme';
import styled from 'styled-components';
import {
  Day,
  BellIcon,
  StarIcon,
  BlackWarningIcon,
  RedWarningSignIcon,
  Facebook,
  Instagram,
  Linkedin,
  Night,
  Wallet,
  Twitter,
  OVOCalculator,
  OVOComputer,
  OVOCalendar,
  OVORefunds,
} from '@/src/components/icons';
import { svgWidth } from '@/src/components/styled-utils';
import { OrionAdditions } from '@/src/types/Theme';

import { ReactComponent as LogoDark } from '@/src/resources/logo.svg';
import { ReactComponent as LogoLight } from '@/src/resources/logo-white.svg';

import mq, { breakpointVals } from '@/src/utils/mediaQuery';

const mqDesktopNav = mq(`(min-width: ${breakpointVals.large + 1}px)`);

export const StyledLightLogo = styled(LogoLight)`
  width: 50px;
  display: block;
  line-height: 0;

  ${mqDesktopNav(`
    ${svgWidth('50px', 48, 48)};
  `)}
`;

export const StyledDarkLogo = styled(LogoDark)`
  width: 50px;
  display: block;
  line-height: 0;

  ${mqDesktopNav(`
    ${svgWidth('50px', 48, 48)}
  `)}
`;

export const icons = {
  ...themeIcons,
  day: Day,
  night: Night,
  bell: BellIcon,
  star: StarIcon,
  elecBoltInTriangle: BlackWarningIcon,
  warningTriangle: RedWarningSignIcon,
  linkedin: Linkedin,
  facebook: Facebook,
  twitter: Twitter,
  instagram: Instagram,
  ovocalculator: OVOCalculator,
  ovocomputer: OVOComputer,
  ovocalendar: OVOCalendar,
  ovorefunds: OVORefunds,
  wallet: Wallet,
};

// Additions to the nebula theme.
const orionAdditions: OrionAdditions = {
  colors: {
    shadeLightest: '#FFFFFF',
    shadeMidLight: '#BDCAD0',
    shadeMid: '#706F6F',
    shadeDark: '#3C3C3B',
    secondary: '#047AB7',
    chartCartesianGrid: '#D1D6DE',
    combined: '#9FC927',
    comparison: '#197278',
    fuelComparison: '#197278',
    upgradesDark: '#C6187D',
    primaryAlt: '#006945',
    elec: '#E7A234',
    fuelElec: '#E7A234',
    gas: '#26ADE4',
    fuelGas: '#26ADE4',
    backgroundGlobal: '#F9F6F3',
    roundalDark: '#00261C',
    headerPrimary: themeConfig.colors.primary,
    headerPrimaryContrast: themeConfig.colors.primaryContrast,
    ovo: '#0A9828',
    green: {
      lighter: '#3BE44C',
      energised: '#3be44c',
      midnight: '#00261c',
    },
  },

  semantic: {
    inverted: {
      surface: '#00261C',
    },
  },
  zIndexes: {
    ovoBeyondBackground: -1,
  },
  maxLineLength: {
    normal: '75ch',
    small: '100ch',
  },
  space: {
    24: '9.6rem',
    30: '12rem',
  },
  badge: {
    variants: {
      newNavItem: {
        borderColor: themeConfig.colors.brand.mission.base,
        foreground: themeConfig.colors.brand.grey.lightest,
        background: themeConfig.colors.brand.mission.base,
      },
      primary: {
        background: themeConfig.colors.brand.primary.base, // warning colours will change when palette is added to OVO
        foreground: '#FFF',
        borderColor: themeConfig.colors.brand.primary.base,
      },
    },
  },
};

const theme = createTheme<AdditionalConfig, OrionAdditions>(
  // @ts-ignore
  themeConfig,
  orionAdditions,
);

export default theme;
