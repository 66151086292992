import { Card, Strong, Margin, CTALink } from '@ovotech/nebula';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom-v5';
import styled from 'styled-components';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import { ROUTE_ENERGY_INSIGHTS } from '@/src/constants/routes';
import { energyInsightsStrings } from '@/src/pages/EnergyInsights/strings';
import AnalyticsService from '@/src/services/analyticsService';

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 170px;
`;

export const NoSolarBreakdown = () => {
  useEffect(() => {
    AnalyticsService.dispatch(ENERGY_INSIGHTS.VIEWED_SOLAR_MESSAGE);
  }, []);
  return (
    <StyledCard data-testid="no-solar-breakdown-message">
      <Strong>
        We&apos;re sorry, this feature isn&apos;t available for solar panels yet
      </Strong>
      <Margin top={6} />
      <CTALink
        as={Link}
        to={ROUTE_ENERGY_INSIGHTS.HUB}
        iconLeft="chevron-left"
        iconRight="off"
        data-testid="solar-back-to-hub"
        onClick={() => {
          AnalyticsService.dispatch(ENERGY_INSIGHTS.CLICKED_SOLAR_BACK_TO_HUB);
        }}
      >
        {energyInsightsStrings.backToHub}
      </CTALink>
    </StyledCard>
  );
};
