import { Heading3, P, PrimaryCTALink, TextLink } from '@ovotech/nebula';
import React from 'react';
import { connect } from 'react-redux';
import { Banner } from '@/src/components/ActionCentre/Banners/Banner';
import { BANNER_CTA_CLICKED } from '@/src/constants/analytics';
import { SMART_BOOKING_URL } from '@/src/constants/endpoints';
import { State } from '@/src/types/State';

type Props = {
  accountId: string;
};

function ManageSmartMeterBooking({ accountId }: Props) {
  return (
    <Banner icon="changeDate">
      <Heading3 as="h2">You’re getting a smart meter</Heading3>
      <P>We'll install your smart meter very soon.</P>
      <P>
        <TextLink
          href="https://www.ovoenergy.com/help/before-your-smart-meter-installation"
          opensInNewWindow
        >
          {' '}
          What happens on the day?{' '}
        </TextLink>
      </P>

      <P>
        <PrimaryCTALink
          data-event-name={BANNER_CTA_CLICKED}
          data-event-props={JSON.stringify({
            bannerId: 'smartMeterManageBooking',
            ctaName: 'Smart Meter Manage Booking Clicked',
          })}
          href={`${SMART_BOOKING_URL}?accountId=${accountId}&utm_source=banner&utm_medium=MyOVO&utm_campaign=manage-smart-booking`}
        >
          Manage booking
        </PrimaryCTALink>
      </P>
    </Banner>
  );
}

const mapStateToProps = ({ user: { selectedAccountId } }: State) => ({
  accountId: selectedAccountId as string,
});

export default connect(mapStateToProps)(ManageSmartMeterBooking);
