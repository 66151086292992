import { useDecision } from '@optimizely/react-sdk';
import React from 'react';

import { HomePlanLink } from './HomePlanLink/HomePlanLink';
import BookSmartMeterLink from './BookSmartMeterLink/BookSmartMeterLink';
import MyEVLinkWrapper from './MyEVLink/MyEVLink';
import OvoGreenlightLink from './OvoGreenlightLink/OvoGreenlightLink';
import { HomeRecoverLink } from './HomeRecoverLink/HomeRecoverLink';
import { PlanLinksWrapper as PlanLinks } from './PlanLinks';
import { SmartThermostatLinksWrapper } from './SmartThermostatLinks';
import {
  HIDE_FLAKY_NAV,
  HOMEPLAN_NAV_LINK,
  HOMERECOVER_NAV_LINK,
} from '@/src/constants/feature-flags';
import { sandwichIds } from '@/src/constants/sandwich-ids';
import { OptimizelyFeature } from '@/src/utils/optimizely';

interface CustomNavProps {
  path: string;
  closeMobileMenu?: () => void;
  accountId?: string;
}
export const CustomNav = ({
  path,
  closeMobileMenu,
  accountId,
}: CustomNavProps) => {
  const [flag] = useDecision(HOMEPLAN_NAV_LINK);
  const [flagHr] = useDecision(HOMERECOVER_NAV_LINK);

  return (
    <>
      <PlanLinks
        closeMobileMenu={closeMobileMenu}
        path={path}
        type="accordion"
      />
      {accountId && (
        <OvoGreenlightLink accountId={accountId} type="accordion" path={path} />
      )}
      {!!flag.enabled && <HomePlanLink />}
      {!!flagHr.enabled && <HomeRecoverLink />}
    </>
  );
};

const modify = {
  [sandwichIds.sideNavigation.plan]: ({
    path,
    closeMobileMenu,
    accountId,
  }: CustomNavProps) => (
    <CustomNav
      path={path}
      closeMobileMenu={closeMobileMenu}
      accountId={accountId}
    />
  ),
  [sandwichIds.mobileNavigation.plan]: ({
    path,
    closeMobileMenu,
    accountId,
  }: CustomNavProps) => (
    <CustomNav
      path={path}
      closeMobileMenu={closeMobileMenu}
      accountId={accountId}
    />
  ),
  [sandwichIds.sideNavigation.meterReadings]: ({
    children,
    path,
    closeMobileMenu,
  }: any) => (
    <>
      {children}
      <OptimizelyFeature feature={HIDE_FLAKY_NAV}>
        {isEnabled =>
          isEnabled ? null : (
            <>
              <BookSmartMeterLink closeMobileMenu={closeMobileMenu} />
              <SmartThermostatLinksWrapper
                path={path}
                closeMobileMenu={closeMobileMenu}
              />
              <MyEVLinkWrapper path={path} closeMobileMenu={closeMobileMenu} />
            </>
          )
        }
      </OptimizelyFeature>
    </>
  ),
  [sandwichIds.mobileNavigation.meterReadings]: ({
    children,
    path,
    closeMobileMenu,
  }: any) => (
    <>
      {children}
      <OptimizelyFeature feature={HIDE_FLAKY_NAV}>
        {isEnabled =>
          isEnabled ? null : (
            <>
              <BookSmartMeterLink closeMobileMenu={closeMobileMenu} />
              <SmartThermostatLinksWrapper
                path={path}
                closeMobileMenu={closeMobileMenu}
              />
              <MyEVLinkWrapper path={path} closeMobileMenu={closeMobileMenu} />
            </>
          )
        }
      </OptimizelyFeature>
    </>
  ),
};

export { modify };
