import { Card, Heading3, Margin, P, Small, Strong } from '@ovotech/nebula';
import { getPlanConstants } from '@monovo/energy-renewals';
import React from 'react';

import {
  CardCol,
  CardContent,
  CardContentTriangleFiller,
  CardContentWrapper,
  CardHeader,
  CardHeaderMask,
  CardHeaderTitle,
  CardRow,
  DisplayOnlyWeb,
  Divider,
  PrimarySpacer,
  StyledCard,
} from '../Card.styled';

import Footer from './components/Footer';
import PlanDates from './components/PlanDates';
import TariffEstimations from './components/TariffEstimations';
import TariffHeading from './components/TariffHeading';
import { TariffInfo } from './components/TariffInfo';
import { refineRenewablePlans } from '@/src/pages/Renewal/selectors';
import { ContractsResponse } from '@/src/types/Response';
import {
  RenewablePlan,
  RenewablePlansResponse,
  RenewablePlanTariffWithVAT,
} from '@/src/types/RenewablePlans';

type Props = {
  renewablePlans: RenewablePlansResponse;
  contracts: ContractsResponse;
};

const exitFee = (contracts: ContractsResponse, fuel: 'gas' | 'electricity') => {
  const contract = contracts[fuel].find(
    contractItem => contractItem.status.active,
  );
  return contract ? `£${contract.exitFee / 100}` : '';
};

/*
 This component is supposed to display renewable plans in a following format:

 Single plan with one tariff              Single plan with two tariffs                              Two plans each having one tariff

 * * * * * * * * * * *        * * * * * * * * * * * * * * * * * * * * * * * * * * *     * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * tariff fuel       *        * first tariff fuel        second tariff fuel       *     * first plan tariff fuel        second plan tariff fuel       *
 * plan name         *        * plan name                plan name                *     * first plan name               second plan name              *
 * tariff rates      *        * first tariff rates       second tariff rates      *     * first plan tariff rates       second plan tariff rates      *
 * tariff end date   *        * first tariff end date    second tariff end dates  *     * first plan tariff end dates   second plan tariff end dates  *
 * tariff exit fees  *        * first tariff exit fees   second tariff exit fees  *     * first plan tariff exit fees   second plan tariff exit fees  *
 * * * * * * * * * * *        * * * * * * * * * * * * * * * * * * * * * * * * * * *     * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 */
const CurrentPlanCard = ({ renewablePlans, contracts }: Props) => {
  let plan1: RenewablePlan | undefined;
  let plan2: RenewablePlan | undefined;
  let tariff1: RenewablePlanTariffWithVAT | undefined;
  let tariff2: RenewablePlanTariffWithVAT | undefined;

  const refinedRenewablePlans = refineRenewablePlans(renewablePlans);

  if (renewablePlans.length > 1) {
    tariff1 = refinedRenewablePlans[0].tariffs[0];
    tariff2 = refinedRenewablePlans[1].tariffs[0];
    plan1 = refinedRenewablePlans[0];
    plan2 = refinedRenewablePlans[1];
  } else {
    tariff1 = refinedRenewablePlans[0]?.tariffs[0];
    tariff2 = refinedRenewablePlans[0]?.tariffs[1];
    plan1 = refinedRenewablePlans[0];
    plan2 = tariff1 && tariff2 ? plan1 : undefined;
  }

  const tarrif1ExitFee =
    tariff1 && contracts ? exitFee(contracts, tariff1.fuel) : '';
  const tarrif2ExitFee =
    tariff2 && contracts ? exitFee(contracts, tariff2.fuel) : '';

  if (!tariff1 || !plan1) {
    // tariff1 and plan1 should be always defined
    return null;
  }

  const tariffs = [tariff1, tariff2].filter(Boolean);

  return (
    <StyledCard as={Card}>
      <CardHeader>
        <Margin all={6}>
          <Heading3 as={CardHeaderTitle}>Your current plan</Heading3>
        </Margin>
        <CardHeaderMask />
      </CardHeader>
      <CardContentWrapper>
        <CardContent>
          <PrimarySpacer />
          <Margin left={6} right={6} top={6}>
            <TariffHeading tariff1={tariff1} tariff2={tariff2} />
            <CardRow>
              <CardCol />
              <CardCol>
                <P>
                  <Strong>{plan1.name}</Strong>
                </P>
              </CardCol>
              {plan2 && (
                <CardCol>
                  <P>
                    <Strong>{plan2.name}</Strong>
                  </P>
                </CardCol>
              )}
            </CardRow>

            {getPlanConstants(plan1.name).title !== plan1.name && (
              <CardRow>
                <CardCol />
                <CardCol>
                  <P>
                    <Small>{getPlanConstants(plan1.name).title}</Small>
                  </P>
                </CardCol>
                {plan2 && getPlanConstants(plan2.name).title !== plan2.name && (
                  <CardCol>
                    <P>
                      <Small>{getPlanConstants(plan2.name).title}</Small>
                    </P>
                  </CardCol>
                )}
              </CardRow>
            )}

            <Margin top={2} bottom={2}>
              <Divider />
            </Margin>

            <PlanDates plan1={plan1} plan2={plan2} />

            <Divider secondary />

            {(tarrif1ExitFee || tarrif2ExitFee) && (
              <CardRow>
                <CardCol>
                  <P>
                    <Small>
                      Exit fee:
                      <br />
                      (No fees during renewal)
                    </Small>
                  </P>
                </CardCol>
                <CardCol>
                  <P>
                    <Small>
                      <Strong>{tarrif1ExitFee}</Strong>
                    </Small>
                  </P>
                </CardCol>
                {tarrif2ExitFee && (
                  <CardCol>
                    <P>
                      <Small>
                        <Strong>{tarrif2ExitFee}</Strong>
                      </Small>
                    </P>
                  </CardCol>
                )}
              </CardRow>
            )}

            <Divider secondary />

            <TariffInfo tariffs={tariffs} />

            <Margin vertical={4}>
              <Divider />
            </Margin>
            <TariffEstimations tariff1={tariff1} tariff2={tariff2} />

            <Footer />
          </Margin>
        </CardContent>
        {!tariff2 && (
          <DisplayOnlyWeb>
            <CardContentTriangleFiller />
          </DisplayOnlyWeb>
        )}
      </CardContentWrapper>
    </StyledCard>
  );
};

export default CurrentPlanCard;
