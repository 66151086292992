import { CardPaymentResponse } from '@ovotech/energy-cx';
import addMonths from 'date-fns/add_months';
import setDate from 'date-fns/set_date';
import startOfToday from 'date-fns/start_of_today';
import { getEpochDaysDate } from '../../utils/date';

export const getNextDdPaymentDate = ({
  dayOfMonth,
  lockedInPayment,
}: {
  dayOfMonth?: number;
  lockedInPayment?: { date: number };
}) => {
  if (lockedInPayment) {
    return getEpochDaysDate(lockedInPayment.date);
  }

  const today = startOfToday();
  const ddDateThisMonth = setDate(today, dayOfMonth!);

  return ddDateThisMonth < today
    ? addMonths(ddDateThisMonth, 1)
    : ddDateThisMonth;
};

export const formatCurrencyAmountAsPence = (amount: string) => {
  const [pounds, pence] = `${amount}.00`.split('.');
  return Number(pounds) * 100 + Number(pence);
};

export const createWorldpayCardPaymentUrl = (
  amount: string,
  { redirectUrl, transactionReference }: CardPaymentResponse,
  statusRoute: string,
) => {
  const hostname = window.location.origin;

  const query = `?amount=${Number(amount).toFixed(
    2,
  )}&txId=${transactionReference}`;

  const baseUrl = `${hostname}${statusRoute}${query}`;

  // Assumes redirectUrl already contains a "?".
  const fullRedirectUrl = redirectUrl.concat(
    `&successURL=${encodeURIComponent(`${baseUrl}&status=success`)}`,
    `&cancelURL=${encodeURIComponent(`${baseUrl}&status=cancelled`)}`,
    `&pendingURL=${encodeURIComponent(`${baseUrl}&status=pending`)}`,
    `&failureURL=${encodeURIComponent(`${baseUrl}&status=error`)}`,
    `&errorURL=${encodeURIComponent(`${baseUrl}&status=error`)}`,
  );

  return fullRedirectUrl;
};
