import {
  P,
  Margin,
  Checkbox,
  TextLink,
  PrimaryCTAButton,
  SecondaryCTAButton,
  Callout,
  Strong,
} from '@ovotech/nebula';
import { useAnalytics } from '@ovotech/ui-tools';
import React, { useState, useEffect } from 'react';
import { ReactComponent as MedalIcon } from '@/src/resources/medal.svg';
import { ReactComponent as StarIcon } from '@/src/resources/star.svg';
import { usePowerMoveChallenges } from '../../hooks/use-power-move-challenges';
import { MappedPowerMoveChallenge, PowerMoveChallengeType } from '../../types';
import {
  formatHighestReward,
  formatTiersArray,
  formatTime,
  getChallengePeriod,
  startCase,
} from '../../utils/utils';
import {
  StyledCard,
  StyledBadge,
  IconWrapper,
  AdditionInfo,
  PrescriptionP,
  PrescriptionH2,
  StyledTextLink,
  TargetRewardSection,
  PrescriptionSection,
  TargetRewardBlockTitle,
  StyledChallengePPContainer,
  StyledChallengeInfoContainer,
  PrescriptionChallengePeriod,
  StyledDataTable,
  Title,
} from './PowerMoveChallenge.styled';
import { PowerMoveMonthChallengeSkeleton } from './PowerMoveChallengeSkeleton';
import { PATH_TO_ZERO } from '@/src/constants/analytics';
import {
  OVO_PRIVACY_POLICY_LINK,
  POWER_MOVE_TERMS_AND_CONDITIONS,
  POWER_MOVE_CHALLENGE_TERMS_AND_CONDITIONS,
} from '@/src/constants/links';

interface PowerMoveChallengeProps {
  isError: boolean;
  isLoading: boolean;
  isLoadingOrUpdating: boolean;
  isJoiningChallenge: boolean;
  tipsEnabled?: boolean;
  optOutEnabled?: boolean;
  challenge: MappedPowerMoveChallenge;
  onPressJoin: (versionId: string) => void;
  onPressLeave: () => void;
  showRewardsModal: () => void;
  showCalculationModal: () => void;
}

const renderStatusBadge = (
  label: string,
  status: 'isJoined',
  dataTestId: string,
) => (
  <StyledBadge status={status} variant="newNavItem" data-testid={dataTestId}>
    {label}
  </StyledBadge>
);

const renderPrescription = (challenge: MappedPowerMoveChallenge) =>
  challenge?.challengeType === PowerMoveChallengeType.SINGLE_TIER ? (
    <PrescriptionP>
      Lower electricity use in <br /> your home, Monday to <br /> Friday,{' '}
      {formatTime(challenge.peakHours?.from)} to{' '}
      {formatTime(challenge.peakHours?.to)}
    </PrescriptionP>
  ) : (
    <PrescriptionP>
      Earn up to £{formatHighestReward(challenge.highestMonthlyReward)} a month
    </PrescriptionP>
  );

export const PowerMoveChallenge = (props: PowerMoveChallengeProps) => {
  const {
    isError,
    challenge,
    isLoading,
    isLoadingOrUpdating,
    isJoiningChallenge,
    optOutEnabled,
    onPressJoin,
    onPressLeave,
    showRewardsModal,
    showCalculationModal,
  } = props;
  const [hasError, setHasError] = useState<boolean>(false);
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const { joinedChallenges } = usePowerMoveChallenges();

  const { dispatch } = useAnalytics();

  useEffect(() => {
    if (challenge.isJoined) setAcceptedTerms(false);
  }, [challenge.isJoined]);

  const checkboxOnChangeHandler = () => {
    setHasError(false);
    setAcceptedTerms(pS => !pS);
  };

  const onPressJoinHandler = () => {
    dispatch({
      name: PATH_TO_ZERO.POWER_MOVE_JOIN_CHALLENGE_BUTTON_CLICKED,
      type: 'click',
      properties: {
        joinedChallenges,
        isPowerMoveCustomer: joinedChallenges.length > 0 ? 'true' : 'false',
        acceptedTerms: acceptedTerms ? 'true' : 'false',
      },
    });

    if (!acceptedTerms) {
      setHasError(true);
      return;
    }
    onPressJoin(challenge.versionId);
  };

  const challengePeriod = getChallengePeriod(
    challenge.activeFrom,
    challenge.activeUntil,
  );

  return (
    <StyledCard>
      {isLoading ? (
        <PowerMoveMonthChallengeSkeleton />
      ) : (
        <StyledChallengeInfoContainer>
          <PrescriptionSection>
            {challenge.isJoined &&
              renderStatusBadge(
                'Joined',
                'isJoined',
                'power-move-is-joined-challenge-status-badge',
              )}
            <IconWrapper>
              {!challenge.isUpcoming ? <MedalIcon /> : <StarIcon />}
            </IconWrapper>

            <PrescriptionH2>{startCase(challenge?.displayName)}</PrescriptionH2>
            {challengePeriod && (
              <Margin vertical={1}>
                <PrescriptionChallengePeriod>
                  {challengePeriod}
                </PrescriptionChallengePeriod>
              </Margin>
            )}
            <Margin vertical={3}>{renderPrescription(challenge)}</Margin>
          </PrescriptionSection>
          <TargetRewardSection>
            <TargetRewardBlockTitle>Target</TargetRewardBlockTitle>
            <P data-testid="target">
              Use{' '}
              <Strong>
                {challenge.highestLessThanOrEqualTotalPercentage}% or less
              </Strong>{' '}
              of your home’s total daily electricity between{' '}
              {formatTime(challenge.peakHours?.from)} and{' '}
              {formatTime(challenge.peakHours?.to)}
              {challenge.challengeType === PowerMoveChallengeType.SINGLE_TIER
                ? '.'
                : ', Monday to Friday.'}
            </P>
            {challenge.isJoined && (
              <AdditionInfo>
                We’ll send email updates on your progress.
              </AdditionInfo>
            )}
            {challenge?.challengeType === PowerMoveChallengeType.SINGLE_TIER ? (
              <>
                <TargetRewardBlockTitle>Reward</TargetRewardBlockTitle>
                <P>
                  Earn up to £
                  {formatHighestReward(challenge.highestMonthlyReward)} in
                  credit a month on your next energy bill.{' '}
                </P>
              </>
            ) : (
              <>
                <P>Here’s what you could earn each month.</P>
                <StyledDataTable
                  columnHeadings={['Target (peak use %)', 'Monthly reward']}
                  items={formatTiersArray(challenge)}
                  striped
                />
              </>
            )}
            {challenge.areWeekendsIncluded ? (
              <>
                <Title>Weekends included as off-peak</Title>
                <P>
                  Try moving your energy use to off-peak hours whenever
                  possible. Weekends count too!
                </P>
                <TextLink
                  onClick={showCalculationModal}
                  href="#calculation-modal"
                >
                  Learn how we calculate peak use
                </TextLink>
                <Title>How to check progress</Title>
                <P>Look out for email updates on how you're getting on.</P>
              </>
            ) : (
              <StyledTextLink onClick={showRewardsModal}>
                How to earn {challenge.displayName.toLocaleLowerCase()} rewards
              </StyledTextLink>
            )}

            {challenge.isJoined && (
              <>
                {optOutEnabled && (
                  <div>
                    <Margin top={4} />
                    <SecondaryCTAButton
                      disabled={isLoadingOrUpdating}
                      onClick={onPressLeave}
                    >
                      {isLoadingOrUpdating
                        ? 'Loading...'
                        : `Leave ${challenge.displayName.toLocaleLowerCase()}`}
                    </SecondaryCTAButton>
                  </div>
                )}
              </>
            )}
          </TargetRewardSection>
        </StyledChallengeInfoContainer>
      )}
      {!challenge.isJoined && (
        <StyledChallengePPContainer>
          <Margin top={4} />
          <P>
            View the{' '}
            <TextLink
              target="_blank"
              href={`${POWER_MOVE_CHALLENGE_TERMS_AND_CONDITIONS}/${challenge.versionId}`}
            >
              {challenge.displayName.toLocaleLowerCase()} terms
            </TextLink>{' '}
            and{' '}
            <TextLink target="_blank" href={POWER_MOVE_TERMS_AND_CONDITIONS}>
              Power Move terms
            </TextLink>
          </P>

          <Margin top={4} />
          <Checkbox
            disabled={isLoading}
            id={`power-move-terms-and-conditions-${challenge.versionId}`}
            label="I accept both terms and conditions"
            error={hasError && 'Select if you accept the terms and conditions'}
            checked={acceptedTerms}
            onChange={checkboxOnChangeHandler}
          />
          <P>
            If you join Power Move, we will share your name and email with our
            trusted third-party research partners. They may contact you directly
            for feedback on Power Move challenges. For more information on how
            we process your personal data, see our{' '}
            <TextLink target="_blank" href={OVO_PRIVACY_POLICY_LINK}>
              privacy policy
            </TextLink>
            .
          </P>
          <Margin top={6} />
          <PrimaryCTAButton
            type="button"
            disabled={isLoadingOrUpdating}
            onClick={onPressJoinHandler}
          >
            {isLoading || isJoiningChallenge
              ? 'Loading...'
              : `Join ${challenge.displayName.toLocaleLowerCase()}`}
          </PrimaryCTAButton>
          {isError && (
            <Callout style={{ marginTop: 24 }} variant="error">
              <P>Sorry, something went wrong. Try joining again.</P>
            </Callout>
          )}
        </StyledChallengePPContainer>
      )}
    </StyledCard>
  );
};
