import { useAccountContext } from '@ovotech/ui-tools';
import { useMemo } from 'react';
import { HomeProfileData } from '../types';
import { useLocalStorage } from '@/src/utils/useLocalStorage';

export type HomeProfilePromptPosition = 'top' | 'bottom';

export const useHomeProfilePromptPosition = (
  homeProfile: HomeProfileData | null,
) => {
  const { accountId } = useAccountContext();
  const [position, setPosition] = useLocalStorage<HomeProfilePromptPosition>(
    `homeProfilePromptPosition-${accountId}`,
    homeProfile ? 'bottom' : 'top',
  );

  return useMemo(
    () => ({
      homeProfilePromptPosition: position,
      sendHomeProfilePromptToBottom: () => setPosition('bottom'),
    }),
    [position, setPosition],
  );
};
