import { Heading4, P, PrimaryCTAButton, Margin, Strong } from '@ovotech/nebula';
import React, { useEffect } from 'react';
import { homeProfileModalTestIDs as testIDs } from '../testIDs';
import { ButtonWrapper, IconWrapper } from './HomeProfileIntro.styled';
import { ENERGY_INSIGHTS } from '@/src/constants/analytics';
import { ReactComponent as HouseIcon } from '@/src/resources/house_icon.svg';
import AnalyticsService from '@/src/services/analyticsService';

interface IntroProps {
  onClickCTA: VoidFunction;
}

export const HomeProfileIntro = ({ onClickCTA }: IntroProps) => {
  useEffect(() => {
    AnalyticsService.dispatch(
      ENERGY_INSIGHTS.HOMES_LIKE_YOURS_VIEWED_INTRO_PAGE,
    );
  }, []);

  return (
    <div data-testid={testIDs.intro.container}>
      <Heading4>Tell us about your home</Heading4>
      <IconWrapper>
        <HouseIcon />
      </IconWrapper>
      <P data-testid={testIDs.intro.info}>
        If you share some information with us, we can give you{' '}
        <Strong>personalised insights </Strong>
        about your home’s energy use and how it compares to homes like yours.
      </P>
      <Margin bottom={12}>
        <P>It should only take a couple of minutes to complete.</P>
      </Margin>
      <ButtonWrapper>
        <PrimaryCTAButton
          data-testid={testIDs.intro.cta}
          type="button"
          onClick={onClickCTA}
        >
          Go to question 1
        </PrimaryCTAButton>
      </ButtonWrapper>
    </div>
  );
};
